import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { CookieOptions, CookieStore } from './cookie-store';
import { CookieType } from '../../entity/cookie/cookie-type';

/**
 * An adapter to 3rd party library. Changes to ngx-cookie means we only have to change this adapter.
 */
@Injectable()
export class CookieAdapterService implements CookieStore {
  defaultOptions = {
    domain: '',
    secure: false
  };

  constructor(private cookie: CookieService) {}

  get(key: CookieType): string {
    return this.cookie.get(key.toString());
  }

  getObject(key: CookieType): Object {
    return this.cookie.getObject(key.toString());
  }

  getAll(): Object {
    return this.cookie.getAll();
  }

  put(key: CookieType, value: string, options?: CookieOptions): void {
    const allOptions = { ...this.defaultOptions, ...options };
    this.cookie.put(key.toString(), value, allOptions);
  }

  putObject(key: CookieType, value: Object, options?: CookieOptions): void {
    const allOptions = { ...this.defaultOptions, ...options };
    this.cookie.putObject(key.toString(), value, allOptions);
  }

  remove(key: CookieType, options?: CookieOptions): void {
    this.cookie.remove(key.toString(), options);
  }

  removeAll(): void {
    this.cookie.removeAll();
  }
}
