import { ConfigValidator } from './config.validator';
import { ConfigAccess } from './config-access';

export function configServiceFactory(
  config: ConfigAccess,
  configUrl: string,
  configValidators: ConfigValidator[]
) {
  return function() {
    return config.init(configUrl, configValidators);
  };
}
