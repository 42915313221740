export enum ProcessFieldValidationType {
  REQUIRED = 'required',
  REQUIRED_BOOLEAN = 'required_boolean',
  GSM_INTERNATIONAL = 'gsmInternational',
  GSM_INTERNATIONAL_NO_PREFIX = 'gsmInternational_noPrefix',
  GSM_SE = 'GSM_SE',
  GSM_LV = 'GSM_LV',
  GSM_MB = 'GSM_MB',
  EMAIL = 'email',
  SSN_NUMBER = 'ssn',
  PIC_LV = 'PIC_LV',
  UPLOAD_FILE_NAME = 'fileName',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  PASSWORD = 'passwordRules',
  INTEGER = 'integer',
  PASSWORD_MATCH = 'passwordMatch',
  EMAIL_MATCH = 'EmailMatch',
  NUMBER = 'number',
  DATE = 'date',
  STREET_NUMBER = 'Street_number',
  ZIP_CODE = 'Zip_code',

  AMOUNT_NZ = 'Amount_NZ',
  NAME_NZ = 'Name_NZ',
  NAME_MB = 'Name_MB',
  NAME_LV = 'Name_LV',
  NAME_ALPHA_LV = 'Name_alpha_LV',
  LAST_NAME_MB = 'LastName_MB',
  SSN_SE = 'SSN_SE',
  GSM_INTERNATIONAL_NZ = 'GSM_NZ',
  GSM_INTERNATIONAL_NZ_NO_PREFIX = 'GSM_NZ_NO_PREFIX',
  EMAIL_NZ = 'Email_NZ',
  EMAIL_SE = 'Email_SE',
  EMAIL_LV = 'Email_LV',
  PASSPORT_NZ = 'Pssprt_NZ',
  DRIVERS_LICENCE_NZ = 'DrLic_NZ',
  DRIVERS_LICENCE_VERSION_NZ = 'DrLic_ver_NZ',
  MONTHLY_INCOME_NZ = 'Mthl_Income_NZ',
  MONTHLY_LIVING_COST_NZ = 'Mthl_liv_cost_NZ',
  MONTHLY_OTHER_EXPENSES_NZ = 'Mthl_other_exp_NZ',
  TOTAL_AMOUNT_OTHER_LOANS_NZ = 'Tot_am_other_loans_NZ',
  DRIVER_LICENSE_NUMBER = 'driverLicenseNumber',
  DRIVER_LICENSE_VERSION = 'driverLicenseVersion',
  DATE_NZ = 'DoB_NZ',
  STREET_NUMBER_NZ = 'Street_number_NZ',
  ZIP_CODE_NZ = 'Zip_code_NZ',
  GSM_PREFIX = 'gsmPrefix',
  LOAN_PURPOSE_OTHER = 'otherLoanPurpose',
  STREET_LV = 'Street_LV',
  CITY_LV = 'City_LV',
  ZIP_CODE_LV = 'Zip_code_LV',
  LOAN_PURPOSE_OTHER_SE = 'loanPurpose_SE',
  LOAN_PURPOSE_OTHER_NZ = 'loanPurpose_NZ',
  ADDRESS_LINE = 'addressLine',
  ADDRESS_CITY = 'addressCity',
  ADDRESS_ZIP = 'addressZip',
  ADDRESS_ZIP_SE = 'addressZip_SE',
  ADDRESS_NAME_NZ = 'AddressName_NZ',

  CPR_DK = 'CPR_DK',
  EMAIL_DK = 'Email_DK',
  GSM_DK = 'GSM_DK',
  FIRSTNAME_DK = 'FirstName_DK',
  LASTNAME_DK = 'LastName_DK',
  PLACEOFBIRTH_DK = 'placeOfBirth',
  NATIONALITY_DK = 'nationality',
  NATIONALITY_LV = 'Nationality_LV',
  LOAN_PURPOSE_DK = 'loanPurpose_DK'
}
