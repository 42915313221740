import { ProcessName } from '../../entity/process/process-name';
import { ProcessActionName } from '../../entity/process/process-action-name';
import { ProcessFormStepName } from '../../../shared/models/enums';

export interface ProcessLayout {
  processName: ProcessName;
  layouts: ProcessActionLayout[];
}

export interface ProcessActionLayout {
  processActionName: ProcessActionName;
  formStep: ProcessFormStepName;
  controls: string[][];
}

export const processCountryLayouts: ProcessLayout[] = [
  {
    processName: ProcessName.TPP_REGISTRATION_PROCESS,
    layouts: [
      {
        processActionName: ProcessActionName.REGISTRATION_LEAD_SCREEN,
        formStep: ProcessFormStepName.FULL,
        controls: [
          ['firstName', 'lastName'],
          ['email', 'retypeEmail'],
          ['gsm'],
          ['setPassword', 'retypePassword'],
          ['TPP_PRIVACY_POLICY'],
          ['TPP_TERMS_CONDITIONS']
        ]
      },
      {
        processActionName: ProcessActionName.EMAIL_VERIFICATION,
        formStep: ProcessFormStepName.FULL,
        controls: [['pinCode']]
      }
    ]
  }
];
