import { InjectionToken, Inject, PLATFORM_ID } from '@angular/core';
import { WebSocketLink } from 'apollo-link-ws';
import { Apollo } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular-link-http-batch';
import { GraphService } from './graph.service';
import { GraphServiceAccess } from './graph-service-access';
import { AuthLink } from './links/auth-link';
import { SUBSCRIPTION_LINK } from './links/subscription-link.token';
import { HttpLink } from 'apollo-angular-link-http';
import { SiteService } from '../site/site-service';
import { SITE_SERVICE } from '../site/site-service.token';

export const GRAPH_SERVICE = new InjectionToken<GraphServiceAccess>(
  'GraphServiceAccess'
);

export function graphServiceFactory(
  subscriptionLink: WebSocketLink,
  platformId: Object,
  authLink: AuthLink,
  apollo: Apollo,
  httpLink: HttpLink,
  httpBatchLink: HttpBatchLink,
  siteService: SiteService
) {
  return new GraphService(
    subscriptionLink,
    platformId,
    authLink,
    apollo,
    httpLink,
    httpBatchLink,
    siteService
  );
}

export const GRAPH_SERVICE_PROVIDER = {
  provide: GRAPH_SERVICE,
  useFactory: graphServiceFactory,
  deps: [
    [new Inject(SUBSCRIPTION_LINK)],
    [new Inject(PLATFORM_ID)],
    AuthLink,
    Apollo,
    HttpLink,
    HttpBatchLink,
    [new Inject(SITE_SERVICE)]
  ]
};
