import { ProcessEngineApiCall } from '../../../services/process-engine/service/process-engine.enum';

export enum ParamEncodingType {
  DEFAULT = 'DEFAULT',
  BASE64 = 'BASE64'
}

export interface ProcessEngineMethodParam {
  type: ParamEncodingType;
  value: string;
}

export interface ProcessEngineMethodParams {
  method: ProcessEngineApiCall;
  params: ProcessEngineMethodParam[];
}
