import { Inject, InjectionToken } from '@angular/core';
import { ProcessEngineModelDevelService } from './process-engine-model-devel.service';
import { Router } from '@angular/router';
import { PROCESS_MODEL } from '../../../core/services/process-engine/model/process-model.provider';
import { PROCESS_ENGINE_SERVICE } from '../../../core/services/process-engine/service/process-engine.provider';
import { CountryService } from '../../../core/services/country/country.service';
import { ProcessEngineServiceAccess } from '../../../core/services/process-engine/service/process-engine-service-access';

export function processModelFactory(
  processEngineService: ProcessEngineServiceAccess,
  countryService: CountryService,
  router: Router
) {
  return new ProcessEngineModelDevelService(
    processEngineService,
    countryService,
    router
  );
}

export const PROCESS_DEVEL_MODEL_PROVIDER = {
  provide: PROCESS_MODEL,
  useFactory: processModelFactory,
  deps: [[new Inject(PROCESS_ENGINE_SERVICE)], CountryService, Router]
};
