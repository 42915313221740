import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
  HeaderContent,
  HeaderContentState
} from '../models/header-content.model';

let _state: HeaderContentState = {
  headerContent: null,
  linksVisibleState: true
};

@Injectable()
export class HeaderContentFacade {
  private store$ = new BehaviorSubject<HeaderContentState>(_state);
  private state$ = this.store$.asObservable();

  selectedHeaderContent$ = this.state$.pipe(
    map(state => state.headerContent),
    distinctUntilChanged()
  );

  linksVisibleState$ = this.state$.pipe(
    map(state => state.linksVisibleState),
    distinctUntilChanged()
  );

  updateHeaderContent(content: HeaderContent): void {
    this.updateState({
      ..._state,
      headerContent: content
    });
  }

  updateLinksVisibleState(state: boolean): void {
    this.updateState({
      ..._state,
      linksVisibleState: state
    });
  }

  resetHeaderContent(): void {
    this.updateState({
      ..._state,
      headerContent: null
    });
  }

  private updateState(state: HeaderContentState): void {
    this.store$.next((_state = state));
  }
}
