import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './footer/footer.component';

const COMPONENTS = [FooterComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule],
  exports: [FooterComponent]
})
export class FooterModule {}
