import { Injectable } from '@angular/core';
import { UserService } from './../services/user/user.service';
import { CanLoad, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class BackOfficeGuard implements CanLoad, CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userService.isBackofficeUser()) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }

  canActivate(): boolean {
    if (this.userService.isBackofficeUser()) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
