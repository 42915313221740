import { Component, Inject, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Header } from '../models/header.model';
import { getHeaderConfig } from '../config/header.config';
import { UserService } from '../../../../core/services/user/user.service';
import { User } from '../../../../core/models/user.model';
import { HeaderContentFacade } from '../facades/header-content.facade';
import { HeaderContent } from '../models/header-content.model';
import { WINDOW } from '../../../../core/services/window/window.token';
import { BrowserWindowApi } from '../../../../core/services/window/browser-window-api';

@Component({
  selector: 'fe-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isCookieShow: boolean;
  headerConfig: Header = getHeaderConfig(false);
  userInitials: string;
  isUserLoggedIn: boolean;
  isBackofficeUser: boolean;
  menuOpened = false;
  headerContent$: Observable<HeaderContent | null> = this.headerContentFacade
    .selectedHeaderContent$;
  linksVisibleState$: Observable<boolean> = this.headerContentFacade
    .linksVisibleState$;

  constructor(
    private userService: UserService,
    @Inject(WINDOW) private window: BrowserWindowApi,
    private headerContentFacade: HeaderContentFacade
  ) {}

  ngOnInit() {
    this.userService.shortenedUserName.subscribe(
      (initials: string) => (this.userInitials = initials)
    );
    this.userService.setUserData();

    this.userService.user
      .pipe(filter(user => !!Object.keys(user as User).length))
      .subscribe(() => {
        this.isUserLoggedIn = true;
        this.isBackofficeUser = this.userService.isBackofficeUser();
        this.headerConfig = getHeaderConfig(true);
      });
  }

  logoutUser() {
    this.userService.logout();
  }

  navigateToLanding(): void {
    this.window.location.href = 'https://www.ferratumbank.com/';
  }
}
