export function appConfigValidator(data: any): Error[] {
  const errors: Error[] = [];

  // if (!data) {
  //   return [new Error(`APP_CONFIG is missing`)];
  // }
  //
  // if (!data.apiUrl) {
  //   errors.push(new Error(`APP_CONFIG - property 'apiUrl' is required`));
  // }
  //
  // if (!data.subscriptionUrl) {
  //   errors.push(
  //     new Error(`APP_CONFIG - property 'subscriptionUrl' is required`)
  //   );
  // }
  //
  // if (!data.scraperUrl) {
  //   errors.push(new Error(`APP_CONFIG - property 'scraperUrl' is required`));
  // }
  //
  // if (!data.scraperEnviroment) {
  //   errors.push(
  //     new Error(`APP_CONFIG - property 'scraperEnviroment' is required`)
  //   );
  // }
  //
  // if (!data.tppImUrl) {
  //   errors.push(new Error(`APP_CONFIG - property 'tppImUrl' is required`));
  // }

  return errors;
}
