import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  Optional
} from '@angular/core';
import { Observable, Subject, of, throwError, combineLatest } from 'rxjs';
import {
  delay,
  scan,
  switchMap,
  retryWhen,
  map,
  tap,
  distinctUntilChanged,
  takeUntil,
  filter
} from 'rxjs/operators';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';

import { GRAPH_SERVICE } from '../graph/graph.provider';
import { GraphServiceAccess } from '../graph/graph-service-access';
import { environment } from '../../../../../environments/environment';
import { WINDOW } from '../window/window.token';
import { CountryService } from '../country/country.service';
import { CookieType } from '../../entity/cookie/cookie-type';
import { CookieStore } from '../cookie/cookie-store';
import { COOKIE_SERVICE } from '../cookie/cookie.token';
import * as Raven from 'raven-js';
import { RavenStatic } from 'raven-js';
import { ApmErrorHandler } from '@elastic/apm-rum-angular';
import { EnvConfigService } from '../config-handler/config-handler.service';
import { AppConfig } from '../../../../config/app.config';

export const ERROR_HANDLER_SERVICE = new InjectionToken<ErrorHandler>(
  'GlobalErrorHandlerService'
);

export const RETRY_SECONDS = new InjectionToken<number>('retrySeconds');

export interface ErrorReport {
  type: string;
  message: string;
  stack: string;
  queryInfo: string;
  location: string;
  reportTime: number;
}

let cookie: CookieStore;

@Injectable()
export class GlobalErrorHandlerService extends ApmErrorHandler
  implements ErrorHandler {
  private baseErrorHandler: ErrorHandler = new ErrorHandler();

  private error: Subject<ApolloError | any> = new Subject();

  private retrySeconds = 5;

  private raven: RavenStatic;

  private destroy: Subject<boolean> = new Subject<boolean>();

  private appConfig: AppConfig;

  private authUrl: string;

  constructor(
    private injector: Injector,
    @Inject(COOKIE_SERVICE) cookieService: CookieStore,
    @Optional()
    @Inject(RETRY_SECONDS)
    retrySeconds: number
  ) {
    super();

    if (retrySeconds) {
      this.retrySeconds = retrySeconds;
    }

    cookie = cookieService;

    this.initErrorSubscription();
  }

  /**
   * Global error handler entry point
   *
   * @param error
   */
  handleError(error: any): void {
    if (!environment.production) {
      this.baseErrorHandler.handleError(error);
    }
    this.error.next(error);
  }

  /**
   * Initialise Error Subscription
   */
  private initErrorSubscription() {
    combineLatest(of(EnvConfigService.config), this.error)
      .pipe(
        filter((values: [AppConfig, any]) => !!values[0]),
        tap((values: [AppConfig, any]) => console.log(values)),
        tap((values: [AppConfig, any]) => {
          this.appConfig = values[0];
          this.authUrl = this.appConfig.authUrl;
        }),
        map((values: [AppConfig, any]) => values[1]),
        distinctUntilChanged(),
        map((error: any) => {
          console.log('this.isSentryAvailable(): ', this.isSentryAvailable());
          if (this.isSentryAvailable()) {
            this.raven.captureException(error, {
              extra: this.toErrorMessage(error)
            });
            return;
          }
          return error;
        }),
        filter((error: any) => !!error),
        tap((error: any) => this.handleUnauthorize(error.graphQLErrors)),
        map((error: any) => this.toErrorMessage(error)),
        tap((message: ErrorReport) => this.reportError(message)),
        takeUntil(this.destroy)
      )
      .subscribe(
        () => {},
        (error: any) => {
          console.error(
            `Global error handler encountered an exception: ${error}`
          );
        }
      );
  }

  /**
   *
   * @returns {boolean} whether Sentry can be used or not.
   */
  private isSentryAvailable(): boolean {
    if (this.isRavenSetUp()) {
      return true;
    }
    try {
      if (
        this.appConfig &&
        this.appConfig.sentryEnable &&
        this.appConfig.sentryConfig
      ) {
        this.raven = Raven.config(this.appConfig.sentryConfig).install();
        return true;
      }
    } catch (error) {
      if (error && error.name && error.name === 'RavenConfigError') {
        console.log('Raven misconfiguration');
      }
    }
    return false;
  }

  private isRavenSetUp(): boolean {
    return this.raven && this.raven.isSetup();
  }

  private handleUnauthorize(graphQLErrors: any[] = []) {
    const window: any = this.injector.get(WINDOW);
    const countryService: CountryService = this.injector.get(CountryService);

    const notAuthorized: boolean = graphQLErrors.some((graphQLError: any) => {
      return graphQLError.domain && graphQLError.domain['UW_401'];
    });

    if (!notAuthorized) {
      return;
    }

    console.log('O removing token cookie due to ERR !!')
    cookie.remove(CookieType.AUTH_TOKEN);
    window.location.href = this.authUrl;
  }

  /**
   * Map error information to report object
   *
   * @param {ApolloError | any} error
   * @returns {ErrorReport}
   */
  private toErrorMessage(error: ApolloError | any): ErrorReport {
    console.log('TO ERROR MESSAGE', error);
    const window: any = this.injector.get(WINDOW);
    let location = '';
    if (window && window.location && window.location.href) {
      location = window.location.href;
    }

    let queryInfo = '';
    if (error.graphQLErrors && error.graphQLErrors.length) {
      queryInfo = error.graphQLErrors
        .map((graphError: GraphQLError): string => {
          let message = '';
          if (graphError.path && graphError.path.length) {
            message += `${graphError.path.join('->')}: `;
          }
          message += `${graphError.message}`;
          return message;
        })
        .join('; ');
    }

    return {
      type: error.name || '',
      message: error.message || '',
      stack: error.stack || '',
      queryInfo,
      location,
      reportTime: Math.floor(Date.now() / 1000)
    };
  }

  /**
   * Send report to server
   *
   * @param {ErrorReport} report
   */
  private reportError(report: ErrorReport) {
    const graphService: GraphServiceAccess = this.injector.get(GRAPH_SERVICE);

    const mutation = gql`
      mutation report(
        $type: String
        $message: String
        $stack: String
        $queryInfo: String
        $location: String
        $reportTime: Int
      ) {
        report(
          type: $type
          message: $message
          stack: $stack
          queryInfo: $queryInfo
          location: $location
          reportTime: $reportTime
        )
      }
    `;
    const variables = { ...report };
    graphService
      .mutate({ mutation, variables })
      .pipe(
        retryWhen((errors: Observable<ApolloError>) =>
          this.onNetworkError(errors)
        )
      )
      .subscribe(
        () => console.log('Error was reported'),
        (reportingError: any) =>
          console.error(`Error reporting request failed. ${reportingError}`)
      );
  }

  /**
   * Check if the error is related to networking, to retry
   *
   * @param {Observable<ApolloError>} errors
   * @returns {Observable<ApolloError>}
   */
  private onNetworkError(
    errors: Observable<ApolloError>
  ): Observable<ApolloError | undefined> {
    return errors.pipe(
      switchMap((error: ApolloError):
        | Observable<ApolloError>
        | Observable<never> => {
        if (!error.networkError) {
          return throwError(error);
        }
        console.log(
          `Error reporting failed but is recoverable, retrying in ${this.retrySeconds} seconds...`
        );
        return of(error);
      }),
      delay(this.retrySeconds * 1000),
      // On 2nd iteration, exit
      scan((acc: ApolloError | undefined, source: any, index: number) => {
        if (index) {
          throw source;
        }
      })
    );
  }
}
