import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { HeaderLinks } from '../header/models/header.model';

@Component({
  selector: 'fe-header-drop-down',
  templateUrl: './header-drop-down.component.html',
  styleUrls: ['./header-drop-down.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0 })))
    ])
  ]
})
export class HeaderDropDownComponent {
  @Input() isCookieShow: boolean;
  @Input() isUserLogin: boolean;
  @Input() isBackoffice: boolean;
  @Input() isOpened: boolean;
  @Input() headerLinks: HeaderLinks[];

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  itemClicked: EventEmitter<void> = new EventEmitter<void>();
}
