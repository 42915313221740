export enum ProcessActionFieldType {
  BUTTON = 'button',
  CONFIGURATION = 'configuration',
  CHECKBOX = 'check-box',
  INPUT_DATEPICKER = 'date-picker',
  INPUT_EMAIL = 'input-email',
  INPUT_NUMBER = 'input-number',
  INPUT_PHONE_NUMBER = 'input-phone-number',
  INPUT_RETYPE_EMAIL = 'input-retype-email',
  INPUT_SELECT = 'combo-box',
  INPUT_TEXT = 'input-text',
  INPUT_UNDEFINED = '',
  LINK = 'link',
  PASSWORD = 'password',
  POP_UP = 'pop-up-page',
  RADIO = 'list-single-choice',
  TEXT = 'text',
  SLIDER = 'slider-value',
  SLIDER_MINIMUM = 'slider-min',
  SLIDER_MAXIMUM = 'slider-max',
  SLIDER_STEP = 'slider-step',
  SLIDER_VALUE = 'sliderValue',
  SLIDER_MIN = 'sliderMin',
  SLIDER_MAX = 'sliderMax',
  METADATA = 'metadata',
  FILE = 'file'
}
