import { ProcessActionFieldType } from '../../../entity/process/process-action-field-type';
import { ProcessFieldValidationType } from '../../../entity/process/process-field-validation-type';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ProcessFieldValidation } from '../../../entity/process/process-field-validation';
import { ProcessActionComponentAttribute } from '../../../entity/process/process-action-component-attribute';

const ALPHA_NUM_CHARS = '0-9a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ';

const ALPHA_NUM_SIMPLE_INTERPUNCTION = new RegExp(
  '^(?=[' +
    ALPHA_NUM_CHARS +
    '])([' +
    ALPHA_NUM_CHARS +
    "]*[.,'\\- ]?[" +
    ALPHA_NUM_CHARS +
    ']*)+[' +
    ALPHA_NUM_CHARS +
    ']\\.?$'
);

export const NAME_VALID = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ.,'-])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ.,'\- ]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ.,'-]$/;
export const NAME_VALID_SE = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\'\-,])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp \'\-,]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\'\-,]$/;
export const NAME_VALID_LV = /^[a-zA-Z\u002d\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3][a-zA-Z\u002d\u0020\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3]{0,253}[a-zA-Z\u002d\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3]$/;
export const NAME_VALID_NZ = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ,'-])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ,'\- ]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ,'-]$/;
export const DK_NAME_ALLOWED_CHARS =
  'a-zA-Z-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3';
export const FIRSTNAME_LASTNAME_DK = new RegExp(
  `^[${DK_NAME_ALLOWED_CHARS}]([ ${DK_NAME_ALLOWED_CHARS}]*[${DK_NAME_ALLOWED_CHARS}])?$`
);
export const LOAN_PURPOSE_VALID_NAME_NZ = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\'\-,])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp \'\-,]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\'\-,]$/;

export const ADDRESS_CITY = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp.])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp \-.]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\-.]$/;
export const ADDRESS_LINE = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\d])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\d \-\.\,\'\/]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\d\-\.\,\'\/]$/;
export const STREET_LV = /^[0-9a-zA-Z\u0030-\u0039\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3\u002d\u002f\u002c\u002e\u003b][0-9a-zA-Z\u0030-\u0039\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3\u002d\u0020\u002f\u002c\u002e\u003b]{0,253}[0-9a-zA-Z\u0030-\u0039\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3\u002d\u002f\u002c\u002e\u003b]$/;

export const NAME_ALPHA_LV = /^(?=[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp])[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\-]{0,254}[a-zA-ZÀ-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḀ-ỳp\-]$/;

export const ADDRESS = new RegExp(
  [
    '^',
    '(?=.*',
    '[a-zA-Z',
    '\u00C0-\u00D6',
    '\u00D8-\u00F6',
    '\u00F8-\u0148',
    '\u014A-\u017F',
    '\u01C4-\u01CC',
    '\u0200-\u024F',
    '\u1E02-\u1EF3',
    ` .'-])`,
    '[^\\s]',
    '[a-zA-Z',
    '\\d',
    '\u00C0-\u00D6',
    '\u00D8-\u00F6',
    '\u00F8-\u0148',
    '\u014A-\u017F',
    '\u01C4-\u01CC',
    '\u0200-\u024F',
    '\u1E02-\u1EF3',
    ` .'-/]{0,255}[^\\s]$`
  ].join('')
);

export const OTHER_LOAN_PURPOSE = /^(?=[a-zA-Z0-9À-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ,'-])[a-zA-Z0-9À-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ,'\- ]{0,254}[a-zA-Z0-9À-ÖØ-öø-ňŊ-ſǄ-ǌȀ-ɏḂ-ỳ,'-]$/;
export const EMAIL_REGEX = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
export const EMAIL_REGEX_SE_NZ = /^[A-Za-z0-9.!#$%\'*+-/=?^_`{|}~]+[@]([A-Za-z0-9]+([-]*[A-Za-z0-9])*[.])+\d*[A-Za-z]+[A-Za-z0-9]*$/;
export const EMAIL_REGEX_LV = /^[a-zA-Z0-9.!#$%'*+\/=?^_`\{|}~-]+@((([a-zA-Z0-9]{1,2})|([a-zA-Z0-9][a-zA-Z0-9\-]{1,59}[a-zA-Z0-9]))[.])+((?=.*[a-zA-Z])([a-zA-Z0-9]{2,61}))$/i;
export const PASSWORD_VALID = /^(?=.*\d+.*)(?=.*[A-Z]+.*)(?=.*[a-z]+.*).{8,20}$/;
export const REDIRECT_URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)[^\s]*$/;
export const ENTERPRISE_NAME_REGEX = /^[a-zA-Z0-9-\s\.,]+$/;
export const PHONE_REGEX = /(?=^([+]{1,1}|[0]{2,2})[1-9-\s]{1,1}[0-9-\s]+$)|(?=^0[1-9-\s]{1,1}[0-9-\s]+$)(0|\+){1,1}[0-9-\s]*$/;
export function validatorByName(
  name: ProcessFieldValidationType
): ValidatorFn | null {
  switch (name) {
    case ProcessFieldValidationType.REQUIRED:
      return Validators.required;
    case ProcessFieldValidationType.REQUIRED_BOOLEAN:
      return (control: AbstractControl): { [key: string]: any } | null => {
        return typeof control.value !== 'boolean'
          ? { requiredBoolean: true }
          : null;
      };
    case ProcessFieldValidationType.EMAIL:
      return Validators.compose([
        Validators.maxLength(80),
        Validators.pattern(EMAIL_REGEX)
      ]);
    case ProcessFieldValidationType.EMAIL_NZ:
      return Validators.compose([
        Validators.maxLength(255),
        Validators.pattern(EMAIL_REGEX_SE_NZ)
      ]);
    case ProcessFieldValidationType.EMAIL_SE:
      return Validators.compose([
        Validators.maxLength(80),
        Validators.pattern(EMAIL_REGEX_SE_NZ)
      ]);
    case ProcessFieldValidationType.EMAIL_LV:
      return Validators.compose([
        Validators.maxLength(80),
        Validators.pattern(EMAIL_REGEX_LV)
      ]);
    case ProcessFieldValidationType.FIRST_NAME:
    case ProcessFieldValidationType.MIDDLE_NAME:
      return Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(NAME_VALID)
      ]);
    case ProcessFieldValidationType.LAST_NAME:
      return Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(80),
        Validators.pattern(NAME_VALID)
      ]);
    case ProcessFieldValidationType.LAST_NAME_MB:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(40),
        Validators.pattern(NAME_VALID)
      ]);

    case ProcessFieldValidationType.NAME_MB:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(40),
        Validators.pattern(NAME_VALID)
      ]);
    case ProcessFieldValidationType.NAME_NZ:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(30),
        Validators.pattern(NAME_VALID_NZ)
      ]);
    case ProcessFieldValidationType.NAME_LV:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(255),
        Validators.pattern(NAME_VALID_LV)
      ]);
    case ProcessFieldValidationType.NATIONALITY_LV:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(NAME_VALID_LV)
      ]);
    case ProcessFieldValidationType.NAME_ALPHA_LV:
      return Validators.pattern(NAME_ALPHA_LV);
    case ProcessFieldValidationType.GSM_PREFIX:
      return Validators.pattern(/^[\+][1-9][\d]{1,4}$/);
    case ProcessFieldValidationType.GSM_INTERNATIONAL:
      return Validators.pattern(/^[\+][1-9][\d]{1,14}$/);
    case ProcessFieldValidationType.GSM_SE:
      return Validators.compose([
        Validators.pattern(/^(((\+46)?(( ?-?0)?))|0)( ?-?[1-9])( ?-?\d){8}$/),
        Validators.maxLength(15)
      ]);
    case ProcessFieldValidationType.GSM_LV:
      return Validators.compose([
        Validators.pattern(/^(\+371)?(2)(\d){7}$/),
        Validators.maxLength(12)
      ]);
    case ProcessFieldValidationType.GSM_MB:
      return Validators.compose([
        Validators.pattern(PHONE_REGEX),
        Validators.maxLength(22)
      ]);
    case ProcessFieldValidationType.GSM_INTERNATIONAL_NO_PREFIX:
      return Validators.pattern(/[\d]{1,14}$/);
    case ProcessFieldValidationType.GSM_INTERNATIONAL_NZ:
      return Validators.pattern(/^(\+64)?[\d]{8,15}$/);
    case ProcessFieldValidationType.GSM_INTERNATIONAL_NZ_NO_PREFIX:
      return Validators.pattern(/^[\d]{1,15}$/);
    case ProcessFieldValidationType.SSN_NUMBER:
    case ProcessFieldValidationType.SSN_SE:
      return Validators.compose([
        Validators.pattern(/^(19|20)(\d{6}(-)?\d{4})$/),
        validateDateSSNSE
      ]);
    case ProcessFieldValidationType.PIC_LV:
      return Validators.compose([
        Validators.pattern(
          /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])(\d{2})-(0|1|2)(\d{4})$/
        ),
        validateDateSSNLV
      ]);
    case ProcessFieldValidationType.UPLOAD_FILE_NAME:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(191)
      ]);
    case ProcessFieldValidationType.PASSPORT_NZ:
    case ProcessFieldValidationType.DRIVERS_LICENCE_NZ:
      return Validators.pattern(/^[a-zA-z]{2}\d{6}$/);
    case ProcessFieldValidationType.DRIVERS_LICENCE_VERSION_NZ:
      return Validators.pattern(/^\d{3}$/);
    case ProcessFieldValidationType.MONTHLY_INCOME_NZ:
    case ProcessFieldValidationType.MONTHLY_LIVING_COST_NZ:
    case ProcessFieldValidationType.MONTHLY_OTHER_EXPENSES_NZ:
    case ProcessFieldValidationType.TOTAL_AMOUNT_OTHER_LOANS_NZ:
      return Validators.min(0);
    case ProcessFieldValidationType.PASSWORD:
      return Validators.pattern(PASSWORD_VALID);
    case ProcessFieldValidationType.INTEGER:
      return Validators.compose([
        Validators.min(1),
        Validators.pattern(/^[0-9]+$/)
      ]);
    case ProcessFieldValidationType.NUMBER:
    case ProcessFieldValidationType.AMOUNT_NZ:
      return Validators.compose([
        Validators.min(0),
        Validators.pattern(/^(0|[1-9][0-9]{0,14})$/)
      ]);
    case ProcessFieldValidationType.DATE:
    case ProcessFieldValidationType.DATE_NZ:
      return Validators.pattern(/^\d{4}-\d{2}-\d{2}$/);
    case ProcessFieldValidationType.ADDRESS_LINE:
      return Validators.pattern(ADDRESS_LINE);
    case ProcessFieldValidationType.ADDRESS_CITY:
      return Validators.pattern(ADDRESS_CITY);
    case ProcessFieldValidationType.STREET_NUMBER:
    case ProcessFieldValidationType.STREET_NUMBER_NZ:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(8),
        Validators.pattern(/^[0-9a-zA-Z'.\/-]+$/)
      ]);
    case ProcessFieldValidationType.ZIP_CODE:
    case ProcessFieldValidationType.ZIP_CODE_NZ:
      return Validators.pattern(/^([0-9]{0,7})$/);
    case ProcessFieldValidationType.ADDRESS_ZIP:
    case ProcessFieldValidationType.ADDRESS_ZIP_SE:
      return Validators.pattern(/^(([0-9]{0,7})|([0-9]{4}[\s][0-9]{2}))$/);
    case ProcessFieldValidationType.LOAN_PURPOSE_OTHER:
      return Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.maxLength(60),
        Validators.pattern(OTHER_LOAN_PURPOSE)
      ]);
    case ProcessFieldValidationType.ZIP_CODE_LV:
      return Validators.pattern(/^(LV-)[0-9]{4}$/);
    case ProcessFieldValidationType.STREET_LV:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(255),
        Validators.pattern(STREET_LV)
      ]);
    case ProcessFieldValidationType.LOAN_PURPOSE_OTHER_SE:
      return Validators.compose([
        Validators.min(1),
        Validators.maxLength(60),
        Validators.pattern(NAME_VALID_SE)
      ]);
    case ProcessFieldValidationType.LOAN_PURPOSE_OTHER_NZ:
      return Validators.compose([
        Validators.min(1),
        Validators.maxLength(60),
        Validators.pattern(LOAN_PURPOSE_VALID_NAME_NZ)
      ]);
    case ProcessFieldValidationType.ADDRESS_NAME_NZ:
      return Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(ALPHA_NUM_SIMPLE_INTERPUNCTION)
      ]);
    case ProcessFieldValidationType.CPR_DK:
      return Validators.compose([
        Validators.minLength(1),
        Validators.pattern(
          /^([0|1][1-9]|[1|2][0-9]|[3][0|1])([0][1-9]|[1][0-2])([0-9][0-9])([0-9]{4})$/
        )
      ]);
    case ProcessFieldValidationType.GSM_DK:
      return Validators.compose([
        Validators.pattern(/^(\+45)?.{0,12}$/),
        Validators.pattern(/(^((\+45)? ?-?0?))( ?-?\d){8}$/)
      ]);
    case ProcessFieldValidationType.FIRSTNAME_DK:
      return Validators.compose([
        Validators.maxLength(40),
        Validators.pattern(FIRSTNAME_LASTNAME_DK)
      ]);
    case ProcessFieldValidationType.LASTNAME_DK:
      return Validators.compose([
        Validators.maxLength(80),
        Validators.pattern(FIRSTNAME_LASTNAME_DK)
      ]);
    case ProcessFieldValidationType.EMAIL_DK:
      return Validators.compose([
        Validators.maxLength(80),
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`\{|}~-]+@[-a-zA-Z0-9]{1,61}[.][a-zA-Z0-9]{2,61}$/i
        )
      ]);
    case ProcessFieldValidationType.LOAN_PURPOSE_DK:
      return Validators.compose([
        Validators.maxLength(61),
        Validators.pattern(
          /^[^\s].[a-zA-Z0-9/, .'\:\-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u0148\u014A-\u017F\u01C4-\u01CC\u0200-\u024F\u1E00-\u1EF3].+[^\s]$/
        )
      ]);
  }
  return null;
}

function isAgeAllowed(day: number, month: number, year: number): boolean {
  const date1 = new Date(year, month - 1, day);
  const date2 = new Date();
  date2.setMinutes(date2.getMinutes() + date2.getTimezoneOffset());
  const diff = Math.floor(
    (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)
  );
  return (
    (diff -
      (Math.floor(date2.getFullYear() / 4) -
        Math.floor(date1.getFullYear() / 4))) /
      365 >=
    21
  );
}

function validateDate(day: number, month: number, year: number): boolean {
  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  // Check the range of the day
  return (
    day > 0 && day <= monthLength[month - 1] && isAgeAllowed(day, month, year)
  );
}

export function validateDateSSNSE(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value || control.value.length < 10) {
    return null;
  }

  let datestring = '';
  if (control.value.startsWith('19') || control.value.startsWith('20')) {
    datestring = control.value.slice(0, 8);
  } else {
    datestring = '19' + control.value.slice(0, 6);
  }

  const day = parseInt(datestring.slice(6, 8), 10);
  const month = parseInt(datestring.slice(4, 6), 10);
  const year = parseInt(datestring.slice(0, 4), 10);

  return validateDate(day, month, year) ? null : { invalidDate: true };
}

export function validateDateSSNLV(control: AbstractControl): any {
  if (!control.value || control.value.length !== 12) {
    return null;
  }

  const day = parseInt(control.value.slice(0, 2), 10);
  const month = parseInt(control.value.slice(2, 4), 10);
  const year = parseInt(control.value.slice(4, 6), 10);

  return validateDate(day, month, 1900 + year) ? null : { invalidDate: true };
}

export function validatorsFromField(
  field: ProcessActionComponentAttribute,
  isBooleanType: boolean = false
): ValidatorFn[] {
  const validators: ValidatorFn[] = [];

  if (
    field.mandatory &&
    field.attributeType !== ProcessActionFieldType.BUTTON
  ) {
    validators.push(
      !isBooleanType ? Validators.required : Validators.requiredTrue
    );
  }
  if (field.validations) {
    field.validations.forEach((item: ProcessFieldValidation) => {
      const validatorFn = validatorByName(item.validationName);
      if (validatorFn) {
        validators.push(validatorFn);
      }
    });
  }

  return validators;
}

/**
 * sets validation state / errors also to untouched fields
 */
export function showFormValidationErrors(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      showFormValidationErrors(control);
    }
  });
}

export function showControlValidationErrors(control: AbstractControl) {
  if (control instanceof FormControl) {
    control.markAsTouched({ onlySelf: true });
  } else if (control instanceof FormGroup) {
    showFormValidationErrors(control);
  }
}

export function checkMatchingValues(
  primaryValue: string,
  retypeValue: string,
  prefix: string = ''
): ValidatorFn {
  return (group: FormGroup) => {
    const primaryValueInput = group.controls[primaryValue].value.replace(
      prefix,
      ''
    );
    const retypeValueInput = group.controls[retypeValue].value.replace(
      prefix,
      ''
    );

    if (primaryValueInput) {
      if (
        primaryValueInput &&
        retypeValueInput &&
        primaryValueInput !== retypeValueInput
      ) {
        return {
          matchValue: true
        };
      }
    }
    return null;
  };
}
