import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'apollo-client/util/Observable';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Country } from '../../../core/entity/content/country/country';
import { CountryCode } from '../../../core/entity/content/country/country-code.enum';
import { ProcessCurrentStepName } from '../../../core/entity/process/process-current-step-name';
import { ProcessMessage } from '../../../core/entity/process/process-message';
import { ProcessName } from '../../../core/entity/process/process-name';
import { CountryService } from '../../../core/services/country/country.service';
import { MockMessageItem } from '../../../core/services/process-engine/decorators/process-message.provider';
import { ProcessEngineFormUtils } from '../../../core/services/process-engine/form-utils/form-utils';
import { ProcessEngineModelAccess } from '../../../core/services/process-engine/model/process-engine-model-access';
import { ProcessCancelReason } from '../../../core/services/process-engine/model/process-engine-model.service';
import { ProcessEngineServiceAccess } from '../../../core/services/process-engine/service/process-engine-service-access';
import { PROCESS_ENGINE_SERVICE } from '../../../core/services/process-engine/service/process-engine.provider';
import {
  processEngineStepsConfig,
  ProcessEngineVariants
} from '../process-engine-service/process-engine-mock-config';
import {
  leadMockMessage,
  leadMockMessagesList
} from '../process-messages/lead-mock-message';

@Injectable()
export class ProcessEngineModelDevelService
  implements OnDestroy, ProcessEngineModelAccess {
  private messageSub: Subscription;

  private processId: string;

  private redirectUri: string | boolean;

  private countryCode: CountryCode;

  private entityUid: string;

  private formUtils: ProcessEngineFormUtils;

  private resuming: boolean;

  private _processCancelReason: ProcessCancelReason;

  message: BehaviorSubject<ProcessMessage | undefined> = new BehaviorSubject(
    undefined
  );

  progress: BehaviorSubject<boolean> = new BehaviorSubject(false);

  active: BehaviorSubject<boolean> = new BehaviorSubject(false);

  activeProcess: BehaviorSubject<ProcessName | null> = new BehaviorSubject(
    null
  );

  isBrowser: boolean;

  isServer: boolean;

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(PROCESS_ENGINE_SERVICE)
    private processEngine: ProcessEngineServiceAccess,
    private countryService: CountryService,
    private router: Router
  ) {
    this.countryService.currentCountry
      .pipe(takeUntil(this.destroy))
      .subscribe((currentCountry: Country) => {
        this.countryCode = currentCountry.countryCode;
        this.entityUid =
          currentCountry.counterparty && currentCountry.counterparty.id
            ? currentCountry.counterparty.id
            : '';
      });
  }

  /**
   * Clean up subscriptions
   */
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  restore() {}

  navigateFromProcess(
    navigateTo: string,
    isExternalUrl: boolean,
    navigateToOnBrowserBack?: string
  ): void {}

  isResuming(): boolean {
    return false;
  }

  setResuming(value: boolean): void {}

  getCurrentProcessId(): string {
    return 'process-id';
  }

  /**
   * starts the process and subscribes to messages from process engine
   */
  start(
    process: ProcessName,
    parameters: any,
    redirectUri: string | boolean
  ): Observable<string | null> {
    this.progress.next(true);
    this.active.next(true);
    this.activeProcess.next(process);

    const startSubject = new Subject<string | null>();
    const processName = process.toString();

    this.processEngine
      .start(processName, parameters, this.entityUid, this.countryCode)
      .subscribe(
        (processId: string) => {
          this.redirectUri = redirectUri;
          this.processId = processId;
          console.log(
            `${process.toString()} devel process started ${this.processId}`
          );
          this.subscribeProcessMessages();
          this.processEngine.nextMessage(this.getProcessStartMessage(process));
          startSubject.next(processId);
          startSubject.complete();
        },
        error => {
          console.error(
            `Failed to start a devel process ${process.toString()}: ${error}`
          );
          this.processFinished();
          startSubject.next(null);
          startSubject.complete();
          throw error;
        }
      );
    return startSubject.asObservable();
  }

  /**
   * submits the data & finishes the process if isFinalTask
   */
  submit(
    data: any,
    redirectUri: string | boolean = this.redirectUri,
    allowFinish: boolean = true
  ): Observable<boolean> {
    this.redirectUri = redirectUri;
    const currentMessage = this.message.value;
    const submitSubject = new Subject<boolean>();
    console.log(`Submitting values: \n ${JSON.stringify(data, null, 2)}`);
    if (!currentMessage) {
      console.log(
        `Unable to submit data - current message value is ${currentMessage}`
      );
      submitSubject.next(false);
      submitSubject.complete();
    } else {
      this.resuming = false;
      this.formUtils = new ProcessEngineFormUtils(this.message);
      data = this.formUtils.preprocessRequestData(data);

      console.log(
        `Submitting processed values: \n ${JSON.stringify(data, null, 2)}`
      );

      this.progress.next(true);

      console.log(currentMessage);
      const userTaskId = currentMessage.action.userTaskUid;
      const responseObjectName = currentMessage.action.responseObjectName;
      this.processEngine
        .submit(
          userTaskId,
          this.entityUid,
          this.countryCode,
          ProcessName.TPP_REGISTRATION_PROCESS,
          currentMessage.process.processUid,
          responseObjectName,
          data
        )
        .subscribe(
          (success: boolean) => {
            this.getNextMockMessage(
              responseObjectName,
              currentMessage.process.processName
            );
            console.log(currentMessage);
            console.log(
              `Values submitted userTaskID ${currentMessage.action.userTaskUid}:`
            );
            if (currentMessage.action.isFinalTask && allowFinish) {
              this.processFinished();
            }
            submitSubject.next(success);
            submitSubject.complete();
          },
          error => {
            console.error(
              `Failed to submit values to user taskID ${currentMessage.action.userTaskUid}`
            );
            this.progress.next(false);
            submitSubject.next(false);
            submitSubject.complete();
            throw error;
          }
        );
    }
    return submitSubject.asObservable();
  }

  /**
   * Submits process action for given processUid.
   * Action is defined in data and is allowed only when it is defined in allowedActions
   * in previously received process message.
   */
  processSubmitAction(
    processUid: string,
    action: string,
    redirectUri: string | boolean = this.redirectUri
  ): Observable<boolean> {
    this.redirectUri = redirectUri;
    const currentMessage = this.message.value;

    if (!currentMessage) {
      console.log(
        `Unable to complete step data - current message value is ${currentMessage}`
      );
      return of(false);
    } else {
      this.resuming = false;
      this.formUtils = new ProcessEngineFormUtils(this.message);
      if (!this.formUtils.isActionAllowed(action)) {
        return of(false);
      }

      this.progress.next(true);

      const userTaskId = currentMessage.action.userTaskUid;
      const body = {
        actionName: action
      };

      return this.processEngine
        .processCompleteStep(
          userTaskId,
          this.entityUid,
          this.countryCode,
          ProcessName.TPP_REGISTRATION_PROCESS,
          processUid,
          'actionPage',
          body
        )
        .pipe(
          tap(
            () => {
              this.progress.next(false);
            },
            () => {
              this.progress.next(false);
            }
          )
        );
    }
  }

  /**
   * goes back to previous action
   */
  back() {
    const currentMessage = this.message.value;
    if (!currentMessage) {
      console.log(
        `Unable to revert step - current message value is ${currentMessage}`
      );
      return;
    }
    this.progress.next(true);
    const responseObjectName = currentMessage.action.responseObjectName;
    const userTaskId = currentMessage.action.userTaskUid;
    console.log(`Reverting process step  ${userTaskId}`);
    this.processEngine
      .action(
        'goBack',
        this.processId,
        userTaskId,
        this.entityUid,
        this.countryCode,
        ProcessName.TPP_REGISTRATION_PROCESS
      )
      .subscribe(
        (success: boolean) => {
          this.getPreviousMockMessage(responseObjectName);
          console.log(`Process reverted ${this.processId}`);
        },
        error => {
          console.error(`Failed to revert a process ${this.processId}`);
          this.progress.next(false);
          throw error;
        }
      );
  }

  /**
   * cancels current process
   * @param {string | boolean} redirectUri - set redirect uri when it's different compared to initial in start/submit/resume
   */
  cancel(
    redirectUri?: string | boolean,
    processCancelReason?: ProcessCancelReason
  ): Observable<boolean> {
    const cancelSubject = new Subject<boolean>();
    if (this.processId) {
      if (processCancelReason !== undefined) {
        this.processCancelReason = processCancelReason;
      }
      if (redirectUri !== undefined) {
        this.redirectUri = redirectUri;
      }
      this.processFinished(false);
      console.log(`Cancelling process ${this.processId || ''}`);
      this.processEngine
        .cancel(
          this.processId,
          this.entityUid,
          this.countryCode,
          ProcessName.TPP_REGISTRATION_PROCESS
        )
        .subscribe(
          (success: boolean) => {
            this.clearProcessId();
            console.log(`Process cancelled ${this.processId || ''}`);
            cancelSubject.next(true);
            cancelSubject.complete();
          },
          error => {
            console.error(`Failed to cancel a process ${this.processId}`);
            cancelSubject.next(false);
            cancelSubject.complete();
            throw error;
          }
        );
    } else {
      console.log('No active process to cancel');
    }
    return cancelSubject.asObservable();
  }

  set processCancelReason(processCancelReason: ProcessCancelReason) {
    this._processCancelReason = processCancelReason;
  }

  get processCancelReason() {
    return this._processCancelReason;
  }

  /**
   * resumes current process
   */
  resume(processUid: string, redirectUri: string) {
    this.progress.next(true);
    this.active.next(true);
    this.redirectUri = redirectUri;
    this.subscribeProcessMessages(processUid);
    this.processEngine
      .resume(
        this.entityUid,
        this.countryCode,
        processUid,
        ProcessName.TPP_REGISTRATION_PROCESS
      )
      .subscribe(
        data => {
          if (!(data && data.processResume)) {
            throw new Error('mutation resumeProcess returned no data');
          }
          this.resuming = true;
          if (this.processId !== data.processResume) {
            this.subscribeProcessMessages(data.processResume);
          }
        },
        error => {
          console.error(`Failed to resume a process: ${error}`);
          this.processFinished();
          throw error;
        }
      );
  }

  sendGoogleCid(cid: string): Observable<boolean> {
    const cancelSubject = new Subject<boolean>();
    if (this.processId) {
      console.log(`Sending Google CID for process ${this.processId || ''}`);
      this.processEngine
        .sendGoogleCID(
          this.processId,
          this.entityUid,
          this.countryCode,
          cid,
          ProcessName.TPP_REGISTRATION_PROCESS
        )
        .subscribe(
          (success: boolean) => {
            console.log(
              `Sending Google CID for process: ${this.processId ||
                ''} resulted in: ${success}`
            );
            cancelSubject.next(success);
            cancelSubject.complete();
          },
          error => {
            console.error(
              `Failed to sent Google CID for process: ${this.processId}`
            );
            cancelSubject.next(false);
            cancelSubject.complete();
            throw error;
          }
        );
    } else {
      console.log('No process to send Google CID');
    }
    return cancelSubject.asObservable();
  }

  get inProgress(): Observable<boolean> {
    return this.progress.asObservable();
  }

  /**
   * listen for process messages through graphql subscription
   */
  private subscribeProcessMessages(processUid?: string) {
    if (processUid) {
      this.processId = processUid;
    }
    this.messageSub = this.processEngine
      .message(this.processId)
      .pipe(takeUntil(this.destroy))
      .subscribe((message: ProcessMessage) => {
        if (this.isServer) {
          return;
        }
        console.log(`Received process message`);
        console.log(message);

        this.message.next(message);
        this.progress.next(false);
      });
  }

  /**
   * cleanup after process is finished
   */
  private processFinished(isToClearProcessId: boolean = true) {
    if (this.messageSub) {
      this.messageSub.unsubscribe();
    }
    console.log('FINISH');
    this.message.next(undefined);
    this.progress.next(false);
    this.active.next(false);
    this.activeProcess.next(null);
    this.resuming = false;

    if (this.redirectUri && typeof this.redirectUri === 'string') {
      this.router.navigateByUrl(this.redirectUri);
    }

    console.log(`Process finished ${this.processId || ''}`);
    if (isToClearProcessId) {
      this.clearProcessId();
    }
  }

  private clearProcessId() {
    // @ts-ignore
    delete this.processId;
  }

  private getNextMockMessage(
    currentStepName: string,
    processName: ProcessName
  ) {
    console.log('current step name', currentStepName);
    console.log('process name: ', processName);
    if (currentStepName) {
      const currentStep = processEngineStepsConfig[currentStepName];
      console.log('currentStep: ', currentStep);
      const processEngineVariant = ProcessEngineVariants[processName];
      console.log('process engine path:', processEngineVariant);
      const nextMessage =
        currentStep[processEngineVariant] || currentStep['defaultMessage'];
      const processMessage = this.getMessageCountrySpecific(nextMessage);
      console.log('next message', processMessage);
      this.processEngine.nextMessage(processMessage);
    }
  }

  private getPreviousMockMessage(currentStepName: string) {
    switch (currentStepName) {
      case ProcessCurrentStepName.LOAN_COMPLETED_PAGE:
        break;
      default:
        this.processEngine.nextMessage();
    }
  }

  private getProcessStartMessage(processName: ProcessName): ProcessMessage {
    console.log('process type name: ', processName);
    let startMessage: ProcessMessage = leadMockMessage;

    switch (processName) {
      case ProcessName.ONBOARDING_PROCESS:
        startMessage = this.getMessageCountrySpecific(leadMockMessagesList);
        break;
      case ProcessName.FERRATUM_PROCESS:
        startMessage = this.getMessageCountrySpecific(leadMockMessagesList);
        break;
    }
    return startMessage;
  }

  private getMessageCountrySpecific(
    messageList: MockMessageItem[] | MockMessageItem
  ) {
    if (Array.isArray(messageList)) {
      console.log('mesage List', messageList);
      const messageFiltered = messageList.find(
        message => message.code === this.countryCode
      );
      return messageFiltered ? messageFiltered : messageList[0];
    } else {
      return messageList;
    }
  }

  changeSubscription(processId: string): void {
    const oldProcessId = this.processId;
    this.subscribeProcessMessages(processId);
    this.processId = oldProcessId;
    this.submit({}, '', false);
    this.processId = processId;
  }

  clearOnboardingProcess(): Observable<boolean> {
    return of(true);
  }

  action(): Observable<boolean> {
    return of(true);
  }

  initializeTimeout(type: string, message: any): void {}

  destroyTimer(): void {}

  reportLog(type: string, message: any): void {}

  loggingTime(): number {
    return 0;
  }
}
