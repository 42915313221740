import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProcessMessage } from '../../../core/entity/process/process-message';
import { CountryCode } from '../../../core/entity/content/country/country-code.enum';
import { ProcessEngineServiceAccess } from '../../../core/services/process-engine/service/process-engine-service-access';

@Injectable()
export class ProcessEngineDevelService implements ProcessEngineServiceAccess {
  private processMessage: Subject<ProcessMessage> = new Subject<
    ProcessMessage
  >();
  start(
    name: string,
    parameters: any,
    entityUid: string,
    countryCode: CountryCode
  ): Observable<string> {
    return of('CE-FER_LE_NZ-4bf30383-d294-4587-87b1-f6ac58395221');
  }

  submit(
    stepId: string,
    entityUid: string,
    countryCode: CountryCode,
    processUid: string,
    responseObjectName: string,
    data: any
  ): Observable<boolean> {
    return of(true);
  }

  processCompleteStep(
    stepId: string,
    entityUid: string,
    countryCode: CountryCode,
    processUid: string,
    responseObjectName: string,
    data: any
  ): Observable<boolean> {
    return of(true);
  }

  cancel(
    processUid: string,
    entityUid: string,
    countryCode: CountryCode
  ): Observable<boolean> {
    return of(true);
  }

  action(
    actionName: string,
    processUid: string,
    stepId: string,
    entityUid: string,
    countryCode: CountryCode
  ): Observable<boolean> {
    return of(true);
  }

  replay(
    processUid: string,
    entityUid: string,
    countryCode: CountryCode
  ): Observable<boolean> {
    return of(true);
  }

  message(processUid: string): Observable<ProcessMessage> {
    return this.processMessage
      .asObservable()
      .pipe(
        map(
          (message: ProcessMessage): ProcessMessage => message as ProcessMessage
        )
      );
  }

  nextMessage(message?: ProcessMessage) {
    this.processMessage.next(<ProcessMessage>message);
  }

  resume(
    entityUid: string,
    countryCode: CountryCode,
    processUid: string
    // ): Observable<{ processResume: string }> {
  ): Observable<any> {
    return of(true);
  }

  sendGoogleCID(
    processUid: string,
    entityUid: string,
    countryCode: CountryCode,
    cid: string
  ): Observable<boolean> {
    return of(true);
  }
}
