import { AppConfig } from '../app/config/app.config';

export interface Environment {
  production: boolean;
  MOCK_BE: boolean;
  type: EnvironmentType;
  develMode: DevelMode;
  appConfig: Partial<AppConfig>;
}

export interface DevelMode {
  state: boolean;
}

export enum EnvironmentType {
  DEFAULT = 'default',
  TESTING = 'testing',
  VISUAL_REGRESSION = 'visual-regression',
  VISUAL_REGRESSION_CI = 'visual-regression-ci'
}
