import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { ClickOutsideModule } from 'ng-click-outside';

import { UserComponent } from './user/user.component';
import { HeaderComponent } from './header/header.component';
import { HeaderContainerComponent } from './header-container/header-container.component';
import { HeaderDropDownModule } from './../header-drop-down/header-drop-down.module';
import { HeaderContentFacade } from './facades/header-content.facade';

const COMPONENTS = [HeaderComponent, HeaderContainerComponent, UserComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,

    ClickOutsideModule,

    HeaderDropDownModule
  ],
  providers: [HeaderContentFacade],
  exports: [HeaderComponent]
})
export class HeaderModule {}
