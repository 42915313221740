import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ApolloError } from 'apollo-client';

import { EnvConfigService } from '../config-handler/config-handler.service';
import { UserService } from '../user/user.service';
import { AUTH_MODEL, AuthProvider } from '../../../shared/auth';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private userService: UserService;

  constructor(
    @Inject(AUTH_MODEL) private authModel: AuthProvider,
    private injector: Injector
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        filter((response: HttpResponse<any>) => this.checkUserAuth(response))
      );
  }

  private checkUserAuth(response: HttpResponse<any>): boolean {
    let isUserAuthorized = false;

    if (!response.body || !EnvConfigService.config) {
      return true;
    }

    if (!this.userService) {
      this.userService = this.injector.get(UserService);
    }

    if (Array.isArray(response.body)) {
      isUserAuthorized = !response.body.some(
        data => data.errors && this.checkUserAuthError(data.errors)
      );
    } else {
      isUserAuthorized = !(
        response.body.errors && this.checkUserAuthError(response.body.errors)
      );
    }

    if (!isUserAuthorized) {
      this.userService.logout();
    }

    return isUserAuthorized;
  }

  private checkUserAuthError(errors: ApolloError[]): boolean {
    return errors.some((graphQLError: any) => {
      return graphQLError.domain && graphQLError.domain['UW_401'];
    });
  }
}
