import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { HttpBatchLinkModule } from 'apollo-angular-link-http-batch';
import { PROCESS_ENGINE_DEVEL_SERVICE_PROVIDER } from './process-engine-service/process-engine.provider';
import { PROCESS_DEVEL_MODEL_PROVIDER } from './process-engine-model/process-model.provider';

@NgModule({
  imports: [CommonModule, ApolloModule, HttpLinkModule, HttpBatchLinkModule],
  declarations: [],
  providers: []
})
export class ProcessEngineMocksModule {
  static forRoot(): ModuleWithProviders<any> {
    console.log('running mocks module');
    return {
      ngModule: ProcessEngineMocksModule,
      providers: [
        PROCESS_ENGINE_DEVEL_SERVICE_PROVIDER,
        PROCESS_DEVEL_MODEL_PROVIDER
      ]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ProcessEngineMocksModule
  ) {
    if (parentModule) {
      throw new Error(
        'ProcessEngineMocksModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
