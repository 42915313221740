import { FormControl } from '@angular/forms';

interface FeFormControlParams {
  required: boolean;
  editable: boolean;
}

export class FeFormControl extends FormControl {
  private params: FeFormControlParams;

  setParams(params: FeFormControlParams) {
    this.params = params;
  }

  getParams(): FeFormControlParams {
    return this.params;
  }
}
