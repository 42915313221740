import { emailVerificationMockMessageList } from '../process-messages/email-verification-mock-message';

export interface ProcessEngineSteps {
  [step: string]: any;
}

export interface ProcessVariants {
  [processName: string]: string;
}

export enum ProcessPath {
  REGISTRATION_MESSAGE = 'registrationMessage'
}

export const ProcessEngineVariants: ProcessVariants = {
  TppRegistration: ProcessPath.REGISTRATION_MESSAGE
};

export const processEngineStepsConfig: ProcessEngineSteps = {
  registrationPage: {
    registrationMessage: [emailVerificationMockMessageList[0]]
  },
  addressAndDocumentPage: {
    defaultMessage: emailVerificationMockMessageList
  }
};
