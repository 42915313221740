import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthState } from '../entity/auth-state';
import { AuthProvider } from './auth.provider';
import { COOKIE_SERVICE } from '../../../core/services/cookie/cookie.token';
import { CookieStore } from '../../../core/services/cookie/cookie-store';
import { CookieType } from '../../../core/entity/cookie/cookie-type';

@Injectable()
export class AuthModel implements AuthProvider {
  private authStateSubject: BehaviorSubject<AuthState>;

  constructor(
    @Inject(COOKIE_SERVICE) private cookieService: CookieStore,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.initAuthStateSubject();

    this.authStateSubject.subscribe((authState: AuthState) => {
      // update storage with each auth state change
      if (isPlatformBrowser(this.platformId)) {
        this.cookieService.put(CookieType.AUTH_TOKEN, authState.accessToken);
      }
    });
  }

  /**
   * Auth state accessor
   */
  get authState(): Observable<AuthState> {
    return this.authStateSubject.asObservable();
  }

  private initAuthStateSubject(): void {
    // init auth state from storage / cookie
    const accessToken: string =
      this.cookieService.get(CookieType.AUTH_TOKEN) || '';
    this.authStateSubject = new BehaviorSubject(new AuthState(accessToken));
  }

  /**
   * Login redirect result, as a result of the following:
   *
   * 1. user is redirected to
   * `https://auth-server.dev.ferratum.com/oauth/authorize
   * ?response_type=token&client_id=${clientIdStoredInConfig}&state=optionalStateYouWantBack`
   * 2. user logs in on auth server login page
   * 3. Auth server redirects back to UI, when creating client you specify the redirect URL - in this route, inject this model
   *
   * @param queryString
   */
  loginSuccess(queryString: string): Observable<{ [key: string]: string }> {
    // update state to loginInProgress
    this.authStateSubject.next(new AuthState('', '', true));

    // update state by token
    const responseParams = this.parseData(queryString);
    const authState = new AuthState(responseParams.access_token);
    this.authStateSubject.next(authState);

    // save token into cookie
    const expiryTime = new Date();
    expiryTime.setHours(expiryTime.getHours() + 1);
    this.cookieService.put(CookieType.AUTH_TOKEN, authState.accessToken, {
      expires: expiryTime
    });

    // extract state object and return it
    const decodedState = atob(responseParams['state']);
    const state = JSON.parse(decodedState);

    return of(state);
  }

  /**
   * Logout
   */
  logout(): Observable<boolean> {
    this.cookieService.remove(CookieType.AUTH_TOKEN);

    this.authStateSubject.next(new AuthState(''));

    return of(true);
  }

  isAuthenticated(): Observable<boolean> {
    return this.authStateSubject.pipe(
      map((authState: AuthState) => !!authState.accessToken)
    );
  }

  accessToken(): Observable<string> {
    return this.authStateSubject.pipe(
      map((authState: AuthState) => authState.accessToken)
    );
  }

  tokenData(): Observable<any> {
    return this.authStateSubject.pipe(
      map((authState: AuthState) => {
        if (authState.accessToken) {
          const parts = authState.accessToken.split('.');
          if (parts && parts.length > 1) {
            const json = atob(parts[1]);
            return JSON.parse(json);
          }
        }
        return null;
      })
    );
  }

  private parseData(queryString: string): { [key: string]: string } {
    const query: { [key: string]: string } = {};
    const pairs = (queryString[0] === '?'
      ? queryString.substr(1)
      : queryString
    ).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  /**
   * CUBA 2 Relation UUID
   */
  relationUid(): Observable<string> {
    return this.tokenData().pipe(
      map((tokenData: any) => {
        if (tokenData && tokenData.relationUid) {
          return tokenData.relationUid;
        }
        return '';
      })
    );
  }
}
