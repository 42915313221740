import { InjectionToken, Inject } from '@angular/core';
import { ProcessEngineServiceAccess } from './process-engine-service-access';
import { ProcessEngineService } from './process-engine.service';
import { GraphServiceAccess } from '../../graph/graph-service-access';
import { GRAPH_SERVICE } from '../../graph/graph.provider';

export const PROCESS_ENGINE_SERVICE = new InjectionToken<
  ProcessEngineServiceAccess
>('ProcessEngineServiceAccess');

export function processEngineServiceFactory(graphService: GraphServiceAccess) {
  return new ProcessEngineService(graphService);
}

export const PROCESS_ENGINE_SERVICE_PROVIDER = {
  provide: PROCESS_ENGINE_SERVICE,
  useFactory: processEngineServiceFactory,
  deps: [[new Inject(GRAPH_SERVICE)]]
};
