import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Observable } from 'apollo-link';

@Injectable()
export class FormConfigService {
  // Propagate 'Close Button' display state
  private isCloseButtonShown: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public $isCloseButtonShown = this.isCloseButtonShown.asObservable();

  // Dispatch 'Close button' click
  private closeClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public $closeClick = this.closeClick.asObservable();

  // Dispatch 'Custom button' click
  private customClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public $customClick = this.customClick.asObservable();

  constructor() {}

  public propagateCloseButtonState(state: boolean): void {
    this.isCloseButtonShown.next(state);
  }

  public dispatchCloseClick(state: boolean): void {
    this.closeClick.next(state);
  }

  public dispatchCustomClick(state: boolean): void {
    this.customClick.next(state);
  }
}
