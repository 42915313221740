import { Header } from '../models/header.model';

export const getHeaderConfig = (isUserLoggedIn: boolean): Header => {
  return {
    headerLogo: 'assets/images/multitude_bank_logos_02.svg',
    headerLinks: [
      {
        link: isUserLoggedIn ? '/account/companies' : '/home',
        title: 'Home'
      },
      {
        link: '/faq',
        title: 'FAQ'
      },
      {
        link: '/terms-of-use',
        title: 'Terms of Use'
      },
      {
        link: '/contacts',
        title: 'Contacts'
      },
      {
        link: '/documentation',
        title: 'Documentation'
      },
      {
        link: '/statistics',
        title: 'Statistics'
      }
    ],
    mobileMenu: {
      icon: 'assets/images/ic-mobile-menu.svg'
    },
    closeIcon: 'assets/images/ic-close-white.svg'
  };
};
