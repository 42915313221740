import {
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
  Location
} from '@angular/common';
import {
  ErrorHandler,
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'apollo-client/util/Observable';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  Subscriber,
  throwError,
  timer
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';

import { CountryCode } from '../../../entity/content/country/country-code.enum';
import { CookieType } from '../../../entity/cookie/cookie-type';
import { ProcessCurrentStepName } from '../../../entity/process/process-current-step-name';
import { ProcessMessage } from '../../../entity/process/process-message';
import { ProcessName } from '../../../entity/process/process-name';
import { CookieStore } from '../../cookie/cookie-store';
import { COOKIE_SERVICE } from '../../cookie/cookie.token';
import { BrowserWindowApi } from '../../window/browser-window-api';
import { WINDOW } from '../../window/window.token';
import { ProcessEngineFormUtils } from '../form-utils/form-utils';
import { ProcessEngineServiceAccess } from '../service/process-engine-service-access';
import { PROCESS_ENGINE_SERVICE } from '../service/process-engine.provider';
import { ProcessEngineModelAccess } from './process-engine-model-access';
import { ToastService } from '../../toast-service/toast.service';
import { EnvConfigService } from '../../config-handler/config-handler.service';

export enum ProcessCancelReason {
  ScrapperCanceled
}

export enum ProcessActions {
  BACK = 'goBack',
  EMAIL_VERIFY_PIN_AGAIN = 'sendPinAgain'
}

export interface DomainError {
  [errorCode: string]: { title: string; detail: string }[];
}

export interface ProcessEngineGraphQLError {
  domain: DomainError;
  extensions: { code: string };
}

export interface ProcessEngineError {
  graphQLErrors: ProcessEngineGraphQLError[];
}

@Injectable()
export class ProcessEngineModelService
  implements OnDestroy, ProcessEngineModelAccess {
  message: BehaviorSubject<ProcessMessage | undefined> = new BehaviorSubject(
    undefined
  );
  progress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  active: BehaviorSubject<boolean> = new BehaviorSubject(false);
  activeProcess: BehaviorSubject<ProcessName | null> = new BehaviorSubject(
    null
  );
  isBrowser: boolean;
  isServer: boolean;

  private messageSub: Subscription;
  private processId: string;
  private redirectUri: string | boolean;
  private formUtils: ProcessEngineFormUtils;
  private resuming: boolean;
  private _processCancelReason: ProcessCancelReason;
  private destroyed: Subject<boolean> = new Subject();
  private _errorData: Subject<ProcessEngineError> = new Subject();
  private timer: Subject<number> = new Subject<number>();
  private subscribeTimer: Subscription;
  private isSuccessRegistrationProcess: boolean;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PROCESS_ENGINE_SERVICE)
    private processEngine: ProcessEngineServiceAccess,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(WINDOW) private window: BrowserWindowApi,
    private location: Location,
    private router: Router,
    private handleError: ErrorHandler,
    @Inject(COOKIE_SERVICE) private cookieService: CookieStore,
    private readonly toastService: ToastService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isServer = isPlatformServer(this.platformId);
  }

  /**
   * Clean up subscriptions
   */
  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  /**
   * starts the process and subscribes to messages from process engine
   */
  start(
    process: ProcessName,
    parameters: any,
    redirectUri: string | boolean
  ): Observable<string> {
    if (this.processId) {
      let internalSubscriber: Subscriber<string>;
      return new Observable<string>((subscriber: Subscriber<string>) => {
        internalSubscriber = subscriber;
        throw new Error(
          `Cannot start new process - other process ${this.processId} is running`
        );
      }).pipe(tap(() => internalSubscriber.complete()));
    }

    this.progress.next(true);
    this.active.next(true);
    this.activeProcess.next(process);

    const processName = process.toString();
    return this.processEngine
      .start(processName, parameters, 'CE-FBM_MB_FER', CountryCode.MALTA)
      .pipe(
        tap((processId: string) => {
          this.initializeTimeout('START', {
            processName: processName,
            parameters: parameters,
            entityUid: 'CE-FBM_MB_FER',
            countryCode: CountryCode.MALTA
          });

          if (!processId) {
            throw new Error(`Failed to start a process ${processName}`);
          }
          this.redirectUri = redirectUri;
          this.subscribeProcessMessages(processId);
          this.processId = processId;
        }),
        catchError((error: any) => {
          console.error(`Failed to start a process ${processName}: ${error}`);
          this.processFinished();
          return throwError(error);
        })
      );
  }

  /**
   * submits the data & finishes the process if isFinalTask
   */
  submit(
    data: any,
    redirectUri: string | boolean = this.redirectUri,
    allowFinish: boolean = true,
    forceFinish: boolean = false,
    actionName?: string
  ): Observable<boolean> {
    this.redirectUri = redirectUri;
    const currentMessage = this.message.value;
    const submitSubject = new Subject<boolean>();
    if (!currentMessage) {
      console.log(
        `Unable to submit data - current message value is ${currentMessage}`
      );
      submitSubject.next(false);
      submitSubject.complete();
    } else {
      this.resuming = false;
      this.formUtils = new ProcessEngineFormUtils(this.message);
      data = this.formUtils.preprocessRequestData(data);

      this.progress.next(true);

      const userTaskId = currentMessage.action.userTaskUid;
      const responseObjectName = currentMessage.action.responseObjectName;

      this.processEngine
        .submit(
          userTaskId,
          'CE-FBM_MB_FER',
          CountryCode.MALTA,
          ProcessName.TPP_REGISTRATION_PROCESS,
          currentMessage.process.processUid,
          responseObjectName,
          data,
          actionName
        )
        .pipe(
          catchError((error: ProcessEngineError) => {
            this._errorData.next(error);
            return of(false);
          })
        )
        .subscribe(
          (success: boolean) => {
            this.initializeTimeout('SUBMIT', {
              userTaskId: userTaskId,
              entityUid: 'CE-FBM_MB_FER',
              countryCode: CountryCode.MALTA,
              currentMessage: currentMessage.process.processUid,
              responseObjectName: responseObjectName,
              data: data,
              actionName: actionName
            });

            if (currentMessage.action.isTaskToBeSubmitted === null) {
              currentMessage.action.isTaskToBeSubmitted = true;
            }
            if (
              (currentMessage.action.isFinalTask &&
                currentMessage.action.isTaskToBeSubmitted &&
                allowFinish) ||
              forceFinish
            ) {
              this.processFinished();
            }
            submitSubject.next(success);
            submitSubject.complete();
            this.isSuccessRegistrationProcess =
              responseObjectName === ProcessCurrentStepName.REGISTRATION_PAGE;
          },
          error => {
            console.error(
              `Failed to submit values to user taskID ${currentMessage.action.userTaskUid}`
            );
            this.progress.next(false);
            submitSubject.next(false);
            submitSubject.complete();
            throw error;
          }
        );
    }
    return submitSubject.asObservable();
  }

  /**
   * Submits process action for given processUid.
   * Action is defined in data and is allowed only when it is defined in allowedActions
   * in previously received process message.
   */
  processSubmitAction(
    processUid: string,
    action: string,
    redirectUri: string | boolean = this.redirectUri
  ): Observable<boolean> {
    this.redirectUri = redirectUri;
    const currentMessage = this.message.value;

    if (!currentMessage) {
      console.log(
        `Unable to complete step data - current message value is ${currentMessage}`
      );
      return of(false);
    } else {
      this.resuming = false;
      this.formUtils = new ProcessEngineFormUtils(this.message);
      if (!this.formUtils.isActionAllowed(action)) {
        return of(false);
      }

      this.progress.next(true);

      const userTaskId = currentMessage.action.userTaskUid;
      const body = {
        actionName: action
      };

      return this.processEngine
        .processCompleteStep(
          userTaskId,
          'CE-FBM_MB_FER',
          CountryCode.MALTA,
          ProcessName.TPP_REGISTRATION_PROCESS,
          processUid,
          'actionPage',
          body
        )
        .pipe(
          tap(
            () => {
              this.progress.next(false);
            },
            () => {
              this.progress.next(false);
            }
          )
        );
    }
  }

  /**
   * goes back to previous action
   */
  back() {
    this.action(ProcessActions.BACK);
  }

  action(actionName: ProcessActions) {
    const currentMessage = this.message.value;
    if (!currentMessage) {
      console.log(
        `Unable to call action ${actionName} - current message value is ${currentMessage}`
      );
      return;
    }
    this.progress.next(true);
    const responseObjectName = currentMessage.action.responseObjectName;
    const userTaskId = currentMessage.action.userTaskUid;
    this.processEngine
      .action(
        actionName,
        this.processId,
        userTaskId,
        'CE-FBM_MB_FER',
        CountryCode.MALTA,
        ProcessName.TPP_REGISTRATION_PROCESS
      )
      .subscribe(
        (success: boolean) => {
          this.initializeTimeout('ACTION', {
            actionName: actionName,
            processId: this.processId,
            userTaskId: userTaskId,
            entityUid: 'CE-FBM_MB_FER',
            countryCode: CountryCode.MALTA
          });
          console.log(
            `Process action ${actionName} succesfully called on ${this.processId}`
          );
        },
        error => {
          console.error(
            `Failed to call action ${actionName} on process ${this.processId}`
          );
          this.progress.next(false);
          throw error;
        }
      );
  }

  /**
   * restoring / replay current step from process engine
   * TODO: currently using ProcessState localStorage to restore message as no such support is in process engine
   */
  restore() {
    if (!this.processId) {
      throw new Error(
        `There is no active process - cannot with replay process message`
      );
    }
    this.progress.next(true);
    this.processEngine
      .replay(this.processId, 'CE-FBM_MB_FER', CountryCode.MALTA)
      .subscribe(
        (success: boolean) => {
          this.initializeTimeout('ACTION', {
            processId: this.processId,
            entityUid: 'CE-FBM_MB_FER',
            countryCode: CountryCode.MALTA
          });
        },
        error => {
          console.error(`Failed to replay process step ${this.processId}`);
          this.progress.next(false);
          throw error;
        }
      );
  }

  /**
   * cancels current process
   * @param {string | boolean} redirectUri - set redirect uri when it's different compared to initial in start/submit/resume
   * @param processCancelReason
   */
  cancel(
    redirectUri?: string | boolean,
    processCancelReason?: ProcessCancelReason
  ): Observable<boolean> {
    if (!this.processId) {
      return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
        subscriber.next(false);
        subscriber.complete();
      });
    }

    if (processCancelReason !== undefined) {
      this.processCancelReason = processCancelReason;
    }

    if (redirectUri !== undefined) {
      this.redirectUri = redirectUri;
    }

    this.processFinished(false);
    return this.processEngine
      .cancel(
        this.processId,
        'CE-FBM_MB_FER',
        CountryCode.MALTA,
        ProcessName.TPP_REGISTRATION_PROCESS
      )
      .pipe(
        tap(() => {
          this.destroyTimer();
          this.clearProcessId();
        }),
        catchError((error: any) => {
          console.error(`Failed to cancel a process ${this.processId}`);
          return throwError(error);
        })
      );
  }

  set processCancelReason(processCancelReason: ProcessCancelReason) {
    this._processCancelReason = processCancelReason;
  }

  get processCancelReason() {
    return this._processCancelReason;
  }

  /**
   * resumes current process
   */
  resume(
    processId: string,
    redirectUri: string,
    finishProcess = true
  ): Observable<void> {
    if (!processId) {
      if (!finishProcess) {
        this.active.next(true);
      }
      return throwError(false);
    }
    this.progress.next(true);
    this.active.next(true);
    this.redirectUri = redirectUri;

    this.subscribeProcessMessages(processId);

    return this.processEngine
      .resume(
        'CE-FBM_MB_FER',
        CountryCode.MALTA,
        processId,
        ProcessName.TPP_REGISTRATION_PROCESS
      )
      .pipe(
        map(data => {
          if (!(data && data.processResume)) {
            throw new Error('mutation resumeProcess returned no data');
          }

          this.initializeTimeout('ACTION', {
            entityUid: 'CE-FBM_MB_FER',
            countryCode: CountryCode.MALTA,
            processId: processId
          });

          this.resuming = true;

          if (this.processId !== data.processResume) {
            throw new Error(
              'mutation resumeProcess returned changed processId'
            );
          }
        }),
        catchError(error => {
          console.error(`Failed to resume a process: ${error}`);
          if (finishProcess) {
            this.processFinished();
          }
          throw false;
        })
      );
  }

  sendGoogleCid(cid: string): Observable<boolean> {
    return of(false);
  }

  /**
   * @param {string | any[]} navigateTo Url to navigate to
   * @param {boolean} isExternalUrl Specifies whether navigating to external url. Defaults to 'false'.
   * @param {string} navigateToOnBrowserBack Url that replaces the most recent state on the history stack
   * Allows to redirect from process while providing custom behaviour for browser's back button
   */
  navigateFromProcess(
    navigateTo: string,
    isExternalUrl = false,
    navigateToOnBrowserBack?: string
  ) {
    if (navigateToOnBrowserBack) {
      this.location.replaceState(navigateToOnBrowserBack);
    }
    if (isExternalUrl) {
      this.document.location.href = navigateTo;
    } else {
      this.router.navigateByUrl(navigateTo);
    }
  }

  get inProgress(): Observable<boolean> {
    return this.progress.asObservable();
  }

  get errorData(): Observable<ProcessEngineError> {
    return this._errorData.pipe(
      filter((errorData: ProcessEngineError) => !!errorData)
    );
  }

  isResuming(): boolean {
    return this.resuming;
  }

  setResuming(value: boolean): void {
    this.resuming = value;
  }

  getCurrentProcessId() {
    return this.processId;
  }

  /**
   * listen for process messages through graphql subscription
   */
  private subscribeProcessMessages(processId: string) {
    this.processId = processId;

    this.messageSub = this.processEngine
      .message(processId, CountryCode.MALTA, 'CE-FBM_MB_FER')
      .subscribe((message: ProcessMessage) => {
        if (this.isServer) {
          return;
        }

        this.destroyTimer();
        this.message.next(message);
        this.progress.next(false);

        if (this.isSuccessRegistrationProcess) {
          const errorAttributes = (message.action.components || []).reduce(
            (acc, component) => {
              const errorAtt = (component.attributes || []).find(
                attribute =>
                  attribute.attributeName === 'conflictWithExistingCustomer'
              );
              return errorAtt ? [...acc, errorAtt] : acc;
            },
            []
          );
          if (errorAttributes.length > 0) {
            this.toastService.showError({
              headline: 'Registration failed',
              text: 'An account with this email already exists.'
            });
          }

          if (
            message.action.responseObjectName ===
            ProcessCurrentStepName.EMAIL_VERIFICATION_PAGE
          ) {
            this.cookieService.put(
              CookieType.SUCCESS_REGISTRATION_PROCESS,
              JSON.stringify(message)
            );
            this.isSuccessRegistrationProcess = false;
          }
        }
      });
  }

  /**
   * cleanup after process is finished
   */
  private processFinished(isToClearProcessId: boolean = true) {
    if (this.messageSub) {
      this.messageSub.unsubscribe();
    }

    this.message.next(undefined);
    this.progress.next(false);
    this.active.next(false);
    this.activeProcess.next(null);
    this.resuming = false;

    if (this.redirectUri && typeof this.redirectUri === 'string') {
      this.router.navigateByUrl(this.redirectUri);
    }

    if (isToClearProcessId) {
      this.clearProcessId();
    }
  }

  private clearProcessId() {
    // @ts-ignore
    delete this.processId;
  }

  changeSubscription(processId: string): Observable<boolean> {
    const changeSubject = new Subject<boolean>();
    const oldProcessId = this.processId;

    this.subscribeProcessMessages(processId);

    this.processId = oldProcessId;

    this.submit({}, '', false).subscribe((submitted: boolean) => {
      if (submitted) {
        this.processId = processId;
      }
      changeSubject.next(submitted);
      changeSubject.complete();
    });

    return changeSubject.asObservable();
  }

  clearOnboardingProcess() {
    if (this.processId) {
      this.processFinished();
    }
  }

  // TODO: WHAT TIME BRO
  initializeTimeout(type: string, message: any): void {
    this.subscribeTimer = this.timer
      .pipe(     // @ts-ignore
        switchMap(() => timer(this.loggingTime())),
        takeUntil(this.destroyed)
      )
      .subscribe(() => {
        this.reportLog(type, message);
      });
    // @ts-ignore
    this.timer.next();
  }

  destroyTimer(): void {
    if (this.subscribeTimer) {
      this.subscribeTimer.unsubscribe();
    }
  }

  reportLog(type: string, message: any): void {
    const error = {
      name: `TIMELEAK ${type}`,
      message: JSON.stringify(message)
    };
    this.handleError.handleError(error);
  }

  loggingTime(): number | undefined {
    return EnvConfigService.config && EnvConfigService.config.timeLeak * 1000;
  }
}
