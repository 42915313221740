export enum ErrorCodes {
  ConflictError = 'UW_409',
  AccessDeniedError = 'UW_403',
  ResourceNotFoundError = 'UW_404',
  AcceptableError = 'UW_406',
  RequestTimedOutError = 'UW_408',
  GatewayTimeoutError = 'UW_504',
  InternalServerError = 'UW_500',
  InvalidTokenError = 'UW_401',
  BadRequestError = 'UW_400'
}

export enum ErrorMessage {
  CompanyCreate = 'Company with this Company identification already exists',
  DownloadCertificate = 'Your company was created, but certificate has format error',
  Unexpected = 'Unexpected error',
  InvalidCertificate = 'Invalid certificate. Please upload valid one to proceed.'
}
