import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { HttpBatchLinkModule } from 'apollo-angular-link-http-batch';
import { CookieService } from 'ngx-cookie-service';

import { BackOfficeGuard } from './guards/back-office-user.guard';
import { OnboardingGuard } from './guards/onboarding-guard/onboarding.guard';
import { AuthInterceptorService } from './services/auth-interceptor/auth-interceptor.service';
import { AuthService } from './services/auth-service/auth.service';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';
import { CookieAdapterService } from './services/cookie/cookie-adapter.service';
import { COOKIE_SERVICE } from './services/cookie/cookie.token';
import { CountryService } from './services/country/country.service';
import { ErrorCodesService } from './services/error-codes/error-codes.service';
import {
  ERROR_HANDLER_SERVICE,
  GlobalErrorHandlerService
} from './services/error-handler/global-error-handler.service';
import { FormConfigService } from './services/form-config/form-config.service';
import { GRAPH_SERVICE_PROVIDER } from './services/graph/graph.provider';
import { AuthLink } from './services/graph/links/auth-link';
import { SubscriptionLink } from './services/graph/links/subscription-link';
import { SUBSCRIPTION_LINK } from './services/graph/links/subscription-link.token';
import { PROCESS_MODEL_PROVIDER } from './services/process-engine/model/process-model.provider';
import { PROCESS_ENGINE_SERVICE_PROVIDER } from './services/process-engine/service/process-engine.provider';
import { SiteService } from './services/site/site-service';
import { SITE_SERVICE } from './services/site/site-service.token';
import { TppIdentityManagementService } from './services/tpp-identity-management/tpp-identity-management.service';
import { TPP_IDENTITY_MANAGEMENT } from './services/tpp-identity-management/tpp-identity-management.token';
import { UserService } from './services/user/user.service';
import { WINDOW_PROVIDER } from './services/window/window.provider';
import { TOAST_SERVICE } from './services/toast-service/toast.token';
import { ToastService } from './services/toast-service/toast.service';

export const COMPONENTS = [];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, ApolloModule, HttpLinkModule, HttpBatchLinkModule]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        BackOfficeGuard,
        OnboardingGuard,
        GRAPH_SERVICE_PROVIDER,
        AuthLink,
        UserService,
        ErrorCodesService,
        AuthService,
        CookieService,
        {
          provide: TPP_IDENTITY_MANAGEMENT,
          useClass: TppIdentityManagementService
        },
        { provide: SUBSCRIPTION_LINK, useClass: SubscriptionLink },
        WINDOW_PROVIDER,
        CountryService,
        PROCESS_ENGINE_SERVICE_PROVIDER,
        PROCESS_MODEL_PROVIDER,
        { provide: COOKIE_SERVICE, useClass: CookieAdapterService },
        { provide: TOAST_SERVICE, useClass: ToastService },
        CookieConsentService,
        FormConfigService,
        { provide: SITE_SERVICE, useClass: SiteService },
        { provide: ERROR_HANDLER_SERVICE, useClass: GlobalErrorHandlerService },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true
        }
      ]
    };
  }
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    apollo: Apollo
  ) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
