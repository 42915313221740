import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppConfig } from '../../../../config/app.config';
import { SiteConfig } from '../../../../config/site/site.config';
import { Country } from '../../entity/content/country/country';
import { EnvConfigService } from '../config-handler/config-handler.service';
import { countryConfig } from '../country/country-config';
import { BrowserWindowApi } from '../window/browser-window-api';
import { WINDOW } from '../window/window.token';
import { SiteServiceInterface } from './site-service.interface';

@Injectable()
export class SiteService implements SiteServiceInterface {
  private _currentSite: Observable<SiteConfig>;

  constructor(@Inject(WINDOW) private window: BrowserWindowApi) {
    this._currentSite = of(EnvConfigService.config).pipe(
      map((appConf: AppConfig): SiteConfig | undefined => {
        return appConf.sites.find((item: SiteConfig) =>
          item.hostname.includes(this.hostname)
        );
      }),
      filter((siteConfig: SiteConfig) => !!siteConfig),
      map((siteConfig: SiteConfig) => siteConfig)
    );
  }

  get currentSite(): Observable<SiteConfig> {
    return this._currentSite;
  }

  get currentCountry(): Observable<Country> {
    return this.siteId.pipe(
      map((siteId: string): Country | undefined => {
        return countryConfig.find(
          (country: Country) => country.siteId === siteId
        );
      }),
      filter((country: Country) => !!country),
      map((country: Country) => country)
    );
  }

  get siteId(): Observable<string> {
    return this._currentSite.pipe(
      map((siteConfig: SiteConfig): string => siteConfig.siteId)
    );
  }

  get cmsApiUrl(): Observable<string> {
    return this._currentSite.pipe(
      map((siteConfig: SiteConfig): string => siteConfig.cmsUrl)
    );
  }

  get apiUrl(): Observable<string> {
    return of(EnvConfigService.config).pipe(
      map((appConfig: AppConfig): string => {
        return appConfig ? appConfig.apiUrl : '';
      })
    );
  }

  get hostname(): string {
    return this.window.location.hostname;
  }
}
