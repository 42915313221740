import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { User } from '../../models/user.model';
import { UserService } from '../../services/user/user.service';

@Injectable()
export class OnboardingGuard implements CanLoad, CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  private checkUserLoggedIn(): Observable<boolean> {
    return this.userService.user.pipe(
      take(1),
      map(user => {
        const isUserLoggedIn: boolean = !!Object.keys(user as User).length;

        if (isUserLoggedIn) {
          this.router.navigate(['account', 'companies']);
          return false;
        }

        return true;
      })
    );
  }

  canLoad(): Observable<boolean> {
    return this.checkUserLoggedIn();
  }

  canActivate(): Observable<boolean> {
    return this.checkUserLoggedIn();
  }
}
