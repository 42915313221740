import { Inject, Injectable } from '@angular/core';
import { AuthServiceProvider } from './auth-service.provider';

import { AuthConfig } from '../config/auth-config';
import { WINDOW } from '../../../core/services/window/window.token';
import { BrowserWindowApi } from '../../../core/services/window/browser-window-api';
import { AUTH_CONFIG } from '../config/auth-config.token';

@Injectable()
export class AuthService implements AuthServiceProvider {
  constructor(
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    @Inject(WINDOW) private window: BrowserWindowApi
  ) {}

  redirectToAuthServer(locale: string, redirectUrl: string): void {
    const state: any = { redirectUrl: redirectUrl };
    const encodedState = this.window.btoa(JSON.stringify(state));
    const requestParams = `response_type=token&client_id=${this.authConfig.authClientId}&language=${locale}&state=${encodedState}`;
    const authServerUrl = `${this.authConfig.authUrl}/oauth/authorize?${requestParams}`;

    if (this.window) { // redirect to auth server
      this.window.location.href = authServerUrl;
    }
  }
}
