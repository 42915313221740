import { Inject, Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { CountryCode } from '../../../entity/content/country/country-code.enum';
import { ProcessMessage } from '../../../entity/process/process-message';
import { ProcessName } from '../../../entity/process/process-name';
import { GraphServiceAccess } from '../../graph/graph-service-access';
import { GRAPH_SERVICE } from '../../graph/graph.provider';
import { ProcessEngineServiceAccess } from './process-engine-service-access';

@Injectable()
export class ProcessEngineService implements ProcessEngineServiceAccess {
  constructor(
    @Inject(GRAPH_SERVICE) private graphService: GraphServiceAccess
  ) {}

  start(
    name: string,
    parameters: any,
    entityUid: string,
    countryCode: CountryCode,
    traceId?: string
  ): Observable<string> {
    return this.graphService
      .mutate<string>({
        mutation: gql`
          mutation processStart(
            $name: String!
            $entityUid: String!
            $countryCode: String!
            $parameters: JSON!
            $traceId: String
          ) {
            processUid: processStart(
              name: $name
              entityUid: $entityUid
              countryCode: $countryCode
              parameters: $parameters
              traceId: $traceId
            )
          }
        `,
        variables: { name, parameters, entityUid, countryCode, traceId }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.processUid : ''
        )
      );
  }

  submit(
    stepId: string,
    entityUid: string,
    countryCode: CountryCode,
    processName: ProcessName,
    processUid: string,
    responseObjectName: string,
    data: any,
    actionName?: string
  ): Observable<boolean> {
    return this.graphService
      .mutate<boolean>({
        mutation: gql`
          mutation processCompleteStep(
            $stepId: String!
            $entityUid: String!
            $countryCode: String!
            $processName: String!
            $processUid: String!
            $responseObjectName: String!
            $data: JSON!
            $actionName: String
          ) {
            result: processCompleteStep(
              stepId: $stepId
              entityUid: $entityUid
              countryCode: $countryCode
              processName: $processName
              processUid: $processUid
              responseObjectName: $responseObjectName
              data: $data
              actionName: $actionName
            )
          }
        `,
        variables: {
          stepId,
          entityUid,
          countryCode,
          processName,
          processUid,
          responseObjectName,
          data,
          actionName
        }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.result : false
        )
      );
  }

  processCompleteStep(
    stepId: string,
    entityUid: string,
    countryCode: CountryCode,
    processName: ProcessName,
    processUid: string,
    responseObjectName: string,
    data: any,
    traceId?: string
  ): Observable<boolean> {
    return this.graphService
      .mutate<boolean>({
        mutation: gql`
          mutation processCompleteStep(
            $stepId: String!
            $entityUid: String!
            $countryCode: String!
            $processName: String!
            $processUid: String!
            $responseObjectName: String!
            $data: JSON!
            $traceId: String
          ) {
            result: processCompleteStep(
              stepId: $stepId
              entityUid: $entityUid
              countryCode: $countryCode
              processName: $processName
              processUid: $processUid
              responseObjectName: $responseObjectName
              data: $data
              traceId: $traceId
            )
          }
        `,
        variables: {
          stepId,
          entityUid,
          countryCode,
          processName,
          processUid,
          responseObjectName,
          data,
          traceId
        }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.result : false
        )
      );
  }

  cancel(
    processUid: string,
    entityUid: string,
    countryCode: CountryCode,
    processName: ProcessName
  ): Observable<boolean> {
    return this.graphService
      .mutate<boolean>({
        mutation: gql`
          mutation processCancel(
            $entityUid: String!
            $countryCode: String!
            $processUid: String!
            $processName: String!
          ) {
            processCancel(
              entityUid: $entityUid
              countryCode: $countryCode
              processUid: $processUid
              processName: $processName
            )
          }
        `,
        variables: { processUid, entityUid, countryCode, processName }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.processCancel : false
        )
      );
  }

  action(
    actionName: string,
    processUid: string,
    stepId: string,
    entityUid: string,
    countryCode: CountryCode,
    processName: ProcessName,
    traceId?: string
  ): Observable<boolean> {
    return this.graphService
      .mutate<boolean>({
        mutation: gql`
          mutation processAction(
            $actionName: String!
            $processUid: String!
            $stepId: String!
            $entityUid: String!
            $countryCode: String!
            $processName: String!
            $traceId: String
          ) {
            processAction(
              actionName: $actionName
              processUid: $processUid
              stepId: $stepId
              entityUid: $entityUid
              countryCode: $countryCode
              processName: $processName
              traceId: $traceId
            )
          }
        `,
        variables: {
          actionName,
          processUid,
          stepId,
          entityUid,
          countryCode,
          processName,
          traceId
        }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.result : false
        )
      );
  }

  replay(
    processUid: string,
    entityUid: string,
    countryCode: CountryCode
  ): Observable<boolean> {
    return this.graphService
      .mutate({
        mutation: gql`
          mutation processReplayStep(
            $entityUid: String!
            $countryCode: String!
            $processUid: String!
          ) {
            processReplayStep(
              entityUid: $entityUid
              countryCode: $countryCode
              processUid: $processUid
            )
          }
        `,
        variables: { processUid, entityUid, countryCode }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.result : false
        )
      );
  }

  message(
    processUid: string,
    countryCode?: CountryCode,
    entityUid?: string
  ): Observable<ProcessMessage> {
    return this.graphService
      .subscribe({
        query: gql`
          subscription process(
            $processUid: String!
            $entityUid: String
            $countryCode: String
          ) {
            process(
              processUid: $processUid
              entityUid: $entityUid
              countryCode: $countryCode
            ) {
              traceId
              ack {
                queueId
                ackId
              }
              process {
                processUid
                processName
              }
              action {
                actionType
                allowedActions {
                  actionName
                }
                userTaskName
                userTaskUid
                isFinalTask
                isTaskToBeSubmitted
                isRevertable
                responseObjectName
                scraperUid
                components {
                  componentName
                  componentType
                  attributes {
                    attributeName
                    attributeType
                    attributeCategory
                    mandatory
                    value
                    validations {
                      validationName
                      params {
                        key
                        value
                      }
                    }
                    options {
                      optionValue
                    }
                    errors {
                      formFieldName
                      errorId
                      message
                    }
                    groupName
                  }
                  messages {
                    messageCode
                    messageText
                  }
                }
                messages {
                  messageCode
                  messageText
                }
                errors {
                  formFieldName
                  errorId
                  message
                }
              }
              steps {
                stepID
                status
                description
                currentSubstep
                numberOfSubsteps
              }
            }
          }
        `,
        variables: { processUid, entityUid, countryCode }
      })
      .pipe(
        map((message: any) =>
          message && message.data ? message.data.process : null
        ),
        tap((message: any) => {
          if (message && message.ack) {
            this.processAck(message.ack.queueId, message.ack.ackId);
          }
        })
      );
  }

  private processAck(queueId: string, ackId: string) {
    return this.graphService
      .mutate({
        mutation: gql`
          mutation processAck($queueId: String!, $ackId: String!) {
            processAck(queueId: $queueId, ackId: $ackId)
          }
        `,
        variables: { ackId, queueId }
      })
      .pipe(take(1))
      .subscribe();
  }

  resume(
    entityUid: string,
    countryCode: CountryCode,
    processUid: string,
    processName: ProcessName,
    traceId?: string
  ): Observable<{ processResume: string }> {
    return this.graphService
      .mutate({
        mutation: gql`
          mutation resumeProcess(
            $entityUid: String!
            $countryCode: String!
            $processUid: String!
            $processName: String!
            $traceId: String
          ) {
            processResume(
              entityUid: $entityUid
              countryCode: $countryCode
              processUid: $processUid
              processName: $processName
              traceId: $traceId
            )
          }
        `,
        variables: { processUid, entityUid, countryCode, processName, traceId }
      })
      .pipe(
        map((response: any) => {
          if (!response.data) {
            throw new Error(
              'No response was received from resumeProcess mutation'
            );
          }
          return response.data;
        })
      );
  }

  sendGoogleCID(
    processUid: string,
    entityUid: string,
    countryCode: CountryCode,
    cid: string,
    processName: ProcessName
  ): Observable<boolean> {
    return this.graphService
      .mutate<boolean>({
        mutation: gql`
          mutation sendGoogleCID(
            $processUid: String!
            $entityUid: String!
            $countryCode: String!
            $cid: String!
            $processName: String!
          ) {
            result: sendGoogleCID(
              processUid: $processUid
              entityUid: $entityUid
              countryCode: $countryCode
              cid: $cid
              processName: $processName
            )
          }
        `,
        variables: { processUid, entityUid, countryCode, cid, processName }
      })
      .pipe(
        map((response: any) =>
          response && response.data ? response.data.result : false
        )
      );
  }

  nextMessage() {}
}
