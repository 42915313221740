import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler
} from '@angular/common/http';
import { AuthProvider } from '../model/auth.provider';
import { AUTH_MODEL } from '../model/auth.provider.token';
import { Observable } from 'rxjs';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(@Inject(AUTH_MODEL) public authModel: AuthProvider) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authToken = '';

    // Get the auth token from the service.
    this.authModel
      .accessToken()
      .subscribe((token: string) => (authToken = token));
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = request.clone({
      headers: authToken
        ? request.headers.set('Authorization', 'Bearer ' + authToken)
        : request.headers
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
