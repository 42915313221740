export const TppCompaniesListQuery = `
  query tppCompaniesList
  (
    $relationUid: String!,
    $paginationTppCompaniesRequest: PaginationCompanyCriteriaForTpp
  ) {
   tppCompaniesList(
      relationUid: $relationUid,
      paginationTppCompaniesRequest: $paginationTppCompaniesRequest
    ) {
      content {
        certificateStatus,
        qsealCertificateStatus,
        applications {
          applicationName,
          clientId,
          created,
          creatorRelationUid,
          id,
          redirectUrl,
          scopes,
          signatureKey,
          status
        },
        companyName,
        country,
        created,
        environment,
        id,
        lastUpdated,
        nationalOrganisationIdentifier,
        ownerRelationUid,
        psd2OrganisationIdentifier,
        scopes,
        status
      }
      first,
      last,
      number,
      numberOfElements,
      size,
      sort {
        direction,
        property,
        ignoreCase,
        nullHandling,
        ascending,
        descending
      },
      totalElements,
      totalPages
    }
  }
`;

export const TppCreateCompanyMutation = `
  mutation createCompany($companyRequest: TppCreateCompanyRequest) {
    createCompany(companyRequest: $companyRequest) {
      applications {
        applicationName,
        clientId,
        created,
        creatorRelationUid,
        id,redirectUrl,
        scopes,
        signatureKey,
        status
      },
      companyName,
      country,
      created,
      environment,
      id,
      lastUpdated,
      nationalOrganisationIdentifier,
      scopes,
      status
    }
  }
`;

export const registerTppUserMutation = `
  mutation registerTppUser($registerTppUserRequest: RegisterTppUserRequest) {
    registerTppUser(registerTppUserRequest:$registerTppUserRequest)
  }
`;

export const createTppAppMutation = `
  mutation createTppApp($companyId: Int!, $request: CreateTppAppRequest!) {
    createTppApp(companyId: $companyId, request: $request) {
      actions,
      clientId,
      clientSecret,
      company,
      creator,
      id,
      name,
      redirectUrl,
      scopes,
      signatureKey,
      status,
      timestamp
    }
  }
`;

export const deleteTppUser = `
  mutation deleteTppUser($relationUid: String!) {
    deleteTppUser(relationUid: $relationUid)
  }
`;

export const updateTppCompanyResourceMutation = `
  mutation updateTppCompanyResource($companyId: Int!, $companyRequest: UpdateTppCompanyRequest!) {
    updateTppCompanyResource(companyId: $companyId, companyRequest: $companyRequest) {
      applications {
        applicationName,
        clientId,
        created,
        creatorRelationUid,
        id,
        redirectUrl,
        scopes,
        signatureKey,
        status
      },
      companyName,
      country,
      created,
      environment,
      id,
      lastUpdated,
      nationalOrganisationIdentifier,
      ownerRelationUid,
      psd2OrganisationIdentifier,
      scopes,
      status
    }
  }
`;

export const tppCompanyDetailsQuery = `
  query tppCompanyDetails($id: Int!) {
    tppCompanyDetails(id: $id) {
      certificateStatus,
      qsealCertificateStatus,
      applications {
          applicationName,
          clientId,
          created,
          creatorRelationUid,
          id,
          redirectUrl,
          scopes,
          signatureKey,
          status
      },
      companyName,
      country
      created
      environment
      id
      lastUpdated
      nationalOrganisationIdentifier
      ownerRelationUid
      psd2OrganisationIdentifier
      scopes
      status
    }
  }
`;

export const tppAppDetailsQuery = `
  query tppAppDetails($appId: Int!) {
    tppAppDetails(appId: $appId) {
      applicationName,
      clientId,
      created,
      creatorRelationUid,
      id,
      redirectUrl,
      scopes,
      signatureKey,
      status
    }
  }
`;

export const updateTppAppMutation = `
  mutation updateTppApp($appId: Int!, $request: UpdateTppAppRequest!) {
    updateTppApp(appId: $appId, request: $request)
  }
`;

export const deleteTppAppMutation = `
  mutation deleteTppApp($appId: Int!) {
    deleteTppApp(appId: $appId)
  }
`;

export const reissueTppAppCredentialsQuery = `
  mutation reissueTppAppCredentials($appId: Int!) {
    reissueTppAppCredentials(appId: $appId) {
      clientId,
      clientSecret
    }
  }
`;

export const reissueTppAppSignatureKeyMutation = `
  mutation reissueTppAppSignatureKey($appId: Int!) {
    reissueTppAppSignatureKey(appId: $appId) {
      signatureKey
    }
  }
`;

export const updateTppAppStatusMutation = `
  mutation updateTppAppStatus($appId: Int!, $status: TppApplicationStatus!) {
    updateTppAppStatus(appId: $appId, status:$status)
  }
`;

export const searchTppCompaniesQuery = `
  query searchTppCompanies
  (
    $searchTppCompaniesRequest: SearchCompanyCriteria,
  ) {
    searchTppCompanies(
      searchTppCompaniesRequest: $searchTppCompaniesRequest
    ) {
      content {
        applications {
          applicationName,
          clientId,
          created,
          creatorRelationUid,
          id,
          redirectUrl,
          scopes,
          signatureKey,
          status
        },
        companyName,
        country,
        created,
        environment,
        id,
        lastUpdated,
        nationalOrganisationIdentifier,
        ownerRelationUid,
        psd2OrganisationIdentifier,
        scopes,
        status
      }
      first,
      last,
      number,
      numberOfElements,
      size,
      sort {
        direction,
        property,
        ignoreCase,
        nullHandling,
        ascending,
        descending
      },
      totalElements,
      totalPages
    }
  }
`;

export const updateTppCompanyStatusMutation = `
  mutation updateTppCompanyStatus($companyId: Int!, $companyStatus: TppCompanyStatus!) {
    updateTppCompanyStatus(companyId: $companyId, companyStatus:$companyStatus) {
      applications {
            applicationName,
            clientId,
            created,
            creatorRelationUid,
            id,
            redirectUrl,
            scopes,
            signatureKey,
            status
          },
          companyName,
          country,
          created,
          environment,
          id,
          lastUpdated,
          nationalOrganisationIdentifier,
          ownerRelationUid,
          psd2OrganisationIdentifier,
          scopes,
          status
    }
  }
`;

export const downloadTppCompanyKeysQuery = `
  query  downloadTppCompanyKeys($companyId: Int!) {
    downloadTppCompanyKeys(companyId: $companyId)
  }
`;

export const downloadTppCompanyQSealKeysQuery = `
  query  downloadTppCompanyQSealKeys($companyId: Int!) {
    downloadTppCompanyQSealKeys(companyId: $companyId)
  }
`;

export const tppUserDetailsQuery = `
  query tppUserDetails($relationUid: String!) {
    tppUserDetails(relationUid: $relationUid) {
      email,
      firstName,
      lastName,
      phone
    }
  }
`;

export const updateUserDetailsMutation = `
  mutation updateUserDetails(
    $relationUid: String!
    $request: UpdateTppUserDetailsRequest!
  ) {
      updateUserDetails(
        relationUid: $relationUid
        request: $request
      ) {
        email,
        firstName,
        lastName,
        phone
      }
  }
`;

export const updateTPPUserPasswordMutation = `
  mutation updateTPPUserPassword($relationUid: String!, $request: UpdateTppUserPasswordRequest!) {
    updateTPPUserPassword(relationUid: $relationUid, request: $request)
  }
`;

export const uploadTppCompanyCertificateKeysMutation = `
  mutation uploadTppCompanyCertificateKeys($companyId: Int!, $file: Upload!) {
    uploadTppCompanyCertificateKeys(companyId: $companyId, file: $file)
  }
`;

export const uploadTppCompanyQSealCertificateKeysMutation = `
  mutation uploadTppCompanyQSealCertificateKeys($companyId: Int!, $file: Upload!) {
    uploadTppCompanyQSealCertificateKeys(companyId: $companyId, file: $file)
  }
`;

export const psd2ReportQuery = `
  query psd2Report($dateFrom: String!, $dateTo: String!) {
    psd2Report(dateFrom: $dateFrom, dateTo: $dateTo) {
      reportPerDay {
        aisErrorsPercent,
        aisRequests,
        downtimePercent,
        downtimeSeconds,
        piisErrorsPercent,
        piisRequests,
        pisErrorsPercent,
        pisRequests,
        reportDate,
        uptimePercent
      }
    }
  }
`;
