import { Environment, EnvironmentType } from './environments';

export const environment: Environment = {
  production: true,
  MOCK_BE: false,
  type: EnvironmentType.DEFAULT,
  develMode: {
    state: false
  },
  appConfig: {
    apiUrl: ''
  }
};
