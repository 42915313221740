import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { HeaderDropDownComponent } from './header-drop-down.component';

@NgModule({
  declarations: [HeaderDropDownComponent],
  imports: [CommonModule, RouterModule, MatIconModule],
  exports: [HeaderDropDownComponent]
})
export class HeaderDropDownModule {}
