import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'Entity',
          possibleTypes: [
            { name: 'UnexposedEntity' },
            { name: 'NodePage' },
            { name: 'NodeBlog' },
            { name: 'NodeTrustpilotReview' },
            { name: 'TaxonomyBlogCategory' },
            { name: 'TaxonomyFaqCategory' },
            { name: 'BoxAddressMap' },
            { name: 'BoxAddressMapLocation' },
            { name: 'BoxBlog' },
            { name: 'BoxBlogList' },
            { name: 'BoxBreadcrumb' },
            { name: 'BoxCalculator' },
            { name: 'BoxFaq' },
            { name: 'BoxFooter' },
            { name: 'BoxHeader' },
            { name: 'BoxMenu' },
            { name: 'BoxMscreen' },
            { name: 'BoxPhoto' },
            { name: 'BoxRibbon' },
            { name: 'BoxSimple' },
            { name: 'BoxSteps' },
            { name: 'BoxTable' },
            { name: 'BoxTableRow' },
            { name: 'BoxTrustpilot' },
            { name: 'BoxTrustpilotCompact' },
            { name: 'BoxWidget' }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'Box',
          possibleTypes: [
            { name: 'BoxAddressMap' },
            { name: 'BoxAddressMapLocation' },
            { name: 'BoxBlog' },
            { name: 'BoxBlogList' },
            { name: 'BoxBreadcrumb' },
            { name: 'BoxCalculator' },
            { name: 'BoxFaq' },
            { name: 'BoxFooter' },
            { name: 'BoxHeader' },
            { name: 'BoxMenu' },
            { name: 'BoxMscreen' },
            { name: 'BoxPhoto' },
            { name: 'BoxRibbon' },
            { name: 'BoxSimple' },
            { name: 'BoxSteps' },
            { name: 'BoxTable' },
            { name: 'BoxTableRow' },
            { name: 'BoxTrustpilot' },
            { name: 'BoxTrustpilotCompact' },
            { name: 'BoxWidget' }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'Paragraph',
          possibleTypes: [
            { name: 'ParagraphBit' },
            { name: 'ParagraphBoxBlog' },
            { name: 'ParagraphBoxFooter' },
            { name: 'ParagraphBoxHeader' },
            { name: 'ParagraphBoxHero' },
            { name: 'ParagraphBoxPage' },
            { name: 'ParagraphGrid' },
            { name: 'ParagraphStep' },
            { name: 'ParagraphStepsSection' },
            { name: 'ParagraphText' },
            { name: 'ParagraphTile' }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'TaxonomyTerm',
          possibleTypes: [
            { name: 'TaxonomyBlogCategory' },
            { name: 'TaxonomyFaqCategory' }
          ]
        }
      ]
    }
  }
});
