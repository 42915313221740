import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  FetchResult,
  Observable as ApolloObservable,
  Operation
} from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { combineLatest, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { AppConfig } from '../../../../../config/app.config';
import { EnvConfigService } from '../../config-handler/config-handler.service';
import { AUTH_MODEL, AuthModel } from '../../../../shared/auth';

@Injectable()
export class SubscriptionLink implements OnDestroy {
  private subscriptionClient: SubscriptionClient;
  private webSocketLink: WebSocketLink;

  private subscriptionUrl: string;
  private destroy: Subject<boolean> = new Subject<boolean>();
  private accessToken: string;

  constructor(@Inject(AUTH_MODEL) authModel: AuthModel) {
    combineLatest([of(EnvConfigService.config), authModel.accessToken()])
      .pipe(
        takeUntil(this.destroy),
        filter((values: [AppConfig, string]) => !!values[0])
      )
      .subscribe((values: [AppConfig, string]) => {
        this.subscriptionUrl = values[0].subscriptionUrl;
        this.accessToken = values[1];
        if (!this.subscriptionClient) {
          this.subscriptionClient = new SubscriptionClient(
            this.subscriptionUrl,
            {
              reconnect: true,
              lazy: true,
              connectionParams: () => this.connectionParams()
            }
          );
          this.webSocketLink = new WebSocketLink(this.subscriptionClient);
          this.subscriptionClient['maxConnectTimeGenerator'].duration = () =>
            this.subscriptionClient['maxConnectTimeGenerator'].max;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  request(operation: Operation): ApolloObservable<FetchResult> | null {
    return this.webSocketLink.request(operation);
  }

  private connectionParams() {
    return {
      authorization: this.accessToken ? `Bearer ${this.accessToken}` : null
    };
  }
}
