import {
  ErrorHandler,
  Inject,
  InjectionToken,
  PLATFORM_ID
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { ProcessEngineServiceAccess } from '../service/process-engine-service-access';
import { ProcessEngineModelService } from './process-engine-model.service';
import { Router } from '@angular/router';
import { WINDOW } from '../../window/window.token';
import { BrowserWindowApi } from '../../window/browser-window-api';
import { PROCESS_ENGINE_SERVICE } from '../service/process-engine.provider';
import { AppConfig } from '../../../../../config/app.config';
import { COOKIE_SERVICE } from '../../cookie/cookie.token';
import { CookieStore } from '../../cookie/cookie-store';
import { ToastService } from '../../toast-service/toast.service';
import { TOAST_SERVICE } from '../../toast-service/toast.token';

export const PROCESS_MODEL = new InjectionToken<ProcessEngineModelService>(
  'ProcessModel'
);

export function processModelFactory(
  document: any,
  processEngineService: ProcessEngineServiceAccess,
  platformId: object,
  location: Location,
  router: Router,
  globalError: ErrorHandler,
  window: BrowserWindowApi,
  cookieService: CookieStore,
  toastService: ToastService
) {
  return new ProcessEngineModelService(
    document,
    processEngineService,
    platformId,
    window,
    location,
    router,
    globalError,
    cookieService,
    toastService
  );
}

export const PROCESS_MODEL_PROVIDER = {
  provide: PROCESS_MODEL,
  useFactory: processModelFactory,
  deps: [
    [new Inject(DOCUMENT)],
    [new Inject(PROCESS_ENGINE_SERVICE)],
    [new Inject(PLATFORM_ID)],
    Location,
    Router,
    ErrorHandler,
    [new Inject(WINDOW)],
    [new Inject(COOKIE_SERVICE)],
    [new Inject(TOAST_SERVICE)]
  ]
};
