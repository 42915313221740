<fe-header-container theme="blue">
  <div class="fe-header">
    <div class="close-menu-icon-container">
      <img
        *ngIf="menuOpened"
        alt="close"
        class="fe-header__close-menu"
        [attr.src]="headerConfig.closeIcon"
        (click)="menuOpened = false"
      />
    </div>
    <img
      alt="Multitude logo"
      class="fe-header__logo"
      (click)="navigateToLanding()"
      [attr.src]="headerConfig.headerLogo"
    />

    <div
      *ngIf="linksVisibleState$ | async"
      class="fe-header__links"
    >
      <ul class="fe-header__links-list">
        <li
          *ngFor="let fe_item of headerConfig.headerLinks"
          class="fe-header__links-list-item"
        >
          <a
            *ngIf="!fe_item.isNotNavigationRoute; else defaultLink"
            class="fe-header__link-title"
            routerLinkActive="fe-header__link-title_activated"
            [routerLink]="fe_item.link"
          >
            {{ fe_item.title }}
          </a>

          <ng-template #defaultLink>
            <a
              class="fe-header__link-title"
              target="_blank"
              [attr.href]="fe_item.link"
            >
              {{ fe_item.title }}
            </a>
          </ng-template>
        </li>

        <li
          *ngIf="isUserLoggedIn; else userLoggedOut"
          class="fe-header__links-list-item"
        >
          <fe-user
            [user]="userInitials"
            [isBackoffice]="isBackofficeUser"
            (click)="menuOpened = !menuOpened"
          >
          </fe-user>
        </li>
        <ng-template #userLoggedOut>
          <li class="fe-header__links-list-item">
            <a
              class="fe-header__link-title"
              [routerLink]="['/account', 'companies']"
            >
              <mat-icon>login</mat-icon>
              Sign in
            </a>
          </li>
        </ng-template>
      </ul>
    </div>

    <div
      class="fe-header__dropdown"
      [class.unauthorised-dropdown]="!isUserLoggedIn"
      (click)="menuOpened = !menuOpened"
    >
      <div class="fe-header__dropdown-buttons">
        <button class="fe-header__dropdown-activated-btn">
          <img
            alt="Multitude logo"
            class="fe-header__dropdown-mobile-menu"
            [attr.src]="headerConfig.mobileMenu.icon"
          />
        </button>
      </div>
    </div>
  </div>

  <div
    class="fe-header-description"
    [class.fe-header-description_disabled]="!(headerContent$ | async)"
  >
    <ng-container *ngIf="headerContent$ | async as headerContent">
      <div
        *ngIf="headerContent?.title as title"
        class="fe-header-description__title"
      >
        <h3 class="fe-title">
          {{title}}
        </h3>
      </div>

      <div
        *ngIf="headerContent?.subtitle as subtitle"
        class="fe-header-description__subtitle"
      >
        <h4 class="fe-subtitle">
          {{subtitle}}
        </h4>
      </div>
    </ng-container>
  </div>

</fe-header-container>
<fe-header-drop-down
  [isCookieShow]="isCookieShow"
  [isUserLogin]="isUserLoggedIn"
  [isBackoffice]="isBackofficeUser"
  [isOpened]="menuOpened"
  [headerLinks]="headerConfig.headerLinks"
  (logout)="logoutUser()"
  (itemClicked)="menuOpened = false"
></fe-header-drop-down>
