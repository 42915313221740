export const GOOGLE_MOCK: any = {
  maps: {
    LatLng: (lat: any, lng: any) => {
      return {
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
        // @ts-ignore
        lat: () => this.latitude,
        // @ts-ignore
        lng: () => this.longitude
      };
    },
    LatLngBounds: (ne: any, sw: any) => {
      return {
        getSouthWest: () => sw,
        getNorthEast: () => ne
      };
    },
    OverlayView: () => {},
    InfoWindow: () => {},
    Marker: () => {
      return {
        setMap: () => {}
      };
    },
    MarkerImage: () => {},
    Map: () => {},
    Point: () => {},
    Size: () => {},
    event: {
      addListener: () => {}
    },
    places: {
      Autocomplete: () => {
        return {
          addListener: () => {},
          getPlace: () => {}
        };
      }
    }
  }
};
