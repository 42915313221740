import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'fe-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  company = '2022 Multitude Bank';
}
