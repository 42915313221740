import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { AppConfig } from '../../../../config/app.config';
import { AuthConfig } from '../../../shared/auth';

@Injectable({
  providedIn: 'root'
})
export class EnvConfigService {
  private static appConfig?: AppConfig;

  static async loadConfig(): Promise<void> {
    if (!environment.production) {
      this.appConfig = <AppConfig>environment.appConfig;
    } else {
      await this.fetchConfiguration();
    }
  }

  static mockConfig(appConfig: AppConfig): void {
    this.appConfig = appConfig;
  }

  private static fetchConfiguration(): Promise<AppConfig | null> {
    return new Promise<AppConfig | null>((resolve, reject) => {
      const oReq = new XMLHttpRequest();
      oReq.addEventListener('load', resp => {
        if (oReq.status === 200) {
          try {
            this.appConfig = JSON.parse(oReq.responseText);
          } catch (error) {
            reject(error);
          }
          resolve(null);
        } else {
          reject(oReq.statusText);
        }
      });
      oReq.open('GET', 'api/config');
      oReq.send();
    });
  }

  static get config(): AppConfig | undefined {
    return this.appConfig;
  }

  static get authConfig(): AuthConfig {
    return {
      authUrl: EnvConfigService.config ? EnvConfigService.config.authUrl : '',
      authClientId: EnvConfigService.config
        ? EnvConfigService.config.authClientId
        : '',
      authLanguage: '',
      authMock: false,
      authClientSecret: ''
    };
  }

  static get apiUrl(): string | undefined {
    return this.appConfig && this.appConfig.apiUrl;
  }

  static get authUrl(): string | undefined {
    return this.appConfig && this.appConfig.authUrl
      ? this.appConfig.authUrl
      : undefined;
  }

  static get authClientId(): string | undefined {
    return this.appConfig && this.appConfig.authClientId
      ? this.appConfig.authClientId
      : undefined;
  }

  static get tppImUrl(): string | undefined {
    return this.appConfig && this.appConfig.tppImUrl;
  }

  static get statistics(): {
    start: string;
    delayDays: number;
  } {
    return {
      start:
        this.appConfig && this.appConfig.statisticsStart
          ? this.appConfig.statisticsStart
          : '2019-01-01',
      delayDays:
        this.appConfig && this.appConfig.statisticsDelayDays
          ? this.appConfig.statisticsDelayDays
          : 30
    };
  }
}
