<div
  class="fe-user__dropdown"
  [class.cookie-open]="isCookieShow"
  [@simpleFadeAnimation]="'in'"
  *ngIf="isOpened"
  (click)="itemClicked.emit()"
>
  <a
    class="item header-link"
    *ngFor="let link of headerLinks"
    [routerLink]="link.link"
  >
    {{ link.title }}
  </a>

  <a
    class="item profile"
    [routerLink]="'/account/profile'"
    *ngIf="!isBackoffice && isUserLogin"
  >
    Profile
  </a>

  <a
    *ngIf="isUserLogin"
    class="item"
    (click)="logout.emit()"
  >Logout</a>

  <a
    *ngIf="!isUserLogin"
    class="item"
    [routerLink]="['/account', 'companies']"
  >
    <mat-icon>login</mat-icon>
    Sign in
  </a>
</div>
