import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthProvider } from '../model/auth.provider';
import { AUTH_MODEL } from '../model/auth.provider.token';
import { AUTH_SERVICE } from '../service/auth-service.provider.token';
import { AuthServiceProvider } from '../service/auth-service.provider';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    @Inject(AUTH_MODEL) private authModel: AuthProvider,
    @Inject(AUTH_SERVICE) private authService: AuthServiceProvider,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const url: string = state.url;
    return this.checkLogin(this.localeId, url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `/${route.path}`;
    return this.checkLogin(this.localeId, url);
  }

  checkLogin(locale: string, url: string): Observable<boolean> {
    return this.authModel.isAuthenticated().pipe(
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.authService.redirectToAuthServer(locale, url);
        }
        return isAuthenticated;
      })
    );
  }
}
