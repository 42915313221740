import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/shared/auth';
import { CallbackComponent } from './modules/login/view-model/callback/callback.component';
import { environment } from '../environments/environment';
import { BackOfficeGuard } from './modules/core/guards/back-office-user.guard';
import { OnboardingGuard } from './modules/core/guards/onboarding-guard/onboarding.guard';

const routes: Routes = [
  {
    path: 'auth',
    component: CallbackComponent
  },
  {
    path: 'application',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
    data: { section: 'ONBOARDING' },
    canLoad: [OnboardingGuard]
  },
  {
    path: 'account',
    children: [
      {
        path: 'callback',
        component: CallbackComponent,
        canActivate: []
      },
      {
        path: 'companies',
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
        canActivate: environment.MOCK_BE ? [] : [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        canActivate: environment.MOCK_BE ? [] : [AuthGuard],
        canLoad: [BackOfficeGuard]
      }
    ]
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
  },
  {
    path: 'contacts',
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'documentation',
    loadChildren: () => import('./modules/documentation/documentation.module').then(m => m.DocumentationModule),
  },
  {
    path: 'statistics',
    loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
