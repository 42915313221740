import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import {
  HttpBatchLink,
  HttpBatchLinkHandler
} from 'apollo-angular-link-http-batch';
import { ExtraSubscriptionOptions, R } from 'apollo-angular/types';
import { InMemoryCache } from 'apollo-cache-inmemory/lib/inMemoryCache';
import {
  ApolloQueryResult,
  MutationOptions,
  QueryOptions,
  SubscriptionOptions
} from 'apollo-client';
import { ApolloLink, FetchResult, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { SiteService } from '../site/site-service';
import { SITE_SERVICE } from '../site/site-service.token';
import { fragmentMatcher } from './fragment-matcher';
import { GraphServiceAccess } from './graph-service-access';
import { AuthLink } from './links/auth-link';
import { subscriptionFilter } from './links/subscription-filter';
import { SUBSCRIPTION_LINK } from './links/subscription-link.token';

@Injectable()
export class GraphService implements GraphServiceAccess {
  constructor(
    @Inject(SUBSCRIPTION_LINK) private subscriptionLink: WebSocketLink,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authLink: AuthLink,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private httpBatchLink: HttpBatchLink,
    @Inject(SITE_SERVICE) private siteService: SiteService
  ) {
    this.siteService.apiUrl.pipe(take(1)).subscribe(apiUrl => {
      this.initDefaultClient(apiUrl);
    });
  }

  query<T, V = R>(
    options: QueryOptions<V>,
    name?: string
  ): Observable<ApolloQueryResult<T>> {
    if (name && this.apollo.use(name)) {
      return this.apollo.use(name).query<T, V>({ ...options });
    }
    return this.apollo.query<T, V>({ ...options });
  }

  mutate<T, V = R>(options: MutationOptions<T, V>): Observable<FetchResult<T>> {
    return this.apollo.mutate<T, V>({ ...options });
  }

  subscribe<T, V = R>(
    options: SubscriptionOptions<V>,
    extra?: ExtraSubscriptionOptions
  ): Observable<any> {
    return this.apollo.subscribe({ ...options }, extra);
  }

  get ssrMode(): boolean {
    return isPlatformServer(this.platformId);
  }

  private initDefaultClient(apiUrl: string): void {
    const cache = new InMemoryCache({
      addTypename: true,
      fragmentMatcher: fragmentMatcher
    });

    const httpBatchLinkHandler: HttpBatchLinkHandler = this.httpBatchLink.create(
      {
        uri: apiUrl
      }
    );

    const auth: ApolloLink = this.authLink.concat(httpBatchLinkHandler as any);

    const link = split(subscriptionFilter, this.subscriptionLink, auth) as any;

    this.apollo.create({
      link: link,
      cache: cache,
      ssrMode: this.ssrMode
    });
  }
}
