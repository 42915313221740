import { ProcessEngineDevelService } from './process-engine-devel.service';
import { PROCESS_ENGINE_SERVICE } from '../../../core/services/process-engine/service/process-engine.provider';
export function processEngineServiceFactory() {
  return new ProcessEngineDevelService();
}

export let PROCESS_ENGINE_DEVEL_SERVICE_PROVIDER = {
  provide: PROCESS_ENGINE_SERVICE,
  useFactory: processEngineServiceFactory,
  deps: []
};
