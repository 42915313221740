<div class="fe-user">
  <div class="fe-user__info" (click)="userClicked.emit()">
    <div class="name-block">
      <span class="name">
        {{ user }}
      </span>
    </div>
    <img
      alt="menu icon"
      class="icon-menu"
      src="assets/images/ic-expand-more-white.svg"
    />
  </div>
</div>
