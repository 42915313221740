export enum CountryCode {
  AUSTRALIA = <any>'AU',
  BRAZIL = <any>'BR',
  BULGARIA = <any>'BG',
  DENMARK = <any>'DK',
  ESTONIA = <any>'EE',
  FINLAND = <any>'FI',
  FRANCE = <any>'FR',
  CANADA = <any>'CA',
  CROATIA = <any>'HR',
  LATVIA = <any>'LV',
  LITHUANIA = <any>'LT',
  MEXICO = <any>'MX',
  NETHERLANDS = <any>'NL',
  NORWAY = <any>'NO',
  NEW_ZEALAND = <any>'NZ',
  POLAND = <any>'PL',
  ROMANIA = <any>'RO',
  RUSSIAN_FEDERRATION = <any>'RU',
  SPAIN = <any>'ES',
  UNITED_KINGDOM = <any>'GB',
  CZECH_REPUBLIC = <any>'CZ',
  GERMANY = <any>'DE',
  SWEDEN = <any>'SE',
  MALTA = <any>'MT',
  FERRATUMBANK = <any>'FERRATUMBANK',
  FERRATUMBANK_DEVELOPER = <any>'FERRATUMBANK_DEVELOPER'
}
