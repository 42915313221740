export enum LanguageCode {
  ENGLISH = 'EN',
  PORTUGUESE = 'PT',
  BULGARIAN = 'BG',
  DANISH = 'DA',
  ESTONIAN = 'ET',
  EUROPE = 'EU',
  FINNISH = 'FI',
  SWEDISH = 'SV',
  FRENCH = 'FR',
  CROATIAN = 'HR',
  LATVIAN = 'LV',
  LITHUANIAN = 'LT',
  SPANISH = 'ES',
  DUTCH = 'NL',
  FRISIAN = 'FY',
  NORWEGIAN_BOKMAL = 'NB',
  NORWEGIAN_NYNORSK = 'NN',
  NORWEGIAN = 'NO',
  NORTHERN_SAMI = 'SE',
  MAORI = 'MI',
  POLISH = 'PL',
  ROMANIAN = 'RO',
  RUSSIAN = 'RU',
  CZECH = 'CS',
  GERMAN = 'DE'
}

export enum LanguageCodeReverse {
  EN = 'ENGLISH',
  PT = 'PORTUGUESE',
  BG = 'BULGARIAN',
  DA = 'DANISH',
  ET = 'ESTONIAN',
  EU = 'EUROPE',
  FI = 'FINNISH',
  SV = 'SWEDISH',
  FR = 'FRENCH',
  HR = 'CROATIAN',
  LV = 'LATVIAN',
  LT = 'LITHUANIAN',
  ES = 'SPANISH',
  NL = 'DUTCH',
  FY = 'FRISIAN',
  NB = 'NORWEGIAN_BOKMAL',
  NN = 'NORWEGIAN_NYNORSK',
  NO = 'NORWEGIAN',
  SE = 'NORTHERN_SAMI',
  MI = 'MAORI',
  PL = 'POLISH',
  RO = 'ROMANIAN',
  RU = 'RUSSIAN',
  CS = 'CZECH',
  DE = 'GERMAN'
}
