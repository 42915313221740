import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookieType } from './modules/core/entity/cookie/cookie-type';

@Component({
  selector: 'fe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCookieShow = false;
  title = 'developer-portal';

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.check(CookieType.COOKIE_CONSENT)) {
      return;
    }
    this.isCookieShow = true;
  }

  hideCookieBox() {
    this.cookieService.set(CookieType.COOKIE_CONSENT, 'true');
    this.isCookieShow = false;
  }
}
