import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

export interface ToastMessage {
  headline?: string;
  text: string;
}

const TOAST_TIMEOUT = 10000;

@Injectable()
export class ToastService {
  readonly defaultToastBehavior: Partial<IndividualConfig> = {
    closeButton: true,
    positionClass: 'toast-bottom-right',
    tapToDismiss: true,
    timeOut: TOAST_TIMEOUT
  };

  constructor(private readonly toastr: ToastrService) {}

  showSuccess(
    message: ToastMessage,
    options?: Partial<IndividualConfig>
  ): void {
    this.toastr.success(message.text, message.headline, {
      ...this.defaultToastBehavior,
      ...options
    });
  }

  showInfo(message: ToastMessage, options?: Partial<IndividualConfig>): void {
    this.toastr.info(message.text, message.headline, {
      ...this.defaultToastBehavior,
      ...options
    });
  }

  showWarning(
    message: ToastMessage,
    options?: Partial<IndividualConfig>
  ): void {
    this.toastr.warning(message.text, message.headline, {
      ...this.defaultToastBehavior,
      ...options
    });
  }

  showError(message: ToastMessage, options?: Partial<IndividualConfig>): void {
    const text =
      message.headline || message.text ? message.text : 'Error occurred';
    this.toastr.error(text, message.headline, {
      ...this.defaultToastBehavior,
      ...options
    });
  }
}
