import { Operation } from 'apollo-link';
import { DefinitionNode, DocumentNode } from 'graphql';

export const subscriptionFilter = (root: Operation | any): boolean => {
  const operationName = 'subscription';
  const nodeKind = 'OperationDefinition';
  const ack = {
    operationName: 'mutation',
    name: 'processAck'
  };
  const queryDoc: DocumentNode = root.query;
  let definitionNodes: DefinitionNode[] = [];

  if (queryDoc && queryDoc.definitions) {
    definitionNodes = queryDoc.definitions.filter((node: DefinitionNode) => {
      return (
        node.kind === nodeKind &&
        (node.operation === operationName ||
          (node.operation === ack.operationName &&
            node.name &&
            node.name.value === ack.name))
      );
    });
  }

  return definitionNodes && definitionNodes.length > 0;
};
