import { Injectable } from '@angular/core';
import { CONFIG_STATE_KEY, ConfigAccess } from './config-access';
import { TransferState } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../config/app.config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigValidator } from './config.validator';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConfigService implements ConfigAccess {
  private _config = new BehaviorSubject<Object | undefined>(undefined);

  constructor(
    private transferState: TransferState,
    private httpClient: HttpClient
  ) {}

  get config(): Observable<Object | undefined> {
    return this._config.asObservable();
  }

  init(configUrl: string, validators: ConfigValidator[]): Promise<Object> {
    const config = this.transferState.get<Object | undefined>(
      CONFIG_STATE_KEY,
      undefined
    );
    if (config) {
      this._config.next(config);
      return Promise.resolve(config);
    }

    return <Promise<AppConfig>>this.httpClient
      .get<AppConfig>(configUrl)
      .pipe(
        tap((appConfig: AppConfig) => {
          this.validate(appConfig, validators);
          this._config.next(appConfig);
        })
      )
      .toPromise();
  }

  validate(appConfig: AppConfig, validators: ConfigValidator[]) {
    if (!appConfig) {
      throw new Error(`CONFIG ERROR | config data is not defined!`);
    }

    validators
      .reduce((result: Error[], validator: Function) => {
        return result.concat(validator(appConfig));
      }, [])
      .forEach((error: Error) => {
        error.message = `CONFIG ERROR | ${error.message}`;
        throw error;
      });
  }
}
