import { BehaviorSubject } from 'rxjs';
import { ProcessActionAllowedAction } from '../../../entity/process/process-action-allowed-action';
import { ProcessActionAllowedActions } from '../../../entity/process/process-action-allowed-actions';
import { ProcessMessage } from '../../../entity/process/process-message';
import { FormControl, FormGroup } from '@angular/forms';
import { validatorsFromField } from './validators';
import { ProcessActionComponent } from '../../../entity/process/process-action-component';
import {
  ProcessActionComponentAttribute,
  ProcessActionComponentAttributeEditable,
  ProcessActionComponentAttributeReadOnly
} from '../../../entity/process/process-action-component-attribute';
import { ProcessActionOption } from '../../../entity/process/process-action-option';
import { ComponentAttributeCategory } from '../../../entity/process/component-attribute-category';
import { FeFormControl } from './fe-form-control';
import { ProcessActionFieldType } from '../../../entity/process/process-action-field-type';

export class ProcessEngineFormUtils {
  private message: BehaviorSubject<ProcessMessage | undefined>;
  formControlMap: Map<string, FormControl> = new Map();

  constructor(
    message: ProcessMessage | BehaviorSubject<ProcessMessage | undefined>
  ) {
    if (message instanceof BehaviorSubject) {
      this.message = message;
    } else {
      this.message = new BehaviorSubject(message);
    }
  }

  private instanceOfProcessMessage(
    object: ProcessMessage | BehaviorSubject<ProcessMessage | undefined>
  ): object is ProcessMessage {
    return 'member' in object;
  }

  /**
   * Form & form controls
   */

  createFormControl(fieldName: string, value?: any): FeFormControl | undefined {
    const field = this.fieldByName(fieldName);

    if (field) {
      const control = new FeFormControl(
        value ? value : field.value,
        validatorsFromField(field)
      );
      control.setParams({
        required: this.fieldIsMandatory(fieldName),
        editable: this.fieldIsEditable(fieldName)
      });
      this.formControlMap.set(fieldName, control);
      return control;
    }
    return undefined;
  }

  // TODO
  // Temporary solution, refactor form-utils, move form methods to component one.
  createFormControlFromReadOnlyAttribute(
    componentName: string,
    attributeName: string,
    componentType: string | null,
    value?: any
  ): FormControl | undefined {
    const field = componentType
      ? this.attributeByNameAndType(
          componentName,
          componentType as string,
          attributeName
        )
      : this.attributeByName(componentName, attributeName);
    if (field) {
      const control = new FeFormControl(
        value ? value : field.value,
        validatorsFromField(field)
      );
      control.setParams({
        required: field.mandatory,
        editable: false
      });
      this.formControlMap.set(attributeName, control);
      return control;
    }
    return undefined;
  }

  createFormControlNumber(
    fieldName: string,
    value?: number
  ): FormControl | undefined {
    const field = this.fieldByName(fieldName);
    if (field) {
      const control = new FeFormControl(
        value ? value : this.fieldValueNumber(fieldName),
        validatorsFromField(field)
      );
      control.setParams({
        required: this.fieldIsMandatory(fieldName),
        editable: this.fieldIsEditable(fieldName)
      });
      this.formControlMap.set(fieldName, control);
      return control;
    }
    return undefined;
  }

  createFormControlBool(
    fieldName: string,
    value?: boolean
  ): FeFormControl | undefined {
    const field = this.fieldByName(fieldName);
    if (field) {
      const control = new FeFormControl(
        value ? value : this.fieldValueBool(fieldName),
        validatorsFromField(field, true)
      );
      control.setParams({
        required: this.fieldIsMandatory(fieldName),
        editable: this.fieldIsEditable(fieldName)
      });
      this.formControlMap.set(fieldName, control);
      return control;
    }
    return undefined;
  }

  formValueToFields(formGroup: FormGroup) {
    const value: any = {};
    this.formControlMap.forEach((control: FeFormControl, fieldName: string) => {
      value[fieldName] = control.value;
    });
    return value;
  }

  trimStringValues(data: Object): Object {
    const trimmed: any = { ...data };
    Object.keys(trimmed).forEach((field: any) => {
      if (typeof trimmed[field] === 'string') {
        trimmed[field] = trimmed[field].trim();
      }
    });
    return trimmed;
  }

  nullEmptyValues(data: Object): Object {
    const nulled: any = { ...data };
    Object.keys(nulled).forEach((field: any) => {
      if (nulled[field] === '') {
        nulled[field] = null;
      }
    });
    return nulled;
  }

  requiredControls(formGroup: FormGroup): { [key: string]: boolean } {
    const required: any = {};
    this.formControlMap.forEach((control: FeFormControl, fieldName: string) => {
      required[fieldName] = control.getParams().required;
    });
    return required;
  }

  editableControls(): { [key: string]: boolean } {
    const editable: any = {};
    this.formControlMap.forEach((control: FeFormControl, fieldName: string) => {
      editable[fieldName] = control.getParams().editable;
    });
    return editable;
  }

  /**
   * Returns configuration for dynamic actions
   * @param fallbackValue - used in case message does not contain allowedActions
   */
  isActionAllowed(
    action: ProcessActionAllowedAction | string,
    fallbackValue?: boolean
  ): boolean {
    if (!this.message || !this.message.value || !this.message.value.action) {
      return false;
    }

    if (
      this.message.value.action.allowedActions !== undefined &&
      this.message.value.action.allowedActions.length > 0
    ) {
      return !!this.message.value.action.allowedActions.find(
        (item: ProcessActionAllowedActions) =>
          item.actionName === <ProcessActionAllowedAction>action
      );
    }

    // Fallback to deprecated properties to ensure backwards compatibility with PE
    switch (action) {
      case ProcessActionAllowedAction.GO_BACK:
        return this.message.value.action.isRevertable || false;
      default:
        return fallbackValue || false;
    }
  }

  /**
   * Fields in message
   */

  fieldByName(name: string): ProcessActionComponentAttribute | undefined {
    if (this.message && this.message.value) {
      return this.message.value.action.components
        .reduce((acc, component) => [...acc, ...component.attributes], [])
        .filter((componentAttribute: ProcessActionComponentAttribute) => {
          return (
            componentAttribute.attributeCategory ===
            ComponentAttributeCategory.EDITABLE
          );
        })
        .find((componentAttribute: ProcessActionComponentAttributeEditable) => {
          return componentAttribute.attributeName === name;
        });
    }
    return undefined;
  }

  fieldValue(name: string): any {
    const field = this.fieldByName(name);
    return field ? field.value : undefined;
  }

  fieldIsMandatory(name: string): boolean {
    const field = this.fieldByName(name);
    return !!(field && field.mandatory);
  }

  fieldIsEditable(name: string): boolean {
    const field = this.fieldByName(name);
    return !(field && field.attributeType === 'text');
  }

  fieldOptions(name: string): any {
    const field = this.fieldByName(name);
    if (field && field.options) {
      return field.options.map(
        (option: ProcessActionOption) => option.optionValue
      );
    }
    return [];
  }

  fieldValueString(name: string): string {
    const field = this.fieldByName(name);
    return field ? <string>field.value : '';
  }

  fieldValueNumber(name: string): number {
    let value = this.fieldValue(name);
    value = parseInt(value, 10);
    return isNaN(value) ? 0 : value;
  }

  fieldValueBool(name: string): boolean {
    const value = this.fieldValue(name);
    return this.valueToBool(value);
  }

  /**
   * Components & Read only attributes
   */

  componentByName(name: string): ProcessActionComponent | undefined {
    if (
      this.message &&
      this.message.value &&
      this.message.value.action.components
    ) {
      const component = this.message.value.action.components.find(
        (item: ProcessActionComponent) => item.componentName === name
      );
      if (component) {
        return component;
      }
    }
    return undefined;
  }

  componentsByType(type: string): ProcessActionComponent[] {
    if (
      !this.message ||
      !this.message.value ||
      !this.message.value.action.components
    ) {
      return [];
    }
    return this.message.value.action.components.filter(
      (item: ProcessActionComponent) => item.componentType === type
    );
  }

  attributesByType(type: string): any[] {
    if (
      !this.message ||
      !this.message.value ||
      !this.message.value.action.components
    ) {
      return [];
    }
    return this.message.value.action.components
      .filter(
        (component: ProcessActionComponent) => component.componentType === type
      )
      .map((component: ProcessActionComponent) => {
        return component.attributes.reduce(
          (attributes: any, attribute: ProcessActionComponentAttribute) => {
            return {
              ...attributes,
              [attribute.attributeName]: attribute.value
            };
          },
          {}
        );
      });
  }

  attributeByNameAndType(
    componentName: string,
    componentType: string,
    attributeName: string
  ): any {
    const component = this.componentsByType(componentType).find(
      (matchedComponent: ProcessActionComponent) =>
        matchedComponent.componentName === componentName
    );

    if (component) {
      const attribute = component.attributes.find(
        (item: ProcessActionComponentAttribute) =>
          item.attributeName === attributeName
      );
      return attribute ? attribute : undefined;
    }
    return undefined;
  }

  attributeByName(
    componentName: string,
    attributeName: string
  ): ProcessActionComponentAttribute | undefined {
    const component = this.componentByName(componentName);
    if (component) {
      return component.attributes.find(
        (item: ProcessActionComponentAttribute) =>
          item.attributeName === attributeName
      );
    }
    return undefined;
  }

  attributeValue(componentName: string, attributeName: string): any {
    const component = this.componentByName(componentName);
    if (component) {
      const attribute = component.attributes.find(
        (item: ProcessActionComponentAttribute) =>
          item.attributeName === attributeName
      );
      return attribute ? attribute.value : undefined;
    }
  }

  attributeValueString(componentName: string, attributeName: string): string {
    const value = this.attributeValue(componentName, attributeName);
    return value ? value : '';
  }

  attributeValueBool(componentName: string, attributeName: string): boolean {
    const value = this.attributeValue(componentName, attributeName);
    return this.valueToBool(value);
  }

  attributeValueNumber(componentName: string, attributeName: string): number {
    let value = this.attributeValue(componentName, attributeName);
    value = parseInt(value, 10);
    return isNaN(value) ? 0 : value;
  }

  attributeValueFloat(componentName: string, attributeName: string): number {
    let value = this.attributeValue(componentName, attributeName);
    value = parseFloat(value);
    return isNaN(value) ? 0 : value;
  }

  attributeValueDate(componentName: string, attributeName: string): Date {
    const value = this.attributeValue(componentName, attributeName);
    return value ? new Date(value) : new Date();
  }

  attributeOptions(componentName: string, attributeName: string): string[] {
    const component = this.componentByName(componentName);
    if (!component) {
      return [];
    }
    const attribute = component.attributes
      .filter((componentAttribute: ProcessActionComponentAttribute) => {
        return (
          componentAttribute.attributeCategory ===
          ComponentAttributeCategory.READ_ONLY
        );
      })
      .find(
        (item: ProcessActionComponentAttributeReadOnly) =>
          item.attributeName === attributeName
      );
    if (!attribute || !attribute.options) {
      return [];
    }

    return attribute.options.map(
      (option: ProcessActionOption) => option.optionValue
    );
  }

  attributeOptionsPopup(
    componentName: string,
    attributeName: string
  ): string[] {
    const component = this.componentByName(componentName);
    if (!component) {
      return [];
    }
    const attribute = component.attributes.find(
      (item: ProcessActionComponentAttributeReadOnly) =>
        item.attributeName === attributeName
    );
    if (!attribute || !attribute.options) {
      return [];
    }

    return attribute.options.map(
      (option: ProcessActionOption) => option.optionValue
    );
  }

  hasAttribute(componentName: string, attributeName: string): boolean {
    const component = this.componentByName(componentName);
    if (component) {
      const attribute = component.attributes.find(
        (item: ProcessActionComponentAttribute) =>
          item.attributeName === attributeName
      );
      return !!attribute;
    } else {
      return false;
    }
  }

  getComponentControls(
    components: ProcessActionComponent[],
    componentName: string,
    componentType: string
  ): Object {
    const component = components.find(
      (item: ProcessActionComponent) =>
        item.componentName === componentName &&
        item.componentType === componentType
    );
    if (!component) {
      return {};
    }

    return component.attributes
      .map(attribute => {
        switch (attribute.attributeType) {
          case ProcessActionFieldType.INPUT_TEXT:
          case ProcessActionFieldType.INPUT_EMAIL:
          case ProcessActionFieldType.INPUT_PHONE_NUMBER:
          case ProcessActionFieldType.INPUT_DATEPICKER:
          case ProcessActionFieldType.INPUT_SELECT:
          case ProcessActionFieldType.INPUT_NUMBER:
          case ProcessActionFieldType.POP_UP:
          case ProcessActionFieldType.RADIO:
          case ProcessActionFieldType.PASSWORD:
          case ProcessActionFieldType.SLIDER_VALUE:
          case ProcessActionFieldType.SLIDER:
          case ProcessActionFieldType.METADATA:
          case ProcessActionFieldType.FILE:
            return {
              [attribute.attributeName]: this.createFormControl(
                attribute.attributeName
              )
            };
          case ProcessActionFieldType.CHECKBOX:
          case ProcessActionFieldType.BUTTON:
            return {
              [attribute.attributeName]: this.createFormControlBool(
                attribute.attributeName
              )
            };
          case ProcessActionFieldType.TEXT:
          case ProcessActionFieldType.INPUT_RETYPE_EMAIL:
            return {
              [attribute.attributeName]: this.createFormControlFromReadOnlyAttribute(
                component.componentName,
                attribute.attributeName,
                component.componentType,
                attribute.value
              )
            };
          default:
            return {};
        }
      })
      .reduce((previousValue, currentValue) => {
        return Object.assign(previousValue, currentValue);
      });
  }

  valueToBool(value: any) {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      case true:
        return true;
      case false:
        return false;
      case 1:
        return true;
      case 0:
        return false;
      default:
        return false;
    }
  }

  prepareFormValues(components: ProcessActionComponent[], formValue: any) {
    const elements: any = {};

    components.map(item => {
      return item.attributes.map(items => {
        elements[items.attributeName] = formValue[items.attributeName];
      });
    });

    return elements;
  }

  /**
   * Changing structure of the Request fields. All attributes that have groupName
   *  defined are going to have own array named by groupName. See UN-599.
   * @param data - request data
   * @returns - data with groupName arrays
   */
  preprocessRequestData(data: any): any {
    interface RequestData {
      [key: string]: any;
    }

    return Object.keys(data).reduce(
      (obj: RequestData, key: string) => {
        const processActionComponentAttribute = this.fieldByName(key);
        if (processActionComponentAttribute) {
          const groupName = (<ProcessActionComponentAttributeEditable>(
            processActionComponentAttribute
          )).groupName;
          if (groupName) {
            const processedAttr = {
              attributeName: key,
              value: data[key]
            };
            if (!obj[groupName]) {
              obj[groupName] = [processedAttr];
            } else {
              obj[groupName].push(processedAttr);
            }
          } else {
            obj[key] = data[key];
          }
        }
        return obj;
      },
      {} as RequestData
    );
  }
}
