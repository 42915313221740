import { HttpClientModule } from '@angular/common/http';
import { Inject, LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { ApmService } from '@elastic/apm-rum-angular';

import { CookieModule } from 'ngx-cookie';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfigValidator } from './config/app.config.validator';

import { AUTH_CONFIG, AuthModule } from './modules/shared/auth';
import { COOKIE_SERVICE } from './modules/core/services/cookie/cookie.token';
import { CookieAdapterService } from './modules/core/services/cookie/cookie-adapter.service';
import { CONFIG_VALIDATORS } from './modules/config/config.module';
import { authConfigFactory } from './modules/config/service/auth-config.factory';
import { CoreModule } from './modules/core/core.module';
import { EnvConfigService } from './modules/core/services/config-handler/config-handler.service';
import { authConfigValidator } from './modules/login/config/auth.config.validator';
import { CallbackComponent } from './modules/login/view-model/callback/callback.component';
import { FooterModule } from './modules/shared/components/footer/footer.module';
import { HeaderModule } from './modules/shared/components/header/header.module';
import { ProcessEngineMocksModule } from './modules/shared/process-engine-mocks/process-engine-mocks.module';

@NgModule({
  declarations: [AppComponent, CallbackComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    CoreModule.forRoot(),
    environment.develMode.state ? ProcessEngineMocksModule.forRoot() : [],
    CookieModule.forRoot(),
    HeaderModule,
    FooterModule,
    ToastrModule.forRoot({ preventDuplicates: true })
  ],
  providers: [
    {
      provide: ApmService,
      useClass: ApmService,
      deps: [Router]
    },
    {
      provide: CONFIG_VALIDATORS,
      useValue: [authConfigValidator, appConfigValidator]
    },
    {
      provide: COOKIE_SERVICE,
      useClass: CookieAdapterService
    },
    {
      provide: AUTH_CONFIG,
      useFactory: authConfigFactory
    },
    // {
    //   provide: WINDOW,
    //   useValue: window
    // },
    // {
    //   provide: AUTH_SERVICE,
    //   useClass: AuthService,
    //   deps: [WINDOW]
    // },
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
    CookieService,
    ToastrService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(ApmService) ampService: ApmService) {
    console.log(EnvConfigService.config);
    if (
      EnvConfigService.config &&
      EnvConfigService.config.openTracingServiceEnable
    ) {
      ampService.init({
        serviceName: EnvConfigService.config.openTracingServiceName,
        serverUrl: EnvConfigService.config.openTracingServiceUrl,
        logLevel: 'debug'
      });
    }
  }
}
