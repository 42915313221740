import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FetchResult } from 'apollo-link';
import { QueryOptions, ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';

import {
  TppCompaniesListQuery,
  TppCreateCompanyMutation,
  registerTppUserMutation,
  createTppAppMutation,
  deleteTppUser,
  updateTppCompanyResourceMutation,
  tppAppDetailsQuery,
  updateTppAppMutation,
  deleteTppAppMutation,
  reissueTppAppCredentialsQuery,
  reissueTppAppSignatureKeyMutation,
  updateTppAppStatusMutation,
  searchTppCompaniesQuery,
  updateTppCompanyStatusMutation,
  tppUserDetailsQuery,
  downloadTppCompanyKeysQuery,
  downloadTppCompanyQSealKeysQuery,
  updateUserDetailsMutation,
  updateTPPUserPasswordMutation,
  tppCompanyDetailsQuery,
  uploadTppCompanyCertificateKeysMutation,
  uploadTppCompanyQSealCertificateKeysMutation,
  psd2ReportQuery
} from './tpp-identity-management.queries';
import { TppIdentityManagementAccess } from './tpp-identity-management-access';
import { GraphServiceAccess } from './../graph/graph-service-access';
import { GRAPH_SERVICE } from './../graph/graph.provider';
import {
  TppCompanyResponse,
  TppCreateCompanyRequest,
  RegisterTppUserRequest,
  CreateTppAppRequest,
  CreateTppAppResponse,
  UpdateTppCompanyRequest,
  TppApplicationResponse,
  UpdateTppAppRequest,
  TppAppCredentialsResponse,
  TppAppSignatureKeyResponse,
  TppAppStatus,
  TppCompanyStatus,
  TppUserDetailsResponse,
  UpdateTppUserDetailsRequest,
  UpdateTppUserPasswordRequest,
  TppCompanyStatusUpdateResponse,
  SearchTppCompaniesResponse,
  SearchCompanyCriteria,
  PaginationCompanyCriteriaForTpp,
  Psd2ReportResponse
} from './tpp-identity-management.models';
import { UserService } from '../user/user.service';
import { UNIWEB_API_PROXY_ROUTES } from '../../../../../../server/proxy-routes';

@Injectable()
export class TppIdentityManagementService
  implements TppIdentityManagementAccess {
  constructor(
    @Inject(GRAPH_SERVICE) private graphService: GraphServiceAccess,
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  tppCompaniesList(
    relationUid: string,
    paginationTppCompaniesRequest: PaginationCompanyCriteriaForTpp
  ): Observable<ApolloQueryResult<SearchTppCompaniesResponse>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${TppCompaniesListQuery}
      `,
      variables: {
        relationUid,
        paginationTppCompaniesRequest
      },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<SearchTppCompaniesResponse>(
      queryOptions,
      'tppCompaniesList'
    );
  }

  createCompany(
    companyRequest: TppCreateCompanyRequest
  ): Observable<FetchResult<TppCompanyResponse>> {
    return this.graphService.mutate<TppCompanyResponse>(
      {
        mutation: gql`
          ${TppCreateCompanyMutation}
        `,
        variables: { companyRequest }
      },
      'createCompany'
    );
  }

  registerTppUser(
    registerTppUserRequest: RegisterTppUserRequest
  ): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${registerTppUserMutation}
        `,
        variables: { registerTppUserRequest }
      },
      'registerTppUser'
    );
  }

  createTppApp(
    companyId: number,
    request: CreateTppAppRequest
  ): Observable<FetchResult<CreateTppAppResponse>> {
    return this.graphService.mutate<CreateTppAppResponse>(
      {
        mutation: gql`
          ${createTppAppMutation}
        `,
        variables: { companyId, request }
      },
      'createTppApp'
    );
  }

  deleteTppUser(relationUid: string): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${deleteTppUser}
        `,
        variables: { relationUid }
      },
      'deleteTppUser'
    );
  }

  updateTppCompanyResource(
    companyId: number,
    companyRequest: UpdateTppCompanyRequest
  ): Observable<FetchResult<TppCompanyResponse>> {
    return this.graphService.mutate<TppCompanyResponse>(
      {
        mutation: gql`
          ${updateTppCompanyResourceMutation}
        `,
        variables: { companyId, companyRequest }
      },
      'updateTppCompanyResource'
    );
  }

  tppCompanyDetails(
    id: number
  ): Observable<ApolloQueryResult<TppCompanyResponse>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${tppCompanyDetailsQuery}
      `,
      variables: { id },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<TppCompanyResponse>(
      queryOptions,
      'tppCompanyDetails'
    );
  }

  tppAppDetails(
    appId: number
  ): Observable<ApolloQueryResult<TppApplicationResponse>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${tppAppDetailsQuery}
      `,
      variables: { appId },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<TppApplicationResponse>(
      queryOptions,
      'tppAppDetails'
    );
  }

  updateTppApp(
    appId: number,
    request: UpdateTppAppRequest
  ): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${updateTppAppMutation}
        `,
        variables: { appId, request }
      },
      'updateTppApp'
    );
  }

  deleteTppApp(appId: number): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${deleteTppAppMutation}
        `,
        variables: { appId }
      },
      'deleteTppApp'
    );
  }

  reissueTppAppCredentials(
    appId: number
  ): Observable<FetchResult<TppAppCredentialsResponse>> {
    return this.graphService.mutate<TppAppCredentialsResponse>(
      {
        mutation: gql`
          ${reissueTppAppCredentialsQuery}
        `,
        variables: { appId }
      },
      'reissueTppAppCredentials'
    );
  }

  reissueTppAppSignatureKey(
    appId: number
  ): Observable<FetchResult<TppAppSignatureKeyResponse>> {
    return this.graphService.mutate<TppAppSignatureKeyResponse>(
      {
        mutation: gql`
          ${reissueTppAppSignatureKeyMutation}
        `,
        variables: { appId }
      },
      'reissueTppAppSignatureKey'
    );
  }

  updateTppAppStatus(
    appId: number,
    status: TppAppStatus
  ): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${updateTppAppStatusMutation}
        `,
        variables: { appId, status }
      },
      'updateTppAppStatus'
    );
  }

  searchTppCompanies(
    searchTppCompaniesRequest: SearchCompanyCriteria
  ): Observable<ApolloQueryResult<SearchTppCompaniesResponse>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${searchTppCompaniesQuery}
      `,
      variables: {
        searchTppCompaniesRequest
      },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<SearchTppCompaniesResponse>(
      queryOptions,
      'searchTppCompanies'
    );
  }

  updateTppCompanyStatus(
    companyId: number,
    companyStatus: TppCompanyStatus
  ): Observable<FetchResult<TppCompanyStatusUpdateResponse>> {
    return this.graphService.mutate<TppCompanyStatusUpdateResponse>(
      {
        mutation: gql`
          ${updateTppCompanyStatusMutation}
        `,
        variables: { companyId, companyStatus }
      },
      'updateTppCompanyStatus'
    );
  }

  downloadTppCompanyKeys(
    companyId: number
  ): Observable<ApolloQueryResult<void>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${downloadTppCompanyKeysQuery}
      `,
      variables: {
        companyId
      },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<void>(
      queryOptions,
      'downloadTppCompanyKeys'
    );
  }

  downloadTppCompanyQSealKeys(
    companyId: number
  ): Observable<ApolloQueryResult<void>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${downloadTppCompanyQSealKeysQuery}
      `,
      variables: {
        companyId
      },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<void>(
      queryOptions,
      'downloadTppCompanyQSealKeys'
    );
  }

  tppUserDetails(
    relationUid: string
  ): Observable<ApolloQueryResult<TppUserDetailsResponse>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${tppUserDetailsQuery}
      `,
      variables: {
        relationUid
      },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<TppUserDetailsResponse>(
      queryOptions,
      'tppUserDetails'
    );
  }

  updateUserDetails(
    relationUid: string,
    request: UpdateTppUserDetailsRequest
  ): Observable<FetchResult<TppUserDetailsResponse>> {
    return this.graphService.mutate<TppUserDetailsResponse>(
      {
        mutation: gql`
          ${updateUserDetailsMutation}
        `,
        variables: { relationUid, request }
      },
      'updateUserDetails'
    );
  }

  updateTPPUserPassword(
    relationUid: string,
    request: UpdateTppUserPasswordRequest
  ): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${updateTPPUserPasswordMutation}
        `,
        variables: { relationUid, request }
      },
      'updateTPPUserPassword'
    );
  }

  uploadTppCompanyCertificateKeys(
    companyId: number,
    file: File
  ): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${uploadTppCompanyCertificateKeysMutation}
        `,
        variables: { companyId, file }
      },
      'uploadTppCompanyCertificateKeys'
    );
  }

  uploadTppCompanyQSealCertificateKeys(
    companyId: number,
    file: File
  ): Observable<FetchResult<void>> {
    return this.graphService.mutate<void>(
      {
        mutation: gql`
          ${uploadTppCompanyQSealCertificateKeysMutation}
        `,
        variables: { companyId, file }
      },
      'uploadTppCompanyQSealCertificateKeys'
    );
  }

  psd2Report(
    dateFrom: string,
    dateTo: string
  ): Observable<ApolloQueryResult<Psd2ReportResponse>> {
    const queryOptions: QueryOptions = {
      query: gql`
        ${psd2ReportQuery}
      `,
      variables: {
        dateFrom,
        dateTo
      },
      fetchPolicy: 'network-only'
    };

    return this.graphService.query<Psd2ReportResponse>(
      queryOptions,
      'psd2Report'
    );
  }

  uploadTppCompanyCertificateKeysREST(
    companyId: number,
    file: File
  ): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      `Bearer ${this.userService.getToken()}`
    );
    headers = headers.append('accept', 'application/json;charset=UTF-8');

    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<any>(
      `${UNIWEB_API_PROXY_ROUTES.QWAC}/${companyId}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    );
  }

  uploadTppCompanyQSealCertificateKeysREST(
    companyId: number,
    file: File
  ): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      `Bearer ${this.userService.getToken()}`
    );
    headers = headers.append('accept', 'application/json;charset=UTF-8');

    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<any>(
      `${UNIWEB_API_PROXY_ROUTES.QSEAL}/${companyId}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    );
  }
}
