export function authConfigValidator(data: any): Error[] {
  const errors = [];

  if (!data.authUrl) {
    // @ts-ignore
    errors.push(new Error(`AUTH_CONFIG - property 'authUrl' is required`));
  }

  return errors;
}
