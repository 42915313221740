export class AuthState {
  readonly accessToken: string;
  readonly errorMessage: string;
  readonly inProgress: boolean;

  constructor(
    accessToken: string = '',
    errorMessage: string = '',
    inProgress: boolean = false
  ) {
    this.accessToken = accessToken;
    this.errorMessage = errorMessage;
    this.inProgress = inProgress;
  }
}
