import { Injectable } from '@angular/core';

import { ApolloError } from 'apollo-client';

import { ErrorCodes } from '../../enums/error-codes';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorCodesService {
  checkConflictError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.ConflictError);
  }

  checkAccessDeniedError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.AccessDeniedError);
  }

  checkResourceNotFoundError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.ResourceNotFoundError);
  }

  checkNotAcceptableError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.AcceptableError);
  }

  checkRequestTimedOutError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.RequestTimedOutError);
  }

  checkGatewayTimeoutError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.GatewayTimeoutError);
  }

  checkInternalServerError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.InternalServerError);
  }

  checkInvalidTokenError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.InvalidTokenError);
  }

  checkBadRequestError(apolloError: ApolloError): boolean {
    return this.selectError(apolloError, ErrorCodes.BadRequestError);
  }

  checkBadRequestNotApolloError(error: HttpErrorResponse): boolean {
    return error && error.status && error.status === 400 ? true : false;
  }

  private selectError(apolloError: ApolloError, errorCode: string): boolean {
    console.warn('apolloError', apolloError);
    console.warn('errorCode', errorCode);
    return apolloError.graphQLErrors.some(
      (graphQLError: any) =>
        graphQLError.domain &&
        (graphQLError.domain[errorCode] ||
          graphQLError.domain.code === errorCode)
    );
  }
}
