import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf
} from '@angular/core';

import { CookieModule } from 'ngx-cookie';

import { AuthGuard } from './guard/auth.guard';
import { AuthHttpInterceptor } from './http-interceptor/auth-http-interceptor';
import { AuthModel } from './model/auth-model';
import { AUTH_MODEL } from './model/auth.provider.token';
import { AuthService } from './service/auth.service';
import { AUTH_SERVICE } from './service/auth-service.provider.token';
import { AUTH_CONFIG } from './config/auth-config.token';
import { WINDOW } from '../../core/services/window/window.token';
import { windowRefFactory } from '../../core/services/window/window.factory';

const AUTH_MODULE_PROVIDERS: Provider[] = [
  AuthGuard,
  {
    provide: AUTH_SERVICE,
    useClass: AuthService,
    deps: [AUTH_CONFIG, WINDOW]
  },
  {
    provide: AUTH_MODEL,
    useClass: AuthModel
  },
  {
    provide: WINDOW,
    useFactory: windowRefFactory
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
    deps: [AUTH_MODEL]
  }
];

@NgModule({
  imports: [CommonModule, CookieModule.forChild()],
  providers: AUTH_MODULE_PROVIDERS,
  declarations: []
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: AUTH_MODULE_PROVIDERS
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AuthModule
  ) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
