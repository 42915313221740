export enum ProcessCurrentStepName {
  LEAD_PAGE = 'leadPage',
  BACKGROUND_DETAILS_PAGE = 'addressAndDocumentPage',
  EMAIL_VERIFICATION_PAGE = 'emailVerificationPage',
  SET_YOUR_PASSWORD_PAGE = 'passwordPage',
  FINANCIAL_FORM_PAGE = 'financialDataPage',
  LOAN_PURPOSE_PAGE = 'loanPurposePage',
  LOAN_SCRAPER_PAGE = 'scraperPage',
  LOAN_OFFER_PAGE = 'offerPage',
  WAITING_PAGE = 'waitingPage',
  PRODUCT_CONFIGURATION_PAGE = 'productConfigPage',
  LOAN_CONFIRMATION_PAGE = 'confirmationPage',
  LOAN_COMPLETED_PAGE = 'finalConfirmation',
  WITHDRAWAL_REQUEST_PAGE = 'withdrawalRequestPage',
  REGISTRATION_PAGE = 'registrationPage'
}
