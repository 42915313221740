<div class="fe-page-container">
<div class="cookie-container" *ngIf="isCookieShow">
  <div class="cookie-wrapper">
    <p>Our website uses cookies. By continuing to browse the site, you are agreeing to our use of cookies.</p>
    <button class="read-more-cookie fe-btn-primary">
      <a target="_blank" href="assets/privacy-policy.pdf">Find out more</a>
    </button>
    <button (click)="hideCookieBox()" class="accept-cookie fe-btn-primary">Agree</button>
  </div>
</div>
  <fe-header
    [isCookieShow]="isCookieShow"
  ></fe-header>

  <div class="fe-content">
    <router-outlet></router-outlet>
  </div>

  <fe-footer></fe-footer>
</div>
