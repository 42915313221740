import { InjectionToken } from '@angular/core';
import { makeStateKey } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ConfigValidator } from './config.validator';

export interface ConfigAccess {
  config: Observable<Object | undefined>;
  init(configUrl: string, validators: ConfigValidator[]): Promise<Object>;
}

export const CONFIG_SERVICE = new InjectionToken<ConfigAccess>('ConfigService');

export const CONFIG_STATE_KEY = makeStateKey<string>('config');
