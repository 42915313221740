import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie';

import { PasswordResponse } from '../../../profile/models/change-password.model';
import { UserRoles } from '../../enums/user-roles';
import { User } from '../../models/user.model';
import { AuthService } from '../auth-service/auth.service';
import { EnvConfigService } from '../config-handler/config-handler.service';
import { AUTH_MODEL, AuthProvider } from '../../../shared/auth';
import { WINDOW } from '../window/window.token';
import { BrowserWindowApi } from '../window/browser-window-api';
import { CookieType } from '../../entity/cookie/cookie-type';

@Injectable()
export class UserService {
  user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  userFullName: string;

  private _shortenedUserName: BehaviorSubject<string> = new BehaviorSubject('');
  private token: string;

  get userInitials(): string {
    return this.userFullName
      .split(' ')
      .map(
        (item, index, arr) =>
          index === 0 || index + 1 === arr.length ? item[0] : null
      )
      .join('');
  }

  get shortenedUserName(): Observable<string> {
    return this._shortenedUserName.asObservable();
  }

  constructor(
    private cookieService: CookieService,
    @Inject(AUTH_MODEL) private authModel: AuthProvider,
    @Inject(WINDOW) private window: BrowserWindowApi,
    private authService: AuthService
  ) {}

  private parseUserToken(token: string): User {
    this.token = token;
    return new JwtHelperService().decodeToken(token) as User;
  }

  setUserData(): void {
    const currentUser: User = this.parseUserToken(
      this.cookieService.get(CookieType.AUTH_TOKEN)
    );

    const isCustomerPortalClientId: boolean =
      currentUser && currentUser.client_id
        ? currentUser.client_id.includes('CUSTOMER_PORTAL')
        : false;
    /*TODO it's temporary solution for filtering of user by authenticationType property which is not registered
        in developer portal platform, https://jira.ferratum.com/jira/browse/BIN495-717*/
    const isPSDAuthentication: boolean =
      currentUser && currentUser.authenticationType === 'PSD2';

    if (isCustomerPortalClientId || isPSDAuthentication) {
      this.logout();
      return;
    }

    this.user.next({
      ...currentUser
    });

    this.userFullName = `${(this.currentUser as User).personFirstName} ${
      (this.currentUser as User).personLastName
    }`;
    this._shortenedUserName.next(this.userInitials);
  }

  logout() {
    this.authModel.logout().subscribe(() => {
      this.window.location.href = `${EnvConfigService.authUrl}/logout`;
    });
  }

  isBackofficeUser(): boolean {
    const currentUser = this.currentUser as User;

    return currentUser && !!currentUser.authorities
      ? currentUser.authorities.includes(UserRoles.BackofficeUser)
      : false;
  }

  isTppUser(): boolean {
    const currentUser = this.currentUser as User;
    return currentUser && !!currentUser.authorities
      ? currentUser.authorities.includes(UserRoles.TppUser)
      : false;
  }

  get currentUser(): User | null {
    return this.user.getValue();
  }

  getToken(): string {
    return this.token;
  }

  changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<HttpResponse<PasswordResponse>> {
    return this.authService.changePassword(
      oldPassword,
      newPassword,
      this.token,
      (this.currentUser as User).relationUid
    );
  }
}
