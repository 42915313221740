import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';

import { UserService } from '../../../core/services/user/user.service';
import { AUTH_MODEL, AuthProvider } from '../../../shared/auth';

@Component({
  selector: 'fe-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<boolean>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(AUTH_MODEL) private authModel: AuthProvider,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.activatedRoute.fragment
      .pipe(
        filter((queryString): boolean => Boolean(queryString)),
        switchMap((queryString: string): Observable<{ [key: string]: string; }> => this.authModel.loginSuccess(queryString)),
        tap(() => this.userService.setUserData()),
        takeUntil(this.destroyed)
      )
      .subscribe(({ redirectUrl }): any => {
        if (!redirectUrl) {
          return;
        }
        this.router.navigate([redirectUrl]);
      });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}
