import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { Base64 } from 'js-base64';

import { AppConfig } from '../../../../config/app.config';

import { Counterparty } from '../../entity/content/country/counterparty';
import { Country } from '../../entity/content/country/country';
import { CountryCode } from '../../entity/content/country/country-code.enum';
import { Language } from '../../entity/content/country/language';
import { LanguageCode } from '../../entity/content/country/language-code.enum';
import { Locale } from '../../entity/content/country/locale.enum';
import {
  ParamEncodingType,
  ProcessEngineMethodParam,
  ProcessEngineMethodParams
} from '../../entity/content/country/process-engine-method-params';
import { CookieType } from '../../entity/cookie/cookie-type';
import { ProcessActionName } from '../../entity/process/process-action-name';
import { ProcessName } from '../../entity/process/process-name';
import { EnvConfigService } from '../config-handler/config-handler.service';
import { CookieStore } from '../cookie/cookie-store';
import { COOKIE_SERVICE } from '../cookie/cookie.token';
import { ProcessEngineApiCall } from '../process-engine/service/process-engine.enum';
import { SiteService } from '../site/site-service';
import { SITE_SERVICE } from '../site/site-service.token';
import { BrowserWindowApi } from '../window/browser-window-api';
import { WINDOW } from '../window/window.token';
import { countryConfig } from './country-config';
import {
  ProcessActionLayout,
  processCountryLayouts,
  ProcessLayout
} from './process-country-layouts';

export interface CountryContext {
  channel: string;
  counterpartyCode: string;
  countryCode: string;
}

export interface InitialSettings {
  countryCode: CountryCode;
  languageCode: LanguageCode;
  country: Country | undefined;
}

@Injectable()
export class CountryService {
  private _countries: Country[];

  private _initialSettings: InitialSettings;

  private _currentCountry: Observable<Country>;
  private _currentLanguage: Observable<Language>;
  private _currentLocale: Observable<Locale>;

  private _currentCountryCode: BehaviorSubject<CountryCode>;
  private _currentLanguageCode: BehaviorSubject<LanguageCode>;
  private _currentCountrySubject: BehaviorSubject<Country | undefined>;

  private defaultDateFormat: string = 'yyyymmdd';
  private siteId: string;

  constructor(
    @Inject(COOKIE_SERVICE) private cookieService: CookieStore,
    @Inject(WINDOW) private window: BrowserWindowApi,
    @Inject(DOCUMENT) private document: any,
    @Inject(SITE_SERVICE) private siteService: SiteService
  ) {
    const appConfigObservable = of(EnvConfigService.config);
    combineLatest(appConfigObservable, this.siteService.siteId)
      .pipe(
        filter((values: [AppConfig, string]) => !!values[0] && !!values[1]),
        tap((values: [AppConfig, string]) => {
          this.siteId = values[1];
          this.init();
        }),
        take(1)
      )
      .subscribe();
  }

  private init() {
    try {
      this._countries = countryConfig.sort((a: Country, b: Country) =>
        a.name.localeCompare(b.name)
      );

      this._initialSettings = this.initSettings();

      this._currentCountryCode = new BehaviorSubject<CountryCode>(
        this._initialSettings.countryCode
      );
      this._currentLanguageCode = new BehaviorSubject<LanguageCode>(
        this._initialSettings.languageCode
      );
      this._currentCountrySubject = new BehaviorSubject<Country | undefined>(
        this._initialSettings.country
      );

      this.initI18nChanges();

      if (this.cookieService.get(CookieType.CURRENT_LANGUAGE)) {
        this.setCurrentLanguage(<LanguageCode>(<any>this.cookieService.get(
          CookieType.CURRENT_LANGUAGE
        )));
      } else {
        this.setCurrentLanguage(this._currentLanguageCode.getValue());
      }
    } catch (err) {
      console.log(err);
    }
  }

  private initSettings(): InitialSettings {
    return {
      countryCode: CountryCode.SWEDEN,
      languageCode: LanguageCode.SWEDISH,
      country: undefined
    };

    throw new Error(
      `Country for siteId '${
        this.siteId
      }' not found! Please check countryConfig...`
    );
  }

  private initI18nChanges() {
    this._currentCountry = this._currentCountryCode.pipe(
      map((code: CountryCode) => {
        const country = this.countryFromCode(code);
        if (!country) {
          throw new Error('Country not found');
        }
        this._currentCountrySubject.next(country);
        return country;
      })
    );

    this._currentLocale = this._currentCountry.pipe(
      map((country: Country) => {
        if (country && country.locale) {
          return country.locale;
        }
        return Locale.NEW_ZEALAND;
      })
    );

    this._currentLanguage = combineLatest(
      this._currentCountryCode,
      this._currentLanguageCode
    ).pipe(
      map((results: any) => ({
        countryCode: results[0],
        languageCode: results[1]
      })),
      map(
        (latest: {
          countryCode: CountryCode;
          languageCode: LanguageCode;
        }): Language => {
          const availableLanguages = this.availableLanguagesFromCode(
            latest.countryCode
          );
          const foundLanguage = availableLanguages.find(
            (language: Language) => language.code === latest.languageCode
          );
          if (!foundLanguage) {
            console.log(
              `Language ${latest.languageCode} not found for country ${
                latest.countryCode
              }, setting default language ${availableLanguages[0].code}.`
            );
            this.setCurrentLanguage(availableLanguages[0].code);
            return availableLanguages[0];
          }
          return foundLanguage;
        }
      )
    );
  }

  availableLanguagesFromCode(code: CountryCode): Language[] {
    const country = this.countryFromCode(code);
    if (!country) {
      throw new Error('Country not found');
    }
    return country.availableLanguages;
  }

  accountAvailable(code: CountryCode): boolean {
    const country = this.countryFromCode(code);
    if (!country) {
      throw new Error('Country not found');
    }
    return country.accountAvailable;
  }

  get countries() {
    return this._countries;
  }

  get onlyCountries() {
    return this.countries.filter(
      country => country.countryCode !== CountryCode.FERRATUMBANK
    );
  }

  get currentCountryCode(): Observable<CountryCode> {
    return of(CountryCode.UNITED_KINGDOM);
  }

  get currentLanguageCode(): Observable<LanguageCode> {
    return of(LanguageCode.ENGLISH);
  }

  dateFormatFromCode(code: CountryCode): string {
    const country = this.countryFromCode(code);
    if (!country) {
      return this.defaultDateFormat;
    }

    return country.dateFormat;
  }

  gsmPrefixFromCode(code: CountryCode): string {
    const country = this.countryFromCode(code);

    return country ? country.gsmPrefix : '';
  }

  counterpartyFromCode(code: CountryCode): Counterparty {
    const country = this.countryFromCode(code);

    return country && country.counterparty
      ? country.counterparty
      : { id: '', code: '' };
  }

  channel(process?: ProcessName): string {
    const country = this._currentCountrySubject.getValue();
    if (country && country.channel) {
      if (process && country.channel.has(process)) {
        return country.channel.get(process) || '';
      } else {
        return country.channel.get(ProcessName.DEFAULT) || '';
      }
    }

    return '';
  }

  countryFromCode(code: CountryCode): Country | undefined {
    return this._countries.find(
      (countryItem: Country) =>
        countryItem.countryCode.toString().toLowerCase() ===
        code.toString().toLowerCase()
    );
  }

  currentCountryComponent(
    processType: ProcessName,
    component: string
  ): Observable<any> {
    return of();
  }

  get currentCountry(): Observable<Country> {
    return this._currentCountry;
  }

  setCurrentCountryCode(countryCode: CountryCode) {
    this._currentCountryCode.next(countryCode);
  }

  setCurrentCountry(country: Country) {
    this._currentCountrySubject.next(country);
  }

  setCurrentLanguage(languageCode: LanguageCode) {
    this._currentLanguageCode.next(languageCode);
  }

  processCountryLayoutFromCode(
    processName: ProcessName
  ): ProcessLayout | undefined {
    return processCountryLayouts.find(
      (item: ProcessLayout) => item.processName === processName
    );
  }

  processActionLayoutsFromCode(
    processName: ProcessName,
    processActionName: ProcessActionName,
    countryCode: CountryCode
  ): ProcessActionLayout[] {
    const processCountryLayout = this.processCountryLayoutFromCode(processName);

    if (!processCountryLayout) {
      throw new Error(
        `ProcessCountryLayout for process '${processName}' does not exist for countryCode '${countryCode}'!`
      );
    }
    return processCountryLayout.layouts.filter(
      (item: ProcessActionLayout) =>
        item.processActionName === processActionName
    );
  }

  getParams(
    methodType: ProcessEngineApiCall,
    params: Params
  ): { [key: string]: string } {
    const allowedParams: ProcessEngineMethodParam[] = this.getAllowedParams(
      methodType
    );

    return allowedParams
      .filter((param: ProcessEngineMethodParam) => !!params[param.value])
      .reduce((result: any, param: ProcessEngineMethodParam) => {
        const currentParam =
          param.type === ParamEncodingType.BASE64
            ? this.decodeParam(params[param.value])
            : params[param.value];
        return {
          ...result,
          [param.value]: currentParam
        };
      }, {});
  }

  private getAllowedParams(
    methodType: ProcessEngineApiCall
  ): ProcessEngineMethodParam[] {
    if (
      this._currentCountrySubject.value &&
      this._currentCountrySubject.value.specificProcessEngineParams
    ) {
      const call = this._currentCountrySubject.value.specificProcessEngineParams.filter(
        (methodCall: ProcessEngineMethodParams) =>
          methodCall.method === methodType
      );

      if (call.length) {
        return call[0].params;
      }
    }
    return [];
  }

  private decodeParam(paramValue: string): string {
    try {
      return Base64.decode(paramValue);
    } catch (error) {
      throw new Error('The param to be decoded is not correctly encoded');
    }
  }

  get hostname(): string {
    return this.window.location.hostname;
  }

  getContextDataRequest(): CountryContext {
    const currentCountryCode = this._currentCountryCode.getValue();
    const counterparty = this.counterpartyFromCode(currentCountryCode);
    return {
      channel: this.channel(),
      counterpartyCode: counterparty.code,
      countryCode: currentCountryCode.toString()
    };
  }

  // TODO: needs to be refactored after language switcher implementation
  openInNewWindow(
    event: MouseEvent,
    url: string,
    addBaseHrefPrefix?: boolean
  ): void {
    if (event) {
      event.preventDefault();
    }

    if (addBaseHrefPrefix) {
      const baseElement = this.document.querySelector('base[href]');

      const baseHref = baseElement ? baseElement.getAttribute('href') : '/';

      const unifiedUrl = url.charAt(0) === '/' ? url.substring(1) : url;

      this.window.open(baseHref + unifiedUrl, 'new');
    } else {
      this.window.open(url, 'new');
    }
  }

  onboardingProgressWithSubsteps(code: CountryCode): boolean {
    const country = this.countryFromCode(code);
    return !!(country && country.onboardingProgressWithSubsteps);
  }
}
