import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'fe-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent {
  @Input() user: string;
  @Input() isBackoffice: boolean;
  @Output()
  userClicked: EventEmitter<void> = new EventEmitter<void>();
}
