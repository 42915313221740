import { CountryCode } from '../../entity/content/country/country-code.enum';
import { LanguageCode } from '../../entity/content/country/language-code.enum';
import { Country } from '../../entity/content/country/country';
import { Locale } from '../../entity/content/country/locale.enum';
import { ProcessEngineApiCall } from '../process-engine/service/process-engine.enum';
import { ParamEncodingType } from '../../entity/content/country/process-engine-method-params';
import { CalculatorsTheme } from '../../entity/content/country/calculators.enum';
import { ProcessName } from '../../entity/process/process-name';

export const countryConfig: Country[] = [
  {
    siteId: 'ferratumbank.com',
    name: 'FerratumBank',
    countryCode: CountryCode.FERRATUMBANK,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '',
    availableLanguages: [
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      },
      {
        name: 'Norwegian',
        code: LanguageCode.NORWEGIAN_BOKMAL
      },
      {
        name: 'German',
        code: LanguageCode.GERMAN
      },
      {
        name: 'Swedish',
        code: LanguageCode.SWEDISH
      },
      {
        name: 'French',
        code: LanguageCode.FRENCH
      },
      {
        name: 'Spanish',
        code: LanguageCode.SPANISH
      }
    ],
    websites: ['https://www.ferratumbamk.com.'],
    currency: 'Kr',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratumbank.com',
    useCountryMenu: true,
    logo: 'multitude_bank_logos_02.svg',
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'developer.ferratumbank.com',
    name: 'Developer.FerratumBank',
    countryCode: CountryCode.FERRATUMBANK_DEVELOPER,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '',
    availableLanguages: [
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      }
    ],
    websites: ['https://developer.ferratumbamk.com'],
    currency: 'Kr',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'developer.ferratumbank.com',
    useCountryMenu: false,
    logo: 'multitude_bank_logos_01.svg',
    isHeroParallaxEnabled: false,
    skipCookieConsent: false,
    accountAvailable: false,
    hasPsd2: true
  },
  {
    siteId: 'ferratum.com.au',
    name: 'Australia',
    countryCode: CountryCode.AUSTRALIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+61',
    availableLanguages: [
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      }
    ],
    websites: ['https://www.ferratum.com.au'],
    currency: '$',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.com.au',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.com.br',
    name: 'Brazil',
    countryCode: CountryCode.BRAZIL,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+55',
    availableLanguages: [
      {
        name: 'Portuguese',
        code: LanguageCode.PORTUGUESE
      }
    ],
    websites: ['https://www.ferratum.com.br'],
    currency: 'R$',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.com.br',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    blogTeaserImgRatio: 'ratio-3x2',
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.bg',
    name: 'Bulgaria',
    countryCode: CountryCode.BULGARIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+359',
    availableLanguages: [
      {
        name: 'Bulgarian',
        code: LanguageCode.BULGARIAN
      }
    ],
    websites: ['https://www.ferratum.bg'],
    currency: 'лв',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.bg',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.dk',
    name: 'Denmark',
    countryCode: CountryCode.DENMARK,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+45',
    availableLanguages: [
      {
        name: 'Danish',
        code: LanguageCode.DANISH
      }
    ],
    websites: ['https://www.ferratum.dk'],
    currency: 'kr.',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    formCardIgnoreCustomClass: true,
    hasLoanModificatorTitle: true,
    suffix: 'ferratum.dk',
    counterparty: {
      id: 'CE-FBM_LE_DK',
      code: 'FBM_LE_DK'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'DK_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true,
    loanAmountBox: true,
    onboardingProgressWithSubsteps: true,
    scrapperShowGoEscat: true
  },
  {
    siteId: 'ferratum.ee',
    name: 'Estonia',
    countryCode: CountryCode.ESTONIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+372',
    availableLanguages: [
      {
        name: 'Estonian',
        code: LanguageCode.ESTONIAN
      }
    ],
    websites: ['https://www.ferratum.ee'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.ee',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.fi',
    name: 'Finland',
    countryCode: CountryCode.FINLAND,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+358',
    availableLanguages: [
      {
        name: 'Finnish',
        code: LanguageCode.FINNISH
      },
      {
        name: 'Swedish',
        code: LanguageCode.SWEDISH
      }
    ],
    websites: ['https://www.ferratum.fi'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.fi',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.fr',
    name: 'France',
    countryCode: CountryCode.FRANCE,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+33',
    availableLanguages: [
      {
        name: 'French',
        code: LanguageCode.FRENCH
      }
    ],
    websites: ['https://www.ferratum.fr'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.fr',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.ca',
    name: 'Canada',
    countryCode: CountryCode.CANADA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+1',
    availableLanguages: [
      {
        name: 'French',
        code: LanguageCode.FRENCH
      },
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      }
    ],
    websites: ['https://www.ferratum.ca'],
    currency: '$',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.ca',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratumbank.hr',
    name: 'Croatia',
    countryCode: CountryCode.CROATIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+385',
    availableLanguages: [
      {
        name: 'Croatian',
        code: LanguageCode.CROATIAN
      }
    ],
    websites: ['https://www.ferratumbank.hr'],
    currency: 'kn',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratumbank.hr',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'premium.ferratum.lv',
    name: 'Latvia',
    countryCode: CountryCode.LATVIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+371',
    availableLanguages: [
      {
        name: 'Latvian',
        code: LanguageCode.LATVIAN
      }
    ],
    websites: ['https://premium.ferratum.lv'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'premium.ferratum.lv',
    counterparty: {
      id: 'CE-FBM_LE_LV',
      code: 'FBM_LE_LV'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.SEFINANCE_PROCESS, 'LV_BROKER_SEFINANCE'],
      [ProcessName.PREONBOARDING_PROCESS, 'LV_BROKER_SEFINANCE'],
      [ProcessName.DEFAULT, 'LV_LEN_WEB']
    ]),
    specificProcessEngineParams: [
      {
        method: ProcessEngineApiCall.START,
        params: [
          {
            type: ParamEncodingType.BASE64,
            value: 'relationUid'
          },
          {
            type: ParamEncodingType.BASE64,
            value: 'offerId'
          },
          {
            type: ParamEncodingType.BASE64,
            value: 'applicationUid'
          },
          {
            type: ParamEncodingType.DEFAULT,
            value: 'loanAmount'
          },
          {
            type: ParamEncodingType.DEFAULT,
            value: 'loanTerm'
          },
          {
            type: ParamEncodingType.DEFAULT,
            value: 'productCode'
          },
          {
            type: ParamEncodingType.DEFAULT,
            value: 'productVersionId'
          },
          {
            type: ParamEncodingType.DEFAULT,
            value: 'loanTermUnit'
          },
          {
            type: ParamEncodingType.DEFAULT,
            value: 'currencyCode'
          }
        ]
      }
    ],
    whiteListSubPages: ['preonboarding', 'ferratum'],
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    productCalculatorType: CalculatorsTheme.WITH_TABLE,
    accountAvailable: false,
    pepControlsContainsLink: true,
    dataAnalysesContainsLink: true,
    pageSetup: {
      UserContentDefinitionProcess: {
        loanLeadFormComponent: {
          policyUrl: {
            url:
              'https://www.ferratum.lv/sites/ferratum.lv/files/20180508%20LV%20%28LAT%29%20Principles%20of%20processing%20personal%20data%20of%20clients.pdf\n' +
              '\n',
            prefix: false
          },
          marketingUrl: {
            url:
              'https://www.ferratum.lv/sites/ferratum.lv/files/20180508%20LV%20%28LAT%29%20Principles%20of%20processing%20personal%20data%20of%20clients.pdf\n' +
              '\n',
            prefix: false
          }
        },
        loanConfirmationFormComponent: {
          disableLoanDisclosue: true,
          disablePrivacyPolicy: true,
          disableSecci: true,
          disableTermConditions: true
        }
      }
    }
  },
  {
    siteId: 'ferratum.lt',
    name: 'Lithuania',
    countryCode: CountryCode.LITHUANIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+370',
    availableLanguages: [
      {
        name: 'Lithuanian',
        code: LanguageCode.LITHUANIAN
      }
    ],
    websites: ['https://www.ferratum.lt'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.lt',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.mx',
    name: 'Mexico',
    countryCode: CountryCode.MEXICO,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+52',
    availableLanguages: [
      {
        name: 'Spanish',
        code: LanguageCode.SPANISH
      }
    ],
    websites: ['https://www.ferratum.mx'],
    currency: '$',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.mx',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.nl',
    name: 'Netherlands',
    countryCode: CountryCode.NETHERLANDS,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+31',
    availableLanguages: [
      {
        name: 'Dutch',
        code: LanguageCode.DUTCH
      },
      {
        name: 'Frisian',
        code: LanguageCode.FRISIAN
      }
    ],
    websites: ['https://www.ferratum.nl'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.nl',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.no',
    name: 'Norway',
    countryCode: CountryCode.NORWAY,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+47',
    availableLanguages: [
      {
        name: 'Norwegian Bokmål',
        code: LanguageCode.NORWEGIAN_BOKMAL
      },
      {
        name: 'Norwegian Nynorsk',
        code: LanguageCode.NORWEGIAN_NYNORSK
      },
      {
        name: 'Norwegian',
        code: LanguageCode.NORWEGIAN
      },
      {
        name: 'Northern Sami',
        code: LanguageCode.NORTHERN_SAMI
      }
    ],
    websites: ['https://www.ferratum.no'],
    currency: 'kr,',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.no',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.co.nz',
    name: 'New Zealand',
    countryCode: CountryCode.NEW_ZEALAND,
    locale: Locale.NEW_ZEALAND,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+64',
    availableLanguages: [
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      }
    ],
    websites: ['https://www.ferratum.co.nz'],
    currency: '$',
    currencyPrefix: true,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.co.nz',
    counterparty: {
      id: 'CE-FER_LE_NZ',
      code: 'FER_LE_NZ'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'NZ_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: true,
    accountAvailable: true,
    displayRepaymentElements: false,
    displayInvoicesElements: true,
    pageSetup: {
      UserContentDefinitionProcess: {
        invoiceListViewModelComponent: {
          makePaymentUrlParams: [
            '/account/active-product/creditline',
            ':accountId',
            'invoices',
            'repay'
          ]
        },
        addressGroupComponent: {
          city: true
        }
      }
    },
    sfchatConfig: {
      nameFieldLabel: 'Name',
      subjectFieldLabel: 'Subject',
      emailFieldLabel: 'Email',
      descriptionFieldLabel: 'Description',
      businessType: 'Lending',
      webCountry: 'New Zealand',
      caseRecordType: '0124I000000VfZ9QAK'
    }
  },
  {
    siteId: 'ferratum.pl',
    name: 'Poland',
    countryCode: CountryCode.POLAND,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+48',
    availableLanguages: [
      {
        name: 'Polish',
        code: LanguageCode.POLISH
      }
    ],
    websites: ['https://www.ferratum.pl'],
    currency: 'zł',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.pl',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.ro',
    name: 'Romania',
    countryCode: CountryCode.ROMANIA,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+40',
    availableLanguages: [
      {
        name: 'Romanian',
        code: LanguageCode.ROMANIAN
      }
    ],
    websites: ['https://www.ferratum.ro'],
    currency: 'lei',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.ro',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.ru',
    name: 'Russian Federation',
    countryCode: CountryCode.RUSSIAN_FEDERRATION,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+7',
    availableLanguages: [
      {
        name: 'Russian',
        code: LanguageCode.RUSSIAN
      }
    ],
    websites: ['https://www.ferratum.ru'],
    currency: '₽',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.ru',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.es',
    name: 'Spain',
    countryCode: CountryCode.SPAIN,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+34',
    availableLanguages: [
      {
        name: 'Spanish',
        code: LanguageCode.SPANISH
      }
    ],
    websites: ['https://www.ferratum.es'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.es',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'localhost',
    name: 'United Kingdom',
    countryCode: CountryCode.UNITED_KINGDOM,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+44',
    availableLanguages: [
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      }
    ],
    websites: ['https://www.ferratum.co.uk'],
    currency: '£',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.co.uk',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'developer-ferratumbank-com.dev.ferratum.com',
    name: 'United Kingdom',
    countryCode: CountryCode.UNITED_KINGDOM,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+44',
    availableLanguages: [
      {
        name: 'English',
        code: LanguageCode.ENGLISH
      }
    ],
    websites: ['https://www.ferratum.co.uk'],
    currency: '£',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.co.uk',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.cz',
    name: 'Czech Republic',
    countryCode: CountryCode.CZECH_REPUBLIC,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+420',
    availableLanguages: [
      {
        name: 'Czech',
        code: LanguageCode.CZECH
      }
    ],
    websites: ['https://www.ferratum.cz'],
    currency: 'Kč',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.cz',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'xpresscredit.de',
    name: 'Germany',
    countryCode: CountryCode.GERMANY,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+49',
    availableLanguages: [
      {
        name: 'German',
        code: LanguageCode.GERMAN
      }
    ],
    websites: ['https://www.xpresscredit.de'],
    currency: '€',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: false,
      isTopHeaderFixed: false
    },
    suffix: 'xpresscredit.de',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    skipCookieConsent: false,
    accountAvailable: true
  },
  {
    siteId: 'ferratum.se',
    name: 'Sweden',
    countryCode: CountryCode.SWEDEN,
    locale: Locale.SWEDEN,
    dateFormat: 'dd.MM.yyyy',
    gsmPrefix: '+46',
    availableLanguages: [
      {
        name: 'Swedish',
        code: LanguageCode.SWEDISH
      }
    ],
    currencyFormat: {
      formatByLocale: 'de-DE',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    },
    websites: ['https://www.ferratum.se'],
    currency: 'kr',
    currencyPrefix: false,
    currencyDecimalPoints: 2,
    layout: {
      topHeader: true,
      isTopHeaderFixed: false
    },
    suffix: 'ferratum.se',
    counterparty: {
      id: 'CE-FBM_LE_SE',
      code: 'FBM_LE_SE'
    },
    channel: new Map<ProcessName, string>([
      [ProcessName.DEFAULT, 'SE_LEN_WEB']
    ]),
    isHeroParallaxEnabled: true,
    oldPlatformURL: 'https://ferratum.se/account/login',
    skipCookieConsent: false,
    accountAvailable: true,
    pageSetup: {
      UserContentDefinitionProcess: {
        repaymentsComponent: {
          carouselItems: 1
        },
        trustlyPaymentStartedViewModelComponent: {
          trustlyRedirect: true
        },
        continueFormViewModelComponent: {
          sendCid: true
        },
        invoiceListViewModelComponent: {
          makePaymentUrlParams: [
            '/account/active-product/primeloan',
            ':accountId',
            'repayment'
          ]
        },
        primeloanSummaryComponent: {
          applicationHistory: true
        },
        productConfigurationFormComponent: {
          bankAccountTitle: true,
          disableBankAccountSelect: true,
          disableWithdrawalAmount: true,
          mainForm: true, // TODO: change naming of this property
          loanAmount: true,
          apr: true,
          computeTable: true
        },
        addressGroupComponent: {
          city: true
        }
      }
    },
    displayRepaymentElements: true,
    displayInvoicesElements: false,
    showCountryList: false,
    hideBankAccountPlaceholder: true
  }
];
