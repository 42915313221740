import { ProcessName } from '../../../core/entity/process/process-name';
import { ProcessActionName } from '../../../core/entity/process/process-action-name';
import { ProcessActionType } from '../../../core/entity/process/process-action-type';
import { ProcessFieldValidationType } from '../../../core/entity/process/process-field-validation-type';
import { ComponentAttributeCategory } from '../../../core/entity/process/component-attribute-category';
import { ProcessActionFieldType } from '../../../core/entity/process/process-action-field-type';
import { CountryCode } from '../../../core/entity/content/country/country-code.enum';
import { ProcessStepStatus } from '../../../core/entity/process/process-step-status';
import { MockMessageItem } from '../../../core/services/process-engine/decorators/process-message.provider';

export const leadMockMessage: MockMessageItem = {
  code: CountryCode.NEW_ZEALAND,
  process: {
    processUid: 'some-id',
    processName: ProcessName.TPP_REGISTRATION_PROCESS
  },
  // @ts-ignore
  action: {
    actionType: ProcessActionType.SHOW_SCREEN,
    userTaskName: ProcessActionName.REGISTRATION_LEAD_SCREEN,
    userTaskUid: 'uuid',
    isFinalTask: false,
    isRevertable: false,
    scraperUid: null,
    responseObjectName: 'registrationPage',
    components: [
      {
        componentName: 'contactDetails',
        componentType: 'contactDetails',
        attributes: [
          {
            attributeName: 'PRIVACY_POLICY',
            attributeType: ProcessActionFieldType.CHECKBOX,
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: 'false',
            validations: [],
            options: [],
            errors: [],
            groupName: 'consents'
          },
          {
            attributeName: 'TERMS_CONDITIONS',
            attributeType: ProcessActionFieldType.CHECKBOX,
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: 'false',
            validations: [],
            options: [],
            errors: [],
            groupName: 'consents'
          },
          {
            attributeName: 'email',
            attributeType: 'input-email',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.EMAIL,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          },
          {
            attributeName: 'retypeEmail',
            attributeType: 'input-email',
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.EMAIL_MATCH,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          },
          {
            attributeName: 'gsm',
            attributeType: 'input-phone-number',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.GSM_INTERNATIONAL,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          },
          {
            attributeName: 'firstName',
            attributeType: 'input-text',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.NAME_NZ,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          },
          {
            attributeName: 'lastName',
            attributeType: 'input-text',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.LASTNAME_DK,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          },
          {
            attributeName: 'setPassword',
            attributeType: 'password',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.PASSWORD,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          },
          {
            attributeName: 'retypePassword',
            attributeType: 'password',
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: true,
            value: null,
            validations: [
              {
                validationName: ProcessFieldValidationType.PASSWORD_MATCH,
                params: []
              }
            ],
            options: [],
            errors: [],
            groupName: null
          }
        ],
        messages: []
      }
    ],
    errors: [],
    messages: [],
    allowedActions: []
  },
  steps: [
    {
      stepID: ProcessActionName.REGISTRATION_LEAD_SCREEN,
      status: ProcessStepStatus.CURRENT,
      description: undefined
    },
    {
      stepID: ProcessActionName.EMAIL_VERIFICATION,
      status: ProcessStepStatus.NOT_COMPLETED,
      description: undefined
    }
  ]
};

export const leadMockMessagesList: MockMessageItem[] = Array.from([
  leadMockMessage
]);
