import { ProcessName } from '../../../core/entity/process/process-name';
import { ProcessActionName } from '../../../core/entity/process/process-action-name';
import { ProcessActionType } from '../../../core/entity/process/process-action-type';
import { ComponentAttributeCategory } from '../../../core/entity/process/component-attribute-category';
import { ProcessActionFieldType } from '../../../core/entity/process/process-action-field-type';
import { CountryCode } from '../../../core/entity/content/country/country-code.enum';
import { ProcessStepStatus } from '../../../core/entity/process/process-step-status';
import { MockMessageItem } from '../../../core/services/process-engine/decorators/process-message.provider';

export const emailVerificationMockMessage: MockMessageItem = {
  code: CountryCode.NEW_ZEALAND,
  process: {
    processUid: 'CE-FER_LE_NZ-4bf30383-d294-4587-87b1-f6ac58395221',
    processName: ProcessName.TPP_REGISTRATION_PROCESS
  },
  action: {
    actionType: ProcessActionType.SHOW_SCREEN,
    userTaskName: ProcessActionName.EMAIL_VERIFICATION,
    userTaskUid: 'a6b33029-9585-11e8-88d4-0a580a81028a',
    isFinalTask: false,
    isTaskToBeSubmitted: true,
    isRevertable: false,
    responseObjectName: 'emailVerificationPage',
    scraperUid: null,
    components: [
      {
        componentName: 'contactDetails',
        componentType: 'contactDetails',
        attributes: [
          {
            attributeName: 'email',
            attributeType: ProcessActionFieldType.TEXT,
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: 'email@email.com',
            validations: [],
            options: [],
            errors: []
          },
          {
            attributeName: 'pinCode',
            attributeType: ProcessActionFieldType.INPUT_TEXT,
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: false,
            value: null,
            validations: [],
            options: [],
            errors: [],
            groupName: undefined
          },
          {
            attributeName: 'sendPinAgain',
            attributeType: ProcessActionFieldType.BUTTON,
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [],
            options: [],
            errors: [],
            groupName: undefined
          },
          {
            attributeName: 'emailVerificationStatus',
            attributeType: ProcessActionFieldType.TEXT,
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: null,
            validations: [],
            options: [],
            errors: []
          },
          {
            attributeName: 'availableRetryAttempts',
            attributeType: ProcessActionFieldType.TEXT,
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: null,
            validations: [],
            options: [],
            errors: []
          }
        ],
        messages: []
      }
    ],
    messages: [],
    errors: []
  },
  steps: [
    {
      stepID: ProcessActionName.REGISTRATION_LEAD_SCREEN,
      status: ProcessStepStatus.COMPLETED,
      description: undefined
    },
    {
      stepID: ProcessActionName.EMAIL_VERIFICATION,
      status: ProcessStepStatus.CURRENT,
      description: undefined
    }
  ]
};

export const emailVerificationMockMessageDK: MockMessageItem = {
  code: CountryCode.DENMARK,
  process: {
    processUid: 'CE-FER_LE_NZ-4bf30383-d294-4587-87b1-f6ac58395221',
    processName: ProcessName.ONBOARDING_PROCESS
  },
  action: {
    actionType: ProcessActionType.SHOW_SCREEN,
    userTaskName: ProcessActionName.EMAIL_VERIFICATION,
    userTaskUid: 'a6b33029-9585-11e8-88d4-0a580a81028a',
    isFinalTask: false,
    isTaskToBeSubmitted: true,
    isRevertable: false,
    responseObjectName: 'emailVerificationPage',
    scraperUid: null,
    components: [
      {
        componentName: 'contactDetails',
        componentType: 'contactDetails',
        attributes: [
          {
            attributeName: 'email',
            attributeType: 'text',
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: 'email@email.com',
            validations: [],
            options: [],
            errors: []
          },
          {
            attributeName: 'pinCode',
            attributeType: 'input-text',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: false,
            value: null,
            validations: [],
            options: [],
            errors: [],
            groupName: undefined
          },
          {
            attributeName: 'sendPinAgain',
            attributeType: 'button',
            attributeCategory: ComponentAttributeCategory.EDITABLE,
            mandatory: true,
            value: null,
            validations: [],
            options: [],
            errors: [],
            groupName: undefined
          },
          {
            attributeName: 'emailVerificationStatus',
            attributeType: 'text',
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: null,
            validations: [],
            options: [],
            errors: []
          },
          {
            attributeName: 'availableRetryAttempts',
            attributeType: 'text',
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: null,
            validations: [],
            options: [],
            errors: []
          }
        ],
        messages: []
      },
      {
        componentName: 'productInfo',
        componentType: 'productInfo',
        attributes: [
          {
            attributeName: 'requestedAmount',
            attributeType: 'text',
            attributeCategory: ComponentAttributeCategory.READ_ONLY,
            mandatory: false,
            value: '17000',
            validations: [],
            options: [],
            errors: []
          }
        ]
      }
    ],
    messages: [],
    errors: []
  },
  steps: [
    {
      stepID: '1',
      status: ProcessStepStatus.CURRENT,
      description: undefined,
      currentSubstep: 2,
      numberOfSubsteps: 6
    },
    {
      stepID: '2',
      status: ProcessStepStatus.WAITING,
      description: undefined
    },
    {
      stepID: '3',
      status: ProcessStepStatus.WAITING,
      description: undefined
    }
  ]
};

export const emailVerificationMockMessageList: MockMessageItem[] = [
  emailVerificationMockMessage
];
