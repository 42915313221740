import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApolloLink, NextLink, Operation } from 'apollo-link';
import { WINDOW } from '../../window/window.token';
import { AuthModel, AUTH_MODEL } from '../../../../shared/auth';
import { BrowserWindowApi } from '../../window/browser-window-api';

@Injectable()
export class AuthLink extends ApolloLink {
  private accessToken: string;

  constructor(
    @Inject(AUTH_MODEL) private authModel: AuthModel,
    @Inject(WINDOW) private window: BrowserWindowApi
  ) {
    super();

    this.authModel.accessToken().subscribe((token: string) => {
      this.accessToken = token;
    });
  }

  public request(operation: Operation, forward?: NextLink): any {
    const { origin }: { [key: string]: any } = this.window.location;
    const tokenBearer: string = this.accessToken
      ? `Bearer ${this.accessToken}`
      : '';
    const headers: HttpHeaders = new HttpHeaders()
      .set('authorization', tokenBearer)
      .set('Accept-Language', 'en')
      .set('ssr-origin', origin);

    operation.setContext({ headers });

    if (!forward) {
      return null;
    }

    return forward(operation);
  }
}
