import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  AuthConfig,
  AUTH_CONFIG,
  AuthService as BaseAuthService
} from '../../../shared/auth';
import { WINDOW } from '../window/window.token';
import { BrowserWindowApi } from '../window/browser-window-api';

import { PasswordResponse } from '../../../profile/models/change-password.model';
import { EnvConfigService } from '../config-handler/config-handler.service';

@Injectable()
export class AuthService extends BaseAuthService {
  constructor(
    private httpClient: HttpClient,
    @Inject(AUTH_CONFIG) authConfig: AuthConfig,
    @Inject(WINDOW) window: BrowserWindowApi
  ) {
    super(authConfig, window);
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    token: string,
    relationUid: string
  ): Observable<HttpResponse<PasswordResponse>> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);

    const body = {
      newPassword: newPassword,
      oldPassword: oldPassword
    };

    return this.httpClient.post<PasswordResponse>(
      EnvConfigService.authUrl +
        `/v2/userAccounts/${relationUid}/password-change`,
      body,
      {
        headers,
        observe: 'response',
        responseType: 'json'
      }
    );
  }
}
