export enum StatusClassNames {
  CREATED = 'created',
  PENDING = 'pending',
  REVIEW = 'review',
  APPROVED = 'approved',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  PRODUCTION = 'prod',
  TEST = 'sandbox'
}

export enum EnvTypes {
  production = 'PRODUCTION',
  test = 'TEST'
}

export enum StatusTypes {
  created = 'CREATED',
  pending = 'PENDING',
  review = 'REVIEW',
  approved = 'APPROVED',
  blocked = 'BLOCKED',
  deleted = 'DELETED'
}

export enum CompanyFormTypes {
  RequestProdAccess = 'requestProductionAccess',
  ShowDetails = 'showDetails',
  Edit = 'edit',
  Create = 'create'
}

export enum certificateStatuses {
  Present = 'PRESENT',
  Missing = 'MISSING',
  NotNeeded = 'NOT_NEEDED',
  Expired = 'EXPIRED',
  ExpiresSoon = 'EXPIRES_SOON',
  Invalid = 'INVALID'
}

export enum SubmitButtonText {
  RequestProdAccess = 'Send request',
  ShowDetails = 'Edit',
  Edit = 'Confirm',
  Create = 'Create company'
}
export enum MenuActionTypes {
  Edit = 'edit',
  GrantProdAccess = 'grantProdAccess',
  RequestProdAccess = 'requestProdAccess',
  DownloadTestCertificate = 'downloadTestCertificate',
  DownloadTestQsealCertificate = 'downloadTestQsealCertificate',
  ChangeOwner = 'changeOwner',
  AddApplication = 'addApplication',
  ReIssueOAUTH = 'reIssueOAUTH',
  ReIssueHMAC = 'reIssueHMAC',
  Disable = 'disable',
  Unblock = 'unblock',
  Delete = 'delete',
  Enable = 'enable',
  Detail = 'detail',
  ChangePassword = 'changePassword',
  DeleteProfile = 'deleteProfile'
}

export enum ReissueTppAppModes {
  SECRET = 'secret',
  SIGNATURE = 'signature'
}

export enum ProcessFormStepName {
  FULL = 'Full',
  MAILING_ADDRESS_READ = 'mailingAddress',
  MAILING_ADDRESS_EDIT = 'editMailingAddress',
  RESIDENCE_ADDRESS = 'residenceAddress',
  INCOME_AND_EXPENSES = 'incomeAndExpenses',
  IDENTITY_DOCUMENT = 'identityDocument',
  PREVIOUS_RESIDENCE_ADDRESS = 'previousResidenceAddress',
  CONFIRMATION_PAGE_CL = 'confirmationPageCL',
  LOAN_LEAD_INFORMATION = 'loanLeadInformation',
  LOAN_PURPOSE = 'loanPurpose',
  EMAIL_VERIFICATION = 'emailVerification',
  SET_PASSWORD = 'setPassword',
  BANKING_DETAILS = 'bankingDetails',
  LOAN_OFFER = 'loanOffer',
  WAITING = 'waiting',
  SORRY = 'sorry',
  DISABLED = 'disabled',
  PRODUCT_CONFIGURATION = 'productConfiguration',
  PRODUCT_CONFIGURATION_REF = 'productConfiguration',
  PROCESS_ERROR = 'processError',
  NO_OFFER = 'noOffer',
  MANUAL_REVIEW = 'manualReview',
  LOGIN_CONFIRMATION = 'loginConfirmation',
  FINALIZATION = 'finalization',
  EXISTING_CUSTOMER = 'existingCustomer',
  ERROR = 'error',
  COMPLETED = 'completed',
  CONFIRMATION = 'confirmation'
}
