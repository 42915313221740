/**
 * TODO: Process engine should use more enum-erish names for the Process names, e.g. 'onboarding-process'
 */

export enum ProcessName {
  TPP_REGISTRATION_PROCESS = 'TppRegistration',
  PREONBOARDING_PROCESS = 'PreOnboarding',
  FERRATUM_PROCESS = 'Ferratum',
  ONBOARDING_PROCESS = 'OnboardingProcess',
  SEFINANCE_PROCESS = 'Sefinance',
  ACCOUNT_PROCESS = 'UserContentDefinitionProcess',
  WITHDRAWAL_PROCESS = 'WithdrawalProcess',
  RESCHEDULING_PROCESS = 'ReschedulingProcess',
  APIGW_ERROR = 'ApigwError',
  REPAYMENT_PROCESS = 'RepaymentProcess',
  DEFAULT = 'Default'
}
